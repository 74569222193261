.container {
  text-align: center;
}

.timer {
  font-size: 40px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 0.7rem;
}

.timercountainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timer .days,
.timer .hours,
.timer .minutes,
.timer .second {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  width: 25%;
  margin-bottom: 0.5rem;

  font-size: 16px;
  font-weight: 600;
  color: #535763;
}

.timer .days::after,
.timer .hours::after,
.timer .minutes::after,
.timer .second::after {
  position: absolute;
  bottom: -10px;
  font-size: 12px;
  left: 0;
  right: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  color: rgb(0, 0, 0);
}

.toastContainer {
  display: flex !important;
  gap: 4px;
}

.counter * {
  display: flex;
}