

@font-face {
    font-family: 'Acumin Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Acumin Pro'), url(.//../fonts/Acumin-RPro.woff) format('woff');
    }
    

    // @font-face {
    // font-family: 'Acumin Pro';
    // font-style: normal;
    // font-weight: italic;
    // src: local('Acumin Pro Italic'), url(.//../fonts/Acumin-ItPro.woff) format('woff');
    // }
    

    @font-face {
    font-family: 'Acumin Pro';
    font-style: bold;
    font-weight: 600;
    src: local('Acumin Pro Bold'), url(.//../fonts/Acumin-BdPro.woff) format('woff');
    }
    

    // @font-face {
    // font-family: 'Acumin Pro';
    // font-style: bold;
    // font-weight: italic;
    // src: local('Acumin Pro Bold Italic'), url(.//../fonts/Acumin-BdItPro.woff) format('woff');
    // }