// Reset
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  all: unset;
}

select:focus {
  border: 0;
  offset: 0;
  outline: 0;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(0 0 0 / 15%) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #212121 !important;
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.122) inset !important;
  border: 0.0313rem solid #9ba1a5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

body .register-border,
body .register-input-user,
body .register-input,
body .register-input-input,
body .appf-type-selector,
body .register-inputs {
  background: #F5F5F5 !important;
  border: 0.0313rem solid #98a5ab;

  &.white {
    background: #ffffff !important;

  }
}

.appf-type-selector {
  margin-bottom: 0.6rem;
}

button {
  border: 0;
}

a {
  text-decoration: none;
}

button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html {
  font-size: 10px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: 'Acumin Pro', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.game div,
.aside div,
.button,
*::after,
*::before {
  color: rgb(0, 0, 0);
}

.WEB3_CONNECT_MODAL_ID * {
  text-shadow: none;
}

h1 {
  font-size: 42px;
  line-height: 26.4px;
}

h3 {
  font-size: 34px;
  line-height: 15.4px;
}

h3 {
  font-size: 14px;
  line-height: 15.4px;
}

p {
  font-size: 14px;
  line-height: 20px;
}

.pointer {
  cursor: pointer;
}