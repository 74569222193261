.maintenance-style {
    background-color: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 992px) {
        min-height: 100vh;
    }

    .logo-container {}

    .logo-desktop {
        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    .logo-mobile {
        display: none;
        margin: auto;
        width: 250px;
        margin-bottom: 5rem;

        @media screen and (max-width: 992px) {
            display: block;
        }
    }

    .d-flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .container {
        align-items: center;
        max-width: 90%;

        @media screen and (max-width: 992px) {
            max-width: unset;
        }
    }

    .col-L {
        padding: 2em;
    }

    .col-R {
        max-width: 60%;
        width: 60%
    }

    .color-white {
        fill: #FFFFFF;
    }

    .logo {
        height: 40px;
        width: 40px;
        font-size: .5em;
        background-color: #153458;
        border-radius: 50%;
        padding: 1em;
        margin: 1em;
    }

    // .logo-filter{
    //     filter: brightness(0.3);
    // }

    h1 {
        margin: 0 !important;
        // font-size: 5vmax !important;
        font-size: 6rem !important;
        // font-size: 7vmin !important;

        margin: 2rem 0 !important;
    }

    h1,
    h2,
    h3,
    p {
        color: #153458;

        @media screen and (max-width: 992px) {
            text-align: center;
        }
    }

    .st0 {
        fill: #153458;
    }

    .st1 {
        fill: #FFB8B8;
    }

    .st2,
    .st4,
    .st7 {
        stroke: #0a192b;
        stroke-miterlimit: 10;
    }

    .st2 {
        fill: none;
    }

    .st3 {
        fill: #FFFFFF;
        stroke: #153458;
        stroke-width: 0.9847;
        stroke-miterlimit: 10;
    }

    .st4 {
        fill: #2db4d0;
    }

    .st5 {
        fill: #2db4d0;
    }

    .st6 {
        fill: #00CCCB;
    }

    .st7 {
        fill: #00CCCB;
    }

    .st8 {
        fill: none;
        stroke: #153458;
        stroke-width: 0.9847;
        stroke-miterlimit: 10;
    }

    .st4,
    .st5,
    .st6 {
        -webkit-animation: downMotion 8s ease-in-out infinite;
        -moz-animation: downMotion 8s ease-in-out infinite;
        animation: downMotion 8s ease-in-out infinite;
    }

    .st0 {
        -webkit-animation: upMotion 10s ease-in-out infinite;
        -moz-animation: upMotion 10s ease-in-out infinite;
        animation: upMotion 10s ease-in-out infinite;
    }

    .st7,
    .run {
        -webkit-animation: upMotion 6s ease-in-out infinite;
        -moz-animation: upMotion 6s ease-in-out infinite;
        animation: upMotion 6s ease-in-out infinite;
    }

    .rotate {
        transform-origin: 116px 88px;
        -webkit-animation: 4s rotate infinite linear;
        animation: 4s rotate infinite linear;
    }

    @keyframes downMotion {
        0% {
            transform: translate(0, -15px);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(0, -15px);
        }
    }

    @-webkit-keyframes downMotion {
        0% {
            transform: translate(0, -15px);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(0, -15px);
        }
    }

    @-moz-keyframes downMotion {
        0% {
            transform: translate(0, -15px);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(0, -15px);
        }
    }

    @keyframes upMotion {
        0% {
            transform: translate(0, 15px);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(0, 15px);
        }
    }

    @-webkit-keyframes upMotion {
        0% {
            transform: translate(0, 15px);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(0, 15px);
        }
    }

    @-moz-keyframes upMotion {
        0% {
            transform: translate(0, 15px);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(0, 15px);
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @-moz-keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @-o-keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @media only screen and (min-width: 992px) {
        .container {
            justify-content: center;
            height: calc(100vh - 16px);
            display: flex;
            flex-wrap: wrap;
        }

        .col-L {
            width: 40%;
            margin-right: 10%;
        }
    }

    @media only screen and (max-width: 991px) {
        .flex-column {
            flex-direction: column;
        }
    }
}