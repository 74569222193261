.island-topup-modal {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10rem;
  border-radius: 1.6rem;
  overflow: hidden;
  z-index: 200;
}

.island-topup-modal .checkbox-size {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.island-topup-modal .modal-mobile {
  background-color: #ffffff;
  border: 1.5px solid;
  border-color: transparent;
  position: relative;
}

.island-topup-modal .content {
  display: flex;
  left: 20px;
  position: absolute;
  top: 90px;
}

.island-topup-modal .left {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 569px;
  justify-content: space-between;
  padding: 0px 2px;
  position: relative;
}

.island-topup-modal .details {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 490px;
}

.island-topup-modal .payment-detail {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 26.65px;
  position: relative;
  width: 100%;
}

.island-topup-modal .text-wrapper-2 {
  color: #00000099;
  flex: 1;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 16.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1.67px;
  position: relative;
}

.island-topup-modal .text-wrapper-3 {
  color: #121212;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 16.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1.67px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.island-topup-modal .enterance {
  height: 81px;
  position: relative;
  width: 507px;
}

.island-topup-modal .input {
  background-color: #f5f5f5 !important;
  border: 0.5px solid !important;
  border-color: #eeeeee !important;
  height: 52px !important;
  left: -1px !important;
  position: absolute !important;
  top: -1px !important;
  width: 509px !important;
}

.island-topup-modal .field-instance {
  color: #00000066 !important;
  font-family: 'Inter', Helvetica !important;
  font-size: 17px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  top: 13px !important;
  width: 475px !important;
}

.island-topup-modal .options {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 15px;
  position: absolute;
  top: 57px;
  width: 484px;
}

.island-topup-modal .min {
  color: transparent;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.island-topup-modal .span {
  color: #153458;
  font-weight: 500;
}

.island-topup-modal .text-wrapper-4 {
  color: #1a2b40;
  font-family: 'Acumin Pro-Regular', Helvetica;
}

.island-topup-modal .text-wrapper-5 {
  color: #1a2b40;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-weight: 700;
}

.island-topup-modal .max {
  color: transparent;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.island-topup-modal .description {
  height: 304px;
  margin-right: -2px;
  position: relative;
  width: 522px;
}

.island-topup-modal .overlap-group {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/bg-4.png);
  background-size: 100% 100%;
  height: 304px;
  position: relative;
  width: 520px;
  overflow-y: scroll;
}

.island-topup-modal .bg {
  height: 94px;
  left: 504px;
  position: absolute;
  top: 23px;
  width: 8px;
}

.island-topup-modal .please-ensure-to {
  color: transparent;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 15px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: 21.5px;
  position: absolute;
  top: 29px;
  width: 466px;
}

.island-topup-modal .text-wrapper-6 {
  color: #000000;
}

.island-topup-modal .text-wrapper-7 {
  color: #551a8b;
}

.island-topup-modal .text-wrapper-8 {
  color: #0000ff;
}

.island-topup-modal .text-wrapper-9 {
  color: #3c44ff;
}

.island-topup-modal .right {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 588px;
  justify-content: space-between;
  position: relative;
  width: 524px;
}

.island-topup-modal .header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 210.32px;
  position: relative;
  width: 347.67px;
}

.island-topup-modal .success-icon {
  background-color: #ffffff;
  border-radius: 46.63px;
  height: 93.26px;
  position: relative;
  width: 93.26px;
}

.island-topup-modal .overlap {
  background-color: #23a26d1f;
  border-radius: 36px/36.5px;
  height: 73px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 72px;
}

.island-topup-modal .vuesax-bold-tick {
  height: 53px;
  left: 9px;
  position: relative;
  top: 10px;
  width: 53px;
}

.island-topup-modal .tick-circle-wrapper {
  height: 53px;
}

.island-topup-modal .tick-circle {
  height: 53px;
  position: relative;
  width: 53px;
}

.island-topup-modal .overlap-group-2 {
  height: 44px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 44px;
}

.island-topup-modal .rectangle {
  background-color: #ffffff;
  height: 25px;
  left: 8px;
  position: absolute;
  top: 9px;
  width: 27px;
}

.island-topup-modal .vector {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 44px;
}

.island-topup-modal .img {
  height: 53px;
  left: 5821px;
  position: absolute;
  top: 7643px;
  width: 53px;
}

.island-topup-modal .header-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1px;
  position: relative;
  width: 100%;
}

.island-topup-modal .text-wrapper-10 {
  align-self: stretch;
  color: #474747;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 22.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1.67px;
  position: relative;
  text-align: center;
}

.island-topup-modal .text-wrapper-11 {
  align-self: stretch;
  color: #121212;
  font-family: 'Acumin Pro-Semibold', Helvetica;
  font-size: 31px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 53.3px;
  position: relative;
  text-align: center;
}

.island-topup-modal .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  margin-top: -0.33px;
  position: relative;
}

.island-topup-modal .group {
  height: 242.01px;
  position: relative;
  width: 524px;
}

.island-topup-modal .overlap-group-3 {
  height: 240px;
  position: relative;
}

.island-topup-modal .rectangle-2 {
  border: 1.4px dashed;
  border-color: #00000012;
  border-radius: 8px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 524px;
}

.island-topup-modal .payment-details {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  left: 23px;
  padding: 33px 0px 0px;
  position: absolute;
  top: 0;
  width: 479px;
}

.island-topup-modal .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.island-topup-modal .line {
  height: 1.67px;
  position: relative;
  width: 479px;
}

.island-topup-modal .frame-2 {
  height: 19px;
  position: relative;
  width: 374px;
  left: 22px;
  cursor: pointer;
}

.island-topup-modal .image {
  height: 19px;
  left: 19px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 19px;
}

.island-topup-modal .p {
  color: #00000099;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0.28px;
  line-height: normal;
  position: absolute;
  top: 2px;
  width: 330px;
  cursor: pointer;
}

.island-topup-modal .item {
  height: 54px;
  margin-right: -2px;
  position: relative;
  width: 526px;
}

.island-topup-modal .div-wrapper {
  background-color: #153458;
  border-radius: 8px;
  height: 54px;
  position: relative;
  width: 524px;
}

.island-topup-modal .text-wrapper-12 {
  color: #ffffff;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 217px;
  letter-spacing: 0;
  line-height: 29.3px;
  position: absolute;
  top: 14px;
  width: 90px;
}

.island-topup-modal .line-2 {
  height: 1px;
  left: 12px;
  position: absolute;
  top: 206px;
  width: 404px;
}

.island-topup-modal .left-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 569px;
  justify-content: space-between;
  padding: 0px 2px;
  position: relative;
}

.island-topup-modal .overlap-group-4 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/bg-6.png);
  background-size: 100% 100%;
  height: 283px;
  position: relative;
  width: 520px;
  overflow-y: scroll;
}

.island-topup-modal .line-3 {
  height: 564px;
  position: relative;
  width: 1.67px;
}

.island-topup-modal .right-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  position: relative;
}

.island-topup-modal .vector-2 {
  height: 53px;
  left: 6383px;
  position: absolute;
  top: 8232px;
  width: 53px;
}

.island-topup-modal .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
}

.island-topup-modal .overlap-group-wrapper {
  height: 188px;
  position: relative;
  width: 425px;
}

.island-topup-modal .rectangle-3 {
  border: 1.4px dashed;
  border-color: #00000012;
  border-radius: 8px;
  height: 188px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 425px;
}

.island-topup-modal .payment-details-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  left: 40px;
  padding: 18px 0px 0px;
  position: absolute;
  top: 0;
  width: 347.67px;
}

.island-topup-modal .line-4 {
  height: 1.67px;
  margin-left: -16.66px;
  margin-right: -16.66px;
  position: relative;
  width: 381px;
}

.island-topup-modal .overlap-wrapper {
  height: 54px;
  margin-right: -2px;
  position: relative;
  width: 427px;
}

.island-topup-modal .overlap-2 {
  background-color: #153458;
  border-radius: 8px;
  height: 54px;
  position: relative;
  width: 425px;
}

.island-topup-modal .text-wrapper-13 {
  color: #ffffff;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 176px;
  letter-spacing: 0;
  line-height: 29.3px;
  position: absolute;
  top: 14px;
  width: 73px;
}

.island-topup-modal .hr {
  background-color: #f7fafc;
  height: 2px;
  left: 0;
  position: absolute;
  top: 73px;
}

.island-topup-modal .header-2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 26px;
  position: absolute;
  top: 18px;
}

.island-topup-modal .text-wrapper-14 {
  color: #000000;
  font-family: 'Inter', Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 394.18px;
}

.island-topup-modal .close-btn {
  background-color: #e9f1f599;
  border-radius: 24px;
  height: 38px;
  position: relative;
  width: 38.31px;
  cursor: pointer;
  transition: 0.3s;
}
.island-topup-modal .close-btn:hover {
  transform: rotate(360deg);
}

.island-topup-modal .union {
  height: 12px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 12px;
}

@media screen and (max-width: 590px) {
  .island-topup-modal .modal-mobile {
    width: 440px !important;
  }

  .island-topup-modal .left {
    width: 100% !important;
  }

  .island-topup-modal .header-2 {
    width: 390px !important;
  }

  .island-topup-modal .details,
  .island-topup-modal .content,
  .island-topup-modal .div-wrapper,
  .island-topup-modal .description,
  .island-topup-modal .overlap-group,
  .island-topup-modal .overlap-group input,
  .island-topup-modal .enterance,
  .island-topup-modal .input,
  .island-topup-modal .right,
  .island-topup-modal .group,
  .island-topup-modal .rectangle-2,
  .island-topup-modal .payment-details,
  .island-topup-modal .frame {
    width: 100% !important;
  }

  .island-topup-modal .payment-details {
    left: 0;
    padding: 3.5rem 2rem 0;
  }

  .island-topup-modal .content {
    left: 0;
    padding: 2rem;
  }

  .island-topup-modal .options {
    width: -webkit-fill-available;
  }

  .island-topup-modal .line {
    width: 320px;
  }

  .island-topup-modal .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .island-topup-modal .text-wrapper-12 {
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  }

  .island-topup-modal .text-wrapper-14 {
    width: 250px;
  }
}

@media screen and (max-width: 460px) {
  .island-topup-modal .modal-mobile {
    width: 90vw !important;
    height: 1317px !important;
  }

  .island-topup-modal .frame-2 {
    left: 0;
  }

  .island-topup-modal .left {
    width: 100% !important;
  }

  .island-topup-modal .header-2 {
    width: 390px !important;
  }

  .island-topup-modal .details,
  .island-topup-modal .content,
  .island-topup-modal .div-wrapper,
  .island-topup-modal .description,
  .island-topup-modal .overlap-group,
  .island-topup-modal .overlap-group input,
  .island-topup-modal .enterance,
  .island-topup-modal .input,
  .island-topup-modal .right,
  .island-topup-modal .group,
  .island-topup-modal .rectangle-2,
  .island-topup-modal .payment-details,
  .island-topup-modal .frame {
    width: 100% !important;
  }

  .island-topup-modal .payment-details {
    left: 0;
    padding: 3.5rem 2rem 0;
  }

  .island-topup-modal .content {
    left: 0;
    padding: 2rem;
  }

  .island-topup-modal .options {
    width: -webkit-fill-available;
  }

  .island-topup-modal .line {
    width: 320px;
  }

  .island-topup-modal .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .island-topup-modal .text-wrapper-12 {
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  }

  .island-topup-modal .text-wrapper-14 {
    width: 250px;
  }

  .island-topup-modal .text-wrapper-2,
  .island-topup-modal .text-wrapper-3 {
    font-size: 13.5px;
  }

  .island-topup-modal .header-2 {
    left: 0;
    padding: 0 2.1rem;
    width: 100% !important;
  }
}

@media screen and (max-width: 410px) {
  .island-topup-modal .line {
    width: 270px;
  }
}

@media screen and (max-width: 370px) {
  .island-topup-modal .rectangle-2 {
    height: 280px;
  }
  .island-topup-modal .modal-mobile {
    width: 94vw !important;
    height: 1324px !important;
  }
  .island-topup-modal .item {
    bottom: -16px;
  }
}
