.my-wallet-deposit-modal {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10rem;
  border-radius: 1.6rem;
  overflow: hidden;
  z-index: 200;
}

.deposit-ck-editor-data {
  width: 425px;
  height: 253px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #dee1e3;
  background: #f7fafc;
  background-blend-mode: multiply;
  padding: 2rem;
  overflow-y: scroll;
}

.deposit-ck-editor-data-switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
}
.deposit-ck-editor-data-switch-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  min-width: 200px;
  height: 35px;
  flex-shrink: 0;
}
.deposit-ck-editor-data-switch-btn.active {
  background: #0089cf;
  color: #fff;
  text-align: center;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 556px) {
  .deposit-ck-editor-data {
    width: 100%;
  }

  .deposit-ck-editor-data-switch {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1037px) {
  .deposit-ck-editor-data {
    width: 100%;
  }
}

.island-withdrawal-modal .checkbox-size {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.my-wallet-deposit-modal .modal-deposit-sm {
  background-color: #ffffff;
  border: 1px none;
  position: relative;
}

.my-wallet-deposit-modal .content {
  align-items: flex-start;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  left: 13px;
  position: absolute;
  top: 97px;
  width: 395px;
}

.my-wallet-deposit-modal .left {
  height: 657px;
  position: relative;
  width: 395px;
}

.my-wallet-deposit-modal .description {
  height: 117px;
  left: 0;
  position: absolute;
  top: 540px;
  width: 397px;
}

.my-wallet-deposit-modal .overlap-group {
  background-image: url(./assets/bg.svg);
  background-size: 100% 100%;
  height: 117px;
  position: relative;
  width: 395px;
}

.my-wallet-deposit-modal .text-wrapper {
  color: #000000;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 15px;
  font-weight: 500;
  left: 18px;
  letter-spacing: 0;
  line-height: 21.5px;
  position: absolute;
  top: 19px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .note {
  height: 55px;
  left: 0;
  position: absolute;
  top: 471px;
  width: 397px;
}

.my-wallet-deposit-modal .overlap {
  background-image: url(./assets/bg-1.svg);
  background-size: 100% 100%;
  height: 55px;
  position: relative;
  width: 395px;
}

.my-wallet-deposit-modal .note-the-minimum {
  color: #000000;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 9px;
  width: 363px;
}

.my-wallet-deposit-modal .span {
  font-weight: 700;
}

.my-wallet-deposit-modal .text-wrapper-2 {
  font-family: 'Acumin Pro-Regular', Helvetica;
}

.my-wallet-deposit-modal .inputs {
  height: 292px;
  left: 0;
  position: absolute;
  top: 143px;
  width: 395px;
}

.my-wallet-deposit-modal .amount-input {
  height: 81px;
  left: 0;
  position: absolute;
  top: 211px;
  width: 395px;
}

.my-wallet-deposit-modal .min-max {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 12px;
  position: absolute;
  top: 57px;
  width: 377px;
}

.my-wallet-deposit-modal .min {
  color: transparent;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-wallet-deposit-modal .text-wrapper-3 {
  color: #153458;
  font-weight: 500;
}

.my-wallet-deposit-modal .text-wrapper-4 {
  color: #1a2b40;
  font-family: 'Acumin Pro-Regular', Helvetica;
}

.my-wallet-deposit-modal .text-wrapper-5 {
  color: #1a2b40;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-weight: 700;
}

.my-wallet-deposit-modal .max-unlimited {
  color: transparent;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.my-wallet-deposit-modal .input {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 395px;
}

.my-wallet-deposit-modal .div {
  border-radius: 8px;
  height: 52px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 397px;
}

.my-wallet-deposit-modal .field {
  background-color: #f5f5f5;
  border: 0.5px solid;
  border-color: #eeeeee;
  border-radius: 8px;
  height: 52px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 397px;
}

.my-wallet-deposit-modal .value {
  color: #00000066;
  font-family: 'Inter', Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 24px;
  left: 16px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
  width: 363px;
}

.my-wallet-deposit-modal .info {
  height: 25px;
  left: 361px;
  position: absolute;
  top: 14px;
  width: 27px;
}

.my-wallet-deposit-modal .overlap-group-2 {
  border: 2px solid;
  border-color: #9ba8b6;
  border-radius: 11.01px/10.38px;
  height: 21px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 22px;
}

.my-wallet-deposit-modal .ellipse {
  height: 2px;
  left: 8px;
  position: absolute;
  top: 3px;
  width: 2px;
}

.my-wallet-deposit-modal .vector {
  height: 7px;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 2px;
}

.my-wallet-deposit-modal .tx-input {
  height: 50px;
  left: 0;
  position: absolute;
  top: 148px;
  width: 395px;
}

.my-wallet-deposit-modal .date-input {
  height: 50px;
  left: 0;
  position: absolute;
  top: 85px;
  width: 397px;
}

.my-wallet-deposit-modal .overlap-2 {
  border-radius: 8px;
  height: 52px;
  left: -1px;
  position: relative;
  top: -1px;
}

.my-wallet-deposit-modal .text-wrapper-6 {
  color: #121212;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 16.6px;
  font-weight: 500;
  left: 285px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 95px;
}

.my-wallet-deposit-modal .wallet-input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 395px;
}

.my-wallet-deposit-modal .text-wrapper-7 {
  color: #000000;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21.5px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-wallet-deposit-modal .field-wrapper {
  height: 50px;
  position: relative;
  width: 395px;
}

.my-wallet-deposit-modal .value-wrapper {
  background-color: #f5f5f5;
  border: 0.5px solid;
  border-color: #eeeeee;
  border-radius: 8px;
  height: 52px;
  left: -1px;
  overflow: hidden;
  position: relative;
  top: -1px;
  width: 397px;
}

.my-wallet-deposit-modal .details {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 395px;
}

.my-wallet-deposit-modal .detail {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 363px;
}

.my-wallet-deposit-modal .text-wrapper-8 {
  color: #00000099;
  flex: 1;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 16.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1.67px;
  position: relative;
}

.my-wallet-deposit-modal .text-wrapper-9 {
  color: #121212;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 16.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1.67px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-wallet-deposit-modal .title {
  height: 30px;
  margin-right: -94px;
  position: relative;
  width: 457px;
}

.my-wallet-deposit-modal .value-2 {
  color: #00000099;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 16.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .icon {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 68px;
  padding: 0px 0px 2px;
  position: absolute;
  top: 2px;
}

.my-wallet-deposit-modal .img {
  height: 25px;
  position: relative;
  width: 25px;
}

.my-wallet-deposit-modal .text-wrapper-10 {
  color: #121212;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 16.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-left: -118px;
  margin-top: -1.67px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-wallet-deposit-modal .tabs {
  background-color: #f7fafc;
  border-radius: 8px;
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 391px;
}

.my-wallet-deposit-modal .frame {
  align-items: center;
  display: flex;
  gap: 6px;
  justify-content: center;
  left: 13px;
  position: relative;
  top: 5px;
  width: 370px;
}

.my-wallet-deposit-modal .crypto-tab {
  height: 38.4px;
  margin-left: -6.22px;
  position: relative;
  width: 190.04px;
}

.my-wallet-deposit-modal .div-wrapper {
  background-color: #153458;
  border-radius: 8px;
  box-shadow: 0px 4px 20px #1f223340;
  height: 38px;
  position: relative;
  width: 188px;
}

.my-wallet-deposit-modal .text-wrapper-11 {
  color: #f7fafc;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 24px;
  left: 54px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 78px;
}

.my-wallet-deposit-modal .bank-tab {
  height: 38.4px;
  margin-right: -8.22px;
  position: relative;
  width: 190.04px;
}

.my-wallet-deposit-modal .overlap-3 {
  background-color: #f7fafc;
  border-radius: 8px;
  height: 38px;
  position: relative;
  width: 188px;
}

.my-wallet-deposit-modal .text-wrapper-12 {
  color: #9a9fbf;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 24px;
  left: 67px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 55px;
}

.my-wallet-deposit-modal .right {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  position: relative;
  width: 394px;
}

.my-wallet-deposit-modal .content-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 611px;
  position: relative;
  width: 394px;
}

.my-wallet-deposit-modal .wallet-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 381px;
  justify-content: center;
  position: relative;
  width: 390px;
}

.my-wallet-deposit-modal .qr {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21px;
  position: relative;
  margin-top: 23px;
}

@media screen and (max-width: 1037px) {
  .my-wallet-deposit-modal .qr {
    margin-top: unset;
  }
}

.my-wallet-deposit-modal .qa-code {
  height: 138px;
  position: relative;
  width: 138px;
}

.my-wallet-deposit-modal .p {
  color: #000000;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  width: 333px;
}

.my-wallet-deposit-modal .line {
  height: 1.4px;
  margin-left: -6.81px;
  margin-right: -6.81px;
  position: relative;
  width: 403.62px;
}

.my-wallet-deposit-modal .wallet {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  padding: 11px 0px 0px;
  position: relative;
  width: 390px;
}

.my-wallet-deposit-modal .wallet-address {
  color: #373d50;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.my-wallet-deposit-modal .copy-btn {
  align-items: center;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 7px 21px;
  position: relative;
}

.my-wallet-deposit-modal .text {
  color: #000000;
  font-family: 'Arial-Regular', Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-wallet-deposit-modal .result-info {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 393px;
}

.my-wallet-deposit-modal .bg {
  border: 1.4px dashed;
  border-color: #00000012;
  border-radius: 8px;
  height: 218px;
  position: relative;
  width: 393px;
}

.my-wallet-deposit-modal .payment-details {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 11px;
  left: 22px;
  padding: 14px 0px 0px;
  position: absolute;
  top: 5px;
  width: 350px;
}

.my-wallet-deposit-modal .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 340px;
}

.my-wallet-deposit-modal .item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.my-wallet-deposit-modal .line-2 {
  height: 1.67px;
  position: relative;
  width: 320px;
}

.my-wallet-deposit-modal .title-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 3px;
  position: relative;
}

.my-wallet-deposit-modal .text-wrapper-13 {
  color: #00000099;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 16.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  /* z-index: 1; */
}

.my-wallet-deposit-modal .info-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 0px 2px;
  position: relative;
  z-index: 0;
}

.my-wallet-deposit-modal .submit-btn {
  height: 54px;
  margin-left: -1px;
  margin-right: -3px;
  position: relative;
  width: 398px;
}

.my-wallet-deposit-modal .overlap-group-3 {
  background-color: #153458;
  border-radius: 8px;
  height: 54px;
  position: relative;
  width: 396px;
}

.my-wallet-deposit-modal .text-wrapper-14 {
  color: #ffffff;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 162px;
  letter-spacing: 0;
  line-height: 29.3px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .content-3 {
  border-radius: 16px;
  display: flex;
  position: absolute;
  top: 97px;
}

.my-wallet-deposit-modal .left-2 {
  height: 612px;
  position: relative;
}

.my-wallet-deposit-modal .overlap-group-wrapper.crypto {
  height: 117px;
  position: absolute;
  top: 549px;
}

.my-wallet-deposit-modal .overlap-group-wrapper.bank {
  height: 117px;
  position: absolute;
  top: 494px;
}

.my-wallet-deposit-modal .overlap-group-4.crypto {
  background-size: 100% 100%;
  min-height: 117px;
  position: relative;
  height: 100%;
}
.my-wallet-deposit-modal .overlap-group-4.bank {
  background-size: 100% 100%;
  min-height: 117px;
  position: relative;
}

.my-wallet-deposit-modal .text-wrapper-15 {
  color: #000000;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21.5px;
  position: absolute;
  top: 19px;
}

.my-wallet-deposit-modal .overlap-wrapper.crypto {
  height: 36px;
  position: absolute;
  top: 509px;
}

.my-wallet-deposit-modal .overlap-wrapper.bank {
  height: 36px;
  position: absolute;
  top: 453px;
}

.my-wallet-deposit-modal .note-the-minimum-wrapper {
  background-size: 100% 100%;
  height: 36px;
  position: relative;
}

.my-wallet-deposit-modal .note-the-minimum-2 {
  color: #000000;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 9px;
}

.my-wallet-deposit-modal .inputs-2 {
  height: 340px;
  left: 0;
  position: absolute;
  top: 140px;
}

.my-wallet-deposit-modal .amount-input-2 {
  height: 81px;
  left: 0;
  position: absolute;
  top: 283px;
}
.my-wallet-deposit-modal .inputs-2-b .amount-input-2 {
  top: 218px;
}

.my-wallet-deposit-modal .min-max-2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 15px;
  position: absolute;
  top: 57px;
}

.my-wallet-deposit-modal .input-2 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
}

.my-wallet-deposit-modal .overlap-group-5 {
  border-radius: 8px;
  height: 52px;
  left: -1px;
  position: relative;
  top: -1px;
}
.my-wallet-deposit-modal .overlap-group-5 .mod-inside-input,
.new-modal-input {
  font-family: 'Inter', Helvetica;
  font-size: 17px;
  font-weight: 400;
  padding-right: 4.4rem;
}

.my-wallet-deposit-modal .field-2 {
  background-color: #f5f5f5;
  border: 0.5px solid;
  border-color: #eeeeee;
  border-radius: 8px;
  height: 52px;
  left: 0;
  overflow: visible;
  position: absolute;
  top: 0;
}

.my-wallet-deposit-modal .value-3 {
  color: #00000066;
  font-family: 'Inter', Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 24px;
  left: 16px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .info-2 {
  height: 25px;
  left: 470px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 25px;
}

.my-wallet-deposit-modal .info-3 {
  height: 25px;
  left: 486px;
  position: absolute;
  top: 14px;
  width: 26px;
}

.my-wallet-deposit-modal .overlap-group-6 {
  border: 2px solid;
  border-color: #9ba8b6;
  border-radius: 10.68px/10.38px;
  height: 21px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 21px;
}

.my-wallet-deposit-modal .tx-input-2 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 218px;
}

.my-wallet-deposit-modal .overlap-4 {
  border-radius: 8px;
  height: 52px;
  left: -1px;
  position: relative;
  top: -1px;
}

.my-wallet-deposit-modal .field-3 {
  background-color: #f5f5f5;
  border: 0.5px solid;
  border-color: #eeeeee;
  border-radius: 8px;
  height: 52px;
  left: 0;
  overflow: visible;
  position: absolute;
  top: 0;
}

.my-wallet-deposit-modal .value-4 {
  color: #00000066;
  font-family: 'Inter', Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 24px;
  left: 16px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .date-input-2 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 153px;
}

.my-wallet-deposit-modal .overlap-5 {
  border-radius: 8px;
  height: 52px;
  left: -1px;
  position: relative;
  top: -1px;
}

.my-wallet-deposit-modal .field-4 {
  background-color: #f5f5f5;
  border: 0.5px solid;
  border-color: #eeeeee;
  border-radius: 8px;
  height: 52px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.my-wallet-deposit-modal .value-5 {
  color: #00000066;
  font-family: 'Inter', Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 24px;
  left: 16px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .text-wrapper-16 {
  color: #121212;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 16.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 13px;
}

.my-wallet-deposit-modal .wallet-input-2 {
  align-items: flex-start;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
}

.my-wallet-deposit-modal .input-3 {
  height: 50px;
  position: relative;
}

.my-wallet-deposit-modal .field-5 {
  background-color: #f5f5f5;
  border: 0.5px solid;
  border-color: #eeeeee;
  border-radius: 8px;
  height: 52px;
  left: -1px;
  overflow: hidden;
  position: relative;
  top: -1px;
}

.my-wallet-deposit-modal .value-6 {
  color: #00000066;
  font-family: 'Inter', Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 24px;
  left: 16px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .details-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 4px;
  position: absolute;
  top: 64px;
}

.my-wallet-deposit-modal .detail-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 490px;
}

.my-wallet-deposit-modal .title-3 {
  height: 30px;
  position: relative;
  width: 457px;
}

.my-wallet-deposit-modal .tabs-2 {
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 48px;
  justify-content: center;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 1037px) {
  .my-wallet-deposit-modal .tabs-2 {
    margin-bottom: 17px;
  }
}

.my-wallet-deposit-modal .tab-bg {
  background-color: #f7fafc;
  border-radius: 8px;
  height: 48px;
  position: relative;
  width: 507px;
}

.my-wallet-deposit-modal .crypto-tab-2 {
  height: 38px;
  left: 7px;
  position: absolute;
  top: 5px;
  width: 242px;
}

.my-wallet-deposit-modal .overlap-group-7 {
  background-color: #153458;
  border-radius: 8px;
  box-shadow: 0px 4px 20px #1f223340;
  height: 38px;
  position: relative;
  width: 240px;
}

.my-wallet-deposit-modal .text-wrapper-17 {
  color: #f7fafc;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 24px;
  left: 91px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.my-wallet-deposit-modal .bank-tab-2 {
  height: 38px;
  left: 260px;
  position: absolute;
  top: 5px;
  width: 242px;
}

.my-wallet-deposit-modal .overlap-6 {
  background-color: #f7fafc;
  border-radius: 8px;
  height: 38px;
  position: relative;
  width: 240px;
}

.my-wallet-deposit-modal .text-wrapper-18 {
  color: #9a9fbf;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 20px;
  left: 97px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 45px;
}

.my-wallet-deposit-modal .right-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  position: relative;
  width: 524px;
}

.my-wallet-deposit-modal .content-4 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 521px;
}

.my-wallet-deposit-modal .wallet-info-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  height: 308px;
  justify-content: center;
  position: relative;
}

.my-wallet-deposit-modal .note-2 {
  color: #000000;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  width: 403px;
}

.my-wallet-deposit-modal .line-3 {
  height: 1.4px;
  position: relative;
  width: 403.62px;
}

.my-wallet-deposit-modal .wallet-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 478px;
}

.my-wallet-deposit-modal .wallet-address-2 {
  color: #373d50;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.my-wallet-deposit-modal .result-info-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.my-wallet-deposit-modal .bg-2 {
  border: 1.4px dashed;
  border-color: #00000012;
  border-radius: 8px;
  height: 218px;
  position: relative;
  width: 523px;
}

.my-wallet-deposit-modal .payment-details-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 11px;
  left: 24px;
  padding: 14px 0px 0px;
  position: absolute;
  top: 5px;
  width: 477px;
}

.my-wallet-deposit-modal .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.my-wallet-deposit-modal .line-4 {
  height: 1.67px;
  position: relative;
  width: 381px;
}

.my-wallet-deposit-modal .submit-btn-2 {
  height: 54px;
  margin-right: -2px;
  position: relative;
  width: 526px;
}

.my-wallet-deposit-modal .overlap-group-8 {
  background-color: #153458;
  border-radius: 8px;
  height: 54px;
  position: relative;
  width: 524px;
}

.my-wallet-deposit-modal .text-wrapper-19 {
  color: #ffffff;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 214px;
  letter-spacing: 0;
  line-height: 29.3px;
  position: absolute;
  top: 14px;
  width: 88px;
}

.my-wallet-deposit-modal .line-5 {
  height: 597px;
  position: relative;
  width: 1.67px;
}

.my-wallet-deposit-modal .right-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  position: relative;
}

.my-wallet-deposit-modal .content-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.my-wallet-deposit-modal .wallet-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 402px;
}

.my-wallet-deposit-modal .result-info-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin-top: 20px;
}

.my-wallet-deposit-modal .bg-3 {
  border: 1.4px dashed;
  border-color: #00000012;
  border-radius: 8px;
  height: 218px;
  position: relative;
  width: 425px;
}

.my-wallet-deposit-modal .payment-details-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 11px;
  left: 40px;
  padding: 14px 0px 0px;
  position: absolute;
  top: 5px;
  width: 348px;
}

.my-wallet-deposit-modal .line-6 {
  height: 1.67px;
  margin-left: -16.66px;
  margin-right: -16.66px;
  position: relative;
  width: 381px;
}

.my-wallet-deposit-modal .submit-btn-3 {
  height: 54px;
  margin-right: -2px;
  position: relative;
  width: 427px;
}

.my-wallet-deposit-modal .overlap-group-9 {
  background-color: #153458;
  border-radius: 8px;
  height: 54px;
  position: relative;
  width: 425px;
}

.my-wallet-deposit-modal .text-wrapper-20 {
  color: #ffffff;
  font-family: 'Acumin Pro-Bold', Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 176px;
  letter-spacing: 0;
  line-height: 29.3px;
  position: absolute;
  top: 14px;
  width: 73px;
}

.my-wallet-deposit-modal .hr {
  background-color: #f7fafc;
  height: 2px;
  left: 0;
  position: absolute;
  top: 73px;
}

.my-wallet-deposit-modal .header {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  left: 26px;
  position: absolute;
  top: 9px;
}

.my-wallet-deposit-modal .text-wrapper-21 {
  color: #000000;
  font-family: 'Inter', Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 394.18px;
}

.my-wallet-deposit-modal .close-btn {
  background-color: #e9f1f599;
  border-radius: 24px;
  height: 38px;
  position: relative;
  width: 38.31px;
  cursor: pointer;
  transition: 0.3s;
  transform: rotate(0deg);
}
.my-wallet-deposit-modal .close-btn:hover {
  transform: rotate(360deg);
}

.my-wallet-deposit-modal .union {
  height: 12px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 12px;
}

.my-wallet-deposit-modal .hr-2 {
  background-color: #f7fafc;
  height: 2px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 420px;
}

@media screen and (max-width: 590px) {
  .my-wallet-deposit-modal .modal-mobile {
    width: 440px !important;
  }

  .my-wallet-deposit-modal .modal-deposit-sm {
    height: 1550px !important;
  }

  .my-wallet-deposit-modal .left {
    width: 100% !important;
  }

  .my-wallet-deposit-modal .header-2 {
    width: 390px !important;
  }

  .my-wallet-deposit-modal .details,
  .my-wallet-deposit-modal .content,
  .my-wallet-deposit-modal .div-wrapper,
  .my-wallet-deposit-modal .description,
  .my-wallet-deposit-modal .overlap-group,
  .my-wallet-deposit-modal .overlap-group input,
  .my-wallet-deposit-modal .enterance,
  .my-wallet-deposit-modal .input,
  .my-wallet-deposit-modal .inputs,
  .my-wallet-deposit-modal .right,
  .my-wallet-deposit-modal .group,
  .my-wallet-deposit-modal .rectangle-2,
  .my-wallet-deposit-modal .payment-details,
  .my-wallet-deposit-modal .frame,
  .my-wallet-deposit-modal .field,
  .my-wallet-deposit-modal .note,
  .my-wallet-deposit-modal .overlap,
  .my-wallet-deposit-modal .tx-input,
  .my-wallet-deposit-modal .div,
  .my-wallet-deposit-modal .amount-input,
  .my-wallet-deposit-modal .field-wrapper,
  .my-wallet-deposit-modal .wallet-input,
  .my-wallet-deposit-modal .date-input,
  .my-wallet-deposit-modal .tabs {
    width: 100% !important;
  }

  .my-wallet-deposit-modal .payment-details {
    left: 0;
    padding: 1.8rem 2rem;
  }

  .my-wallet-deposit-modal .content {
    left: 0;
    padding: 2rem;
  }

  .my-wallet-deposit-modal .options,
  .my-wallet-deposit-modal .min-max {
    width: -webkit-fill-available;
  }

  .my-wallet-deposit-modal .line {
    width: 320px;
  }

  .my-wallet-deposit-modal .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my-wallet-deposit-modal .text-wrapper-12 {
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  }

  .my-wallet-deposit-modal .text-wrapper-14 {
    width: 250px;
  }

  .my-wallet-deposit-modal .header {
    left: 26px;
  }
  .my-wallet-deposit-modal .overlap-group-2 {
    left: -16px;
  }
}

@media screen and (max-width: 460px) {
  .my-wallet-deposit-modal .modal-mobile {
    width: 90vw !important;
    height: 1300px !important;
  }

  .my-wallet-deposit-modal .left {
    width: 100% !important;
  }

  .my-wallet-deposit-modal .header-2 {
    width: 390px !important;
  }

  .my-wallet-deposit-modal .details,
  .my-wallet-deposit-modal .content,
  .my-wallet-deposit-modal .div-wrapper,
  .my-wallet-deposit-modal .description,
  .my-wallet-deposit-modal .overlap-group,
  .my-wallet-deposit-modal .overlap-group input,
  .my-wallet-deposit-modal .enterance,
  .my-wallet-deposit-modal .input,
  .my-wallet-deposit-modal .right,
  .my-wallet-deposit-modal .group,
  .my-wallet-deposit-modal .rectangle-2,
  .my-wallet-deposit-modal .payment-details,
  .my-wallet-deposit-modal .frame {
    width: 100% !important;
  }

  .my-wallet-deposit-modal .payment-details {
    left: 0;
    padding: 3.5rem 2rem 0;
  }

  .my-wallet-deposit-modal .content {
    left: 0;
    padding: 2rem;
  }

  .my-wallet-deposit-modal .options {
    width: -webkit-fill-available;
  }

  .my-wallet-deposit-modal .line {
    width: 320px;
  }

  .my-wallet-deposit-modal .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my-wallet-deposit-modal .text-wrapper-12 {
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  }

  .my-wallet-deposit-modal .text-wrapper-14 {
    width: 250px;
  }

  .my-wallet-deposit-modal .text-wrapper-2,
  .my-wallet-deposit-modal .text-wrapper-3 {
    font-size: 13.5px;
  }

  .my-wallet-deposit-modal .header-2 {
    left: 0;
    padding: 0 2.1rem;
    width: 100% !important;
  }
}

@media screen and (max-width: 410px) {
  .my-wallet-deposit-modal .line {
    width: 270px;
  }
}

@media screen and (max-width: 370px) {
  .my-wallet-deposit-modal .rectangle-2 {
    height: 280px;
  }
  .my-wallet-deposit-modal .modal-mobile {
    width: 94vw !important;
    height: 1324px !important;
  }
  .my-wallet-deposit-modal .item {
    bottom: -16px;
  }
}
