.success-modal {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10rem;
  border-radius: 1.6rem;
  overflow: hidden;
  z-index: 200;
}

.success-modal .modal-success-sm {
  background-color: #ffffff;
  border: 1.5px solid;
  border-color: transparent;
  height: 704px;
  position: relative;
}

.success-modal .content {
  position: absolute;
  top: 90px;
}

.success-modal .right {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 590px;
  justify-content: space-between;
  position: relative;
}

.success-modal .header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 206.26px;
  position: relative;
  width: 347.67px;
}

.success-modal .success-icon {
  background-color: #23a26d1f;
  border-radius: 46.63px;
  height: 93.26px;
  position: relative;
  width: 93.26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-modal .vuesax-bold-tick {
  height: 53px;
  /* left: 19px; */
  position: relative;
  /* top: 20px; */
  width: 53px;
}

.success-modal .tick-circle-wrapper {
  height: 53px;
}

.success-modal .tick-circle {
  height: 53px;
  position: relative;
  width: 53px;
}

.success-modal .overlap-group {
  height: 44px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 44px;
}

.success-modal .rectangle {
  background-color: #ffffff;
  height: 25px;
  left: 8px;
  position: absolute;
  top: 9px;
  width: 27px;
}

.success-modal .vector {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 44px;
}

.success-modal .img {
  height: 53px;
  position: absolute;
  top: -888px;
  width: 53px;
}

.success-modal .header-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1px;
  position: relative;
  width: 100%;
}

.success-modal .text-wrapper {
  align-self: stretch;
  color: #23a26d;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 22.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1.67px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.success-modal .div {
  align-self: stretch;
  color: #121212;
  font-family: 'Acumin Pro-Semibold', Helvetica;
  font-size: 31px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 53.3px;
  position: relative;
  text-align: center;
}

.success-modal .line {
  height: 1.4px;
  position: relative;
}

.success-modal .overlap-wrapper {
  height: 368px;
  position: relative;
}

.success-modal .overlap {
  height: 368px;
  position: relative;
}

.success-modal .tx-details {
  height: 368px;
  left: 0;
  position: absolute;
  top: 0;
}

.success-modal .tx-items-wrapper {
  background-size: 100% 100%;
  height: 432px;
  position: relative;
  top: -40px;
}

.success-modal .tx-items {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 191px;
  position: relative;
  top: 81px;
  width: 353px;
}

.success-modal .tx-item {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.success-modal .text-wrapper-2 {
  color: #ffffffb2;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-modal .text-wrapper-3 {
  color: #ffffff;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-modal .tx-item-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.success-modal .tx-item-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.success-modal .tx-item-4 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.success-modal .frame {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6px;
  position: relative;
}

.success-modal .text-wrapper-4 {
  color: #ffffffb2;
  font-family: 'Acumin Pro-Regular', Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  z-index: 1;
}

.success-modal .info-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 0px 2px;
  position: relative;
  z-index: 1;
}

.success-modal .info {
  height: 25px;
  position: relative;
  width: 25px;
}

.success-modal .tx-item-5 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: -11.19px;
  position: relative;
}

.success-modal .receipt {
  background-size: 100% 100%;
  height: 104px;
  left: 0;
  position: absolute;
  top: 264px;
}

.success-modal .text {
  height: 40px;
  position: relative;
  width: 194px;
}

.success-modal .vuesax-linear-import {
  height: 25px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 35px;
}

.success-modal .text-wrapper-5 {
  color: #ffffff;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 44px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.success-modal .illustration {
  height: 581px;
  position: relative;
  width: 531px;
}

.success-modal .line-2 {
  height: 569px;
  position: relative;
  width: 1.67px;
}

.success-modal .right-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 590.26px;
  justify-content: space-between;
  position: relative;
}

.success-modal .vector-2 {
  height: 53px;
  left: -434px;
  position: absolute;
  top: -888px;
  width: 53px;
}

.success-modal .line-3 {
  height: 1.4px;
  position: relative;
  width: 403.62px;
}

.success-modal .overlap-group-wrapper {
  height: 368px;
  position: relative;
  width: 416px;
}

.success-modal .overlap-2 {
  height: 368px;
  position: relative;
}

.success-modal .div-wrapper {
  height: 368px;
  left: 0;
  position: absolute;
  top: 0;
  width: 416px;
}

.success-modal .overlap-group-2 {
  background-image: url(./assets/bg-main.svg);
  background-size: 100% 100%;
  height: 432px;
  left: -53px;
  position: relative;
  top: -40px;
  width: 501px;
}

.success-modal .tx-items-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 191px;
  left: 86px;
  position: relative;
  top: 81px;
  width: 353px;
}

.success-modal .tx-item-6 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 350px;
}

.success-modal .tx-item-7 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: -11.19px;
  position: relative;
  width: 350px;
}

.success-modal .receipt-2 {
  background-image: url(./assets/bg-subtracted.svg);
  background-size: 100% 100%;
  height: 104px;
  left: 0;
  position: absolute;
  top: 264px;
  width: 416px;
  cursor: not-allowed;
}

.success-modal .text-2 {
  height: 36px;
  left: 123px;
  position: relative;
  top: 35px;
  width: 188px;
  cursor: not-allowed;
}

.success-modal .vuesax-linear-import-2 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 27px;
}

.success-modal .text-wrapper-6 {
  color: #ffffff;
  font-family: 'Acumin Pro-Medium', Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 37px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 149px;
}

.success-modal .hr {
  background-color: #f7fafc;
  height: 2px;
  left: 0;
  position: absolute;
  top: 73px;
}

.success-modal .header-2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 26px;
  position: absolute;
  top: 18px;
}

.success-modal .text-wrapper-7 {
  color: #000000;
  font-family: 'Inter', Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 394.18px;
}

.success-modal .close-btn {
  background-color: #e9f1f599;
  border-radius: 24px;
  height: 38px;
  position: relative;
  width: 38.31px;
  cursor: pointer;
  transition: 0.3s;
}
.success-modal .close-btn:hover {
  transform: rotate(360deg);
}

.success-modal .union {
  height: 12px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 12px;
}

@media screen and (max-width: 590px) {
}

@media screen and (max-width: 460px) {
}

@media screen and (max-width: 410px) {
}

@media screen and (max-width: 370px) {
}
