@import url(.//assets/css/utils/font.scss);
@import url(.//assets/css/utils/reset.scss);

body {
  background: #e9f1f5;
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/****************** css ******************/
.sidebar {
  color: #153458 !important;
  position: initial;
  transition: 0.3s;

  @media (max-width: 992px) {
    position: absolute;
    left: -336px;
    transition: 0.3s;
    z-index: 1;
  }
}

.container {
  // min-height: 100vh;
  display: flex;
  align-items: center;
  // margin-left: 0 !important;
  // margin-right: 0 !important;
}

.register-flex {
  padding: 2rem;
  flex-direction: column !important;
  // background: #F5F5F5 !important;
  display: grid !important;
}

.pending-flex {
  width: 493px !important;

  @media (max-width: 768px) {
    width: 100% !important;
    padding: 20px !important;
  }
}

.chart-section {
  .skeleton-chart-fix {
    min-height: 25rem;

    @media screen and (max-width: 768px) {
      min-height: 30rem;
    }

    @media screen and (max-width: 480px) {
      min-height: 20rem;
    }
  }
}

.log-section {
  min-height: 673px;

  &.main {
    display: block !important;
  }
}

.chart-skeleton-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  align-content: center;
  height: 100%;
}

.skeleton-element-item {
  height: 21px !important;
  width: 8rem !important;
  opacity: 0.5;

  &.island-investment {
    height: 24px !important;
  }

  &.island-item {
    height: 14px !important;
  }

  &.small {
    height: 21px !important;
  }

  &.crew-connection-item {
    width: 28px !important;
  }

  &.crew-item {
    height: 24px !important;
  }

  &.crew-link {
    width: 98% !important;
  }

  &.total-connection {
    width: 44px !important;
  }

  &.ck-editor-content {
    height: 30px !important;
  }

  &.ck-editor-content {
    height: 88% !important;
    min-height: 600px;
    width: 100% !important;
    opacity: 0.5;
    margin-top: 1rem;
  }

  &.chart2 {
    height: 88% !important;
    min-height: 550px;
    width: 100% !important;
    opacity: 0.5;
    margin-top: 1rem;
  }

  &.chart1 {
    height: 88% !important;
    min-height: 323px;
    width: 100% !important;
    opacity: 0.5;
    margin-top: 1rem;
  }

  &.chart {
    height: 88% !important;
    width: 100% !important;
    opacity: 0.5;
    margin-top: 1rem;
  }

  &.related {
    width: 10rem !important;
  }

  &.date {
    width: 6rem !important;
  }

  &.bell {
    width: 3rem !important;
  }

  &.crew-fees-1 {
    width: 1.5rem !important;
    height: 18px !important;
  }

  &.crew-fees-2 {
    width: 3rem !important;
    height: 18px !important;
  }
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

/****************** media ******************/

@media screen and (max-width: 1024px) {
  .setting-post {
    grid-template-columns: 1fr !important;
  }

  .fillTreasureChest-post {
    grid-template-columns: 1fr !important;
  }
}

@media screen and (max-width: 960px) {
  .firstMyTreasure-post {
    grid-template-columns: 1fr !important;
  }

  .setting-post {
    grid-template-columns: 1fr !important;
  }

  .secondMyTreasure-post {
    margin: auto;
  }

  .thirdMyTreasure-post {
    margin: auto;
  }

  .fourthMyTreasure-post {
    margin: auto;
  }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
  .register-form {
    width: 100% !important;
  }

  .successful-flex {
    display: unset !important;
  }

  .login-flex {
    display: unset !important;
    padding: 1rem 0 0 5px !important;
  }

  .login-form {
    width: 100% !important;
  }

  .pending-flex {
    width: 72vw !important;
  }

  .approved-flex {
    width: 72vw !important;
  }
}

@media screen and (max-width: 564px) {
  .register-form {
    width: fit-content !important;
  }

  .register-flex {
    padding: 5.5px 0 0 5.5px !important;
  }
}

@media screen and (max-width: 373px) {
  .approved-flex {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
}

.copyright {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  z-index: 1;
  margin: auto auto 0 auto;
  font-size: 14px;
}

.date-picker-title {
  color: rgb(125 125 126);
  font-size: 14px;
  width: 100%;
  min-width: 9rem;
  margin-left: 1.6rem;
}

// Sidebar
.sidebar {
  background: #ffffff;
  padding: 26px 24px 24px;
  gap: 44px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 26px 24px 24px;
  // position: absolute;
  width: 316px;
  // height: 97.6vh;
  min-height: 97.6vh;
  // left: 17px;
  // top: 14px;
  margin: 10px;

  background: #ffffff;
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  position: initial;
  transition: 0.3s;
  // min-height: -webkit-fill-available;

  @media (max-width: 992px) {
    position: absolute;
    left: -326px;
    transition: 0.3s;
    z-index: 100;

    &.open {
      left: 0;
    }
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    align-items: center;
    width: 100%;
    margin: unset auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &-ft {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: -0.01em;
      color: #1a1b2d;
    }

    &-st {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: -0.01em;
      color: #535763;
    }
  }

  &-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    width: 268px;
    height: 48px;
    // background: #FFFFFF;
    border-radius: 8px;

    &-t {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: -0.01em;
      color: #535763;
    }
  }

  &-option {
    display: grid;
    align-items: flex-start;
    gap: 12px;
    width: 255px;
    height: profileOpener;
    background: rgba(83, 87, 99, 0.04);
    border-radius: 8px;
    overflow: hidden;

    &-g {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: -0.01em;
      color: rgb(255, 255, 255);
      background: #153458;
      display: flex;
      flex-direction: row;
      padding: 12px;
      gap: 12px;
      width: 230px;
      height: 28px;
      border-radius: 8px;

      &-b {
        font-weight: 600;
        font-size: 15px;
        letter-spacing: -0.01em;
        color: rgb(255, 255, 255);
        background: unset;
        border: unset;
      }
    }

    &-f-index {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: -0.01em;
      color: #153458;
      padding-left: 12px;
      display: flex;
      gap: 0.3rem;
    }

    &-s-index {
      font-weight: 500;
      font-size: 13px;
      letter-spacing: -0.01em;
      color: #153458;
      padding-left: 12px;
      display: flex;
      gap: 0.3rem;
    }
  }

  &-exit {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    width: 230px;
    height: 28px;
    background: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #535763;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    .sidebar-link {
      cursor: pointer;
      border-radius: 8px;
      transition: 0.3s ease-in-out;

      p {
        font-size: 16px;
        position: relative;
        top: 1.5px;
        letter-spacing: 0.3px;
      }

      .icon {
        width: 24px;
        height: 24px;
        display: inline-block;

        &-wizard {
          background: url(.//assets/icons/wizard.svg) no-repeat;
        }

        &-identity {
          background: url(.//assets/icons/identity.svg) no-repeat;
        }

        &-status {
          background: url(.//assets/icons/status.svg) no-repeat;
        }

        &-welcome {
          background: url(.//assets/icons/welcome.svg) no-repeat;
        }

        &-map {
          background: url(.//assets/icons/map.svg) no-repeat;
        }

        &-island {
          background: url(.//assets/icons/island.svg) no-repeat;
        }

        &-treasury {
          background: url(.//assets/icons/treasury.svg) no-repeat;
        }

        &-crew {
          background: url(.//assets/icons/crew.svg) no-repeat;
        }

        &-wallet {
          background: url(.//assets/icons/wallet.svg) no-repeat;
        }

        &-account {
          background: url(.//assets/icons/account.svg) no-repeat;
        }

        &-blub {
          background: url(.//assets/icons/light-blub.svg) no-repeat;
        }

        &-my-bos {
          background: url(.//assets/icons/my-bos.svg) no-repeat;
        }

        &-signout {
          background: url(.//assets/icons/signout.svg) no-repeat;
        }
      }

      .sidebar-options {
        transition: 0.3s;

        span,
        p {
          color: #153458;
        }

        &:hover {
          background-color: #e8f1f4;
        }
      }

      &.active-sub {
        background: #e8f1f4;
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        padding-bottom: 0.6rem;

        * {
          user-select: none;
        }

        .sidebar-options {
          background: #153458;
        }

        .sidebar-link.sub {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 0px 8px 12px;
        }

        p {
          font-size: 16px;
          color: #ffffff;
        }

        .icon {
          &-account {
            background: url(.//assets/icons/account-hover.svg) no-repeat;
          }

          &-blub {
            background: url(.//assets/icons/light-blub-hover.svg) no-repeat;
          }

          &-my-bos {
            background: url(.//assets/icons/my-bos-hover.svg) no-repeat;
          }
        }

        &:hover {
          background-color: #e8f1f4;
        }
      }

      &.active {
        background: #153458;

        .sidebar-options {
          background: unset;
        }

        p {
          font-size: 16px;
          color: #ffffff;
        }

        .icon {
          &-wizard {
            background: url(.//assets/icons/wizard-hover.svg) no-repeat;
          }

          &-identity {
            background: url(.//assets/icons/identity-hover.svg) no-repeat;
          }

          &-status {
            background: url(.//assets/icons/status-hover.svg) no-repeat;
          }

          &-welcome {
            background: url(.//assets/icons/welcome-hover.svg) no-repeat;
          }

          &-map {
            background: url(.//assets/icons/map-hover.svg) no-repeat;
          }

          &-island {
            background: url(.//assets/icons/island-hover.svg) no-repeat;
          }

          &-treasury {
            background: url(.//assets/icons/treasury-hover.svg) no-repeat;
          }

          &-crew {
            background: url(.//assets/icons/crew-hover.svg) no-repeat;
          }

          &-wallet {
            background: url(.//assets/icons/wallet-hover.svg) no-repeat;
          }

          &-account {
            background: url(.//assets/icons/account-hover.svg) no-repeat;
          }

          &-blub {
            background: url(.//assets/icons/light-blub-hover.svg) no-repeat;
          }

          &-my-bos {
            background: url(.//assets/icons/my-bos-hover.svg) no-repeat;
          }

          &-signout {
            background: url(.//assets/icons/signout-hover.svg) no-repeat;
          }
        }

        &:hover {
          background-color: #153458;
        }
      }
    }
  }
}

.icon.active-icon-wallet {
  background: url(.//assets/icons/wallet.svg) no-repeat;
}
.icon.active-icon-bos-help-desk {
  background: url(.//assets/icons/icon-bos-help-desk.svg) center no-repeat;
}
.active-sub .active-icon-bos-help-desk {
  background: url(.//assets/icons/icon-bos-help-desk-hover.svg) center no-repeat;
}

.active-sub .icon.active-icon-wallet {
  background: url(.//assets/icons/wallet-hover.svg) no-repeat;
}

.icon.active-icon-blub {
  background: url(.//assets/icons/light-blub.svg) no-repeat;
}

.active-sub .icon.active-icon-blub {
  background: url(.//assets/icons/light-blub-hover.svg) no-repeat;
}

.open-nav-mobile {
  display: none;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    width: 65px;
    height: 30px;
    border: 0;
    background: url(.//assets/icons/menu.svg) no-repeat;
    background-size: 3rem;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      background: url(.//assets/icons/menu-hover.svg) no-repeat;
      background-size: 3rem;
    }
  }
}

.close-nav-mobile {
  display: none;

  @media (max-width: 992px) {
    display: unset;
    position: absolute;
    left: 20px;
    top: 20px;
    width: 28px;
    height: 28px;
    border: 0;
    background: url(.//assets/icons/close.svg) no-repeat;
    background-size: 3rem;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      background: url(.//assets/icons/close-hover.svg) no-repeat;
      background-size: 3rem;
    }
  }
}

.sidebar-header {
  @media (max-width: 992px) {
    margin-top: 6rem;
  }
}

.parent {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media (max-width: 992px) {
    grid-template-columns: initial;
  }
}

// Application Form
.container-appf {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-appf {
  padding: 2rem;
  display: grid !important;
  justify-items: center;
}

.appf-steps {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  align-items: center;
}

.appf-step {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;

  &-active-num {
    width: 34px;
    height: 34px;
    left: 677px;
    top: 165px;
    background: #ffffff;
    border: 0.1875rem solid #000000;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
  }

  &-active-t {
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    color: #000000;
  }

  &-row {
    width: 100%;
    height: 6px;
    background: #ffffff;
    border-radius: 40px;
  }

  &-active-row {
    width: 25%;
    height: 6px;
    background: #000000;
    border-radius: 40px;
    position: relative;
  }

  &-deactivate-circle {
    width: 34px;
    height: 34px;
    left: 677px;
    top: 165px;
    background: #ffffff;
    border: 0.1875rem solid #e2e2e2;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
  }

  &-deactivate-num {
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    color: rgba(0, 0, 0, 0.1);
  }

  &-deactivate-row {
    width: 0%;
    height: 6px;
    background: #000000;
    border-radius: 40px;
    position: relative;
  }
}

.appf-type {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;
  padding: 24px 24px 30px 24px;
  width: 573px;

  @media (max-width: 768px) {
    padding: 20px;
    width: 100% !important;
  }

  &-header {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }

  &-cap {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.08em;
    color: rgba(0, 0, 0, 0.6);
  }

  &-info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    // margin-top: 10px;
    // margin-bottom: 30px;
  }

  &-label {
    font-weight: 600;
    font-size: 16px;
    color: #676767;
  }

  &-selector {
    background: #f5f5f5;
    border: 0.5px solid #eeeeee;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 1rem;
  }

  &-btn {
    cursor: pointer;
    width: 100%;
    background: #153458;
    border-radius: 8px;
    border: 0;
    border-radius: 8px;
    height: 58px;
    font-weight: 600;
    font-size: 20px;
    line-height: 133.4%;
    color: #ffffff;
    margin-top: 1rem;

    @media (max-width: 768px) {
      height: 48px;
      font-size: 16px;
    }
  }
}

.appf-des {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;
  padding: 30px;
  width: 573px;
  font-weight: 600;
  font-size: 25px;
  color: #0e608b;

  @media (max-width: 768px) {
    width: 100% !important;
    padding: 20px;
  }

  &-cap {
    font-weight: 500;
    font-size: 14px;
    color: #153458;
    line-height: 24px;
    margin-top: 10px;
  }
}

.appf-form {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;
  padding: 2rem;
  width: 573px;

  @media (max-width: 768px) {
    width: 100%;
  }

  &-h {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }

  &-cap {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2rem;
  }

  &-parent {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  &-input {
    padding: 15px;
    box-sizing: border-box;
    height: 48px;
    background: #f5f5f5;
    border: 0.5px solid #eeeeee;
    border-radius: 8px;
    width: 250px;
    font-size: small;
    text-align: right;

    &-input {
      height: 35px;
      border: unset;
      background-color: unset;
      width: 50%;
    }

    &-p {
      display: grid;
      grid-template-columns: 1fr;
      gap: 11px;

      .span-title {
        color: #153458cf;
        font-family: system-ui;
        font-size: 14px;
        width: 34px;
      }
    }

    &-combo {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      gap: 11px;
      margin-bottom: 30px;
    }

    &-label {
      font-weight: 600;
      font-size: 16px;
      color: #153458;
    }

    &-num-parent {
      padding: 4px;
      box-sizing: border-box;
      height: 48px;
      background: #f5f5f5;
      border: 0.5px solid #eeeeee;
      border-radius: 8px;
    }

    &-num-selector {
      background: rgba(255, 255, 255, 0.79);
      border: 0.5px solid #eeeeee;
      border-radius: 8px;
      height: 39px;
      padding: 0 0 0 1rem;
      font-weight: 400;
      font-size: 17px;
    }
  }

  &-upload {
    padding: 15px;
    box-sizing: border-box;
    height: 202px;
    background: #f8f8ff;
    border: 1px dashed rgba(56, 78, 183, 0.3);
    border-radius: 8px;
    text-align: center;

    &-btn {
      border: unset;
      background-color: unset;
    }

    &-t {
      display: flex;
      justify-content: center;
    }

    &-cap {
      font-weight: 400;
      font-size: 12px;
      align-items: center;
      text-align: center;
      color: #676767;
    }
  }

  &-text {
    display: grid;
    grid-template-columns: 1fr;
    gap: 11px;
    margin-bottom: 11px;
    color: #676767;
    font-weight: 600;
    font-size: 14px;
  }

  &-box {
    position: relative;
    background: #ffffff;
    border: 0.5px solid #e3e3e3;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr;

    &-p {
      display: flex;
      justify-content: space-between;
    }

    &-cap {
      font-weight: 400;
      font-size: 12px;
      align-items: center;
      text-align: center;
      color: #0f0f0f;
      padding-left: 11.51px;
    }

    &-pro {
      height: 3px;
      background: #483ea8;
      border-radius: 10px;
      bottom: 0;
    }

    &-uploaded {
      position: relative;
      background: #ffffff;
      border: 1px solid #11af22;
      border-radius: 4px;
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  &-submit {
    cursor: pointer;
    width: 100%;
    background: #153458;
    border-radius: 8px;
    border: 0;
    border-radius: 8px;
    height: 58px;
    font-weight: 600;
    font-size: 20px;
    line-height: 133.4%;
    color: #ffffff;
    margin-top: 2rem;

    @media (max-width: 768px) {
      font-size: 16px;
      height: 48px;
    }
  }
}

.container-kvc {
  background: #f5f5f5;
  justify-content: center;
}

.flex-kvc {
  background-color: white;
  padding: 64px 51px 40px 51px;
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;
  width: 493px;
}

.kvc {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 32px;
  padding: 2rem;
  max-width: 573px;

  @media (max-width: 768px) {
    padding: 15px;
  }

  &-check {
    background: rgb(245, 245, 245);
    border-radius: 36px;
    font-weight: 600;
    padding-left: 2px;
    padding-top: 6px;
    width: 60px;
    height: 60px;
    display: flex;
    font-size: 25px;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
  }

  &-parent {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 12px;
  }

  &-h {
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #153458;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &-t {
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    text-align: center;
    color: #153458;
  }

  &-cap {
    font-weight: 200;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: #153458;
  }

  &-btn {
    width: 100%;
    background: #153458;
    color: white;
    border: 0;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
  }
}

// Approved
.container-approved {
  background: #f5f5f5;
  justify-content: center;
  margin-left: 0.7rem;
}

.flex-approved {
  width: 493px !important;
  background-color: white;
  padding: 64px 0px 20px;
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;
  min-height: 510px;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.approved {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 2rem 0;

  &-h {
    font-weight: 600;
    font-size: 32px;
    align-items: center;
    text-align: center;
    color: #153458;
  }

  &-t {
    font-weight: 200;
    font-size: 16px;
    align-items: center;
    text-align: center;
    color: #153458;
    width: 90%;
  }

  &-parent {
    display: grid;
    gap: 1rem;
  }

  &-btn {
    cursor: pointer;
    height: 48px;
    background: #153458;
    border: 0;
    border-radius: 8px;
    margin-bottom: 18px;
    padding: 12px 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  &-skip {
    cursor: pointer;
    border: unset;
    background-color: unset;
    font-weight: 400;
    font-size: 17px;
    align-items: center;
    text-decoration-line: none;
    color: #000000;
  }
}

// BOS Crew
.container-boscrew {
  padding: 0;
  width: 100px;
  // max-width: 1200px !important;

  @media screen and (max-width: 1600px) {
    // max-width: 1000px !important;
  }

  @media screen and (max-width: 1400px) {
    // max-width: 992px !important;
  }

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 552px) {
  }
}

.flex-boscrew {
  display: grid;
  grid-template-columns: 1fr;
}

.boscrew {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-description {
    padding: 30px;
    color: #0e608b;
    background: #ffffff;
    border-radius: 16px;
    padding-left: 25px;
    margin-bottom: 1rem;

    strong {
      font-size: 20px;
    }

    p {
      margin-top: 20px;
    }

    @media screen and (min-width: 640px) {
      .button-group {
        flex-direction: row !important;
      }

      a,
      button {
        width: unset !important;
        padding: 14px !important;
        font-size: 15px !important;
      }
    }

    .button-group {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      row-gap: 10px;
      flex-direction: column;
      margin-top: 20px;
      column-gap: 10px;

      a,
      button {
        width: 100%;
        text-align: center;
        padding: 11px;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
        color: #fff;
        transition: 0.3s ease;
        background-color: #153458;
      }

      a:hover,
      button:hover {
        background-color: #214f85;
      }
    }
  }

  &-h {
    font-weight: 600;
    color: #0e608b;
    height: 62px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    margin-bottom: 24px;
    font-size: 16px;

    h4 {
      font-weight: 600;
      color: #0e608b;
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 65px;
      padding-left: 15px;
    }
  }

  &-parent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &-f-row {
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: flex-end;
    padding: 30px;
    width: 100%;
    position: relative;

    &:before {
      content: '';
      background-color: #166bb6;
      position: absolute;
      width: 75%;
      height: 5px;
      top: 49px;
      left: 184px;
      display: block;
      border-radius: 8px;
    }

    &-pl {
      position: absolute;
      top: 0;
      left: 5px;
    }

    &-p {
      display: grid;
      grid-template-columns: 1fr 3fr;
    }

    &-label {
      font-weight: 200;
      font-size: 20px;
      color: #373d50;
    }

    @media screen and (max-width: 1200px) {
      padding: 20px;

      &-p {
        margin-top: 70px;
        margin-bottom: 30px;
        grid-template-columns: 1fr;
      }
    }

    @media screen and (max-width: 768px) {
      &-p {
        grid-template-columns: 1fr;
      }
    }

    @media screen and (max-width: 552px) {
      padding: 15px;
      display: grid;
      grid-template-columns: 1fr;

      &-label {
        font-size: 16px;
      }
    }
  }

  &-f-amount {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.03em;
    color: #535763;

    @media screen and (max-width: 552px) {
      font-size: 16px;
    }
  }

  &-f-link {
    font-weight: 200;
    font-size: 16px;
    color: rgb(31, 31, 31);
    height: 45px;
    background: rgba(40, 43, 50, 0.05);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 15px;
    margin-right: 60px;

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }

  &-f-btn {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 92px;
    height: 37px;
    background-color: #153458;
    color: #ffffff;
    border-radius: 8px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      transition: 0.3s;
      background-color: #3d5c81;
    }
  }

  &-f-symbol {
    &-one {
      content: '';
      background-color: #166bb6;
      position: absolute;
      width: 60px;
      height: 5px;
      bottom: 58px;
      right: 130px;
      display: block;
      border-radius: 8px;
    }

    &-two {
      content: '';
      background-color: #166bb6;
      position: absolute;
      width: 34px;
      height: 5px;
      bottom: 58px;
      right: 195px;
      display: block;
      border-radius: 8px;
    }

    &-three {
      content: '';
      background-color: #166bb6;
      position: absolute;
      width: 22px;
      height: 5px;
      bottom: 58px;
      right: 233px;
      display: block;
      border-radius: 8px;
    }
  }

  &-f-circle {
    width: 140px;
    height: 140px;
    border: 14px solid #166bb6;
    border-radius: 85px;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    right: -67px;
    background-color: #ffffff;

    &-num {
      font-weight: 600;
      font-size: 28px;
      letter-spacing: 0.03em;
      color: #373d50;
    }

    &-cap {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #505566;
    }

    &-connect {
      font-weight: 200;
      font-size: 11px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #505566;
    }

    &-image {
      &-top {
        position: absolute;
        right: -35px;
        top: -55px;
      }

      &-bottom {
        position: absolute;
        right: -20px;
        bottom: -58px;
      }
    }
  }

  &-s-row {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    align-items: center;

    &.start {
      align-items: start !important;
    }

    justify-items: center;
    gap: 24px;
    width: 100%;

    @media screen and (max-width: 762px) {
      grid-template-columns: 1fr;
    }
  }

  &-s-r {
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    padding: 30px;
    gap: 24px;
    align-items: center;
    width: 100%;
    justify-items: center;
    align-content: space-between;
    position: relative;
    width: 100%;

    &-icon {
      position: absolute;
      right: -42px;
    }

    &-t {
      font-weight: 600;
      font-size: 16px;
      color: #535763;
      display: grid;
      justify-items: center;
      margin-bottom: 30px;
      gap: 12px;
    }

    @media screen and (max-width: 1200px) {
      padding: 15px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 40px;

      &-icon {
        right: initial;
        bottom: -40px;
        left: 10px;
        transform: rotate(90deg);
      }
    }

    @media screen and (max-width: 552px) {
      padding: 10px;
    }

    &-combo {
      display: grid;
      gap: 16px;
      justify-items: center;
      width: 100%;

      &-parent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 15px;
        width: 100%;
        background: #f5f5f5;
        border-radius: 16px;
      }

      &-t {
        font-weight: 200;
        font-size: 14px;
        color: #0e608b;
      }

      &-num {
        font-weight: 200;
        font-size: 14px;
        text-align: right;
        color: #535763;
        display: flex;
        align-items: center;
      }

      &-cap {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
      }

      &-info {
        font-weight: 200;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #212121;
      }

      &-name {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #535763;
        margin-left: 3px;
      }
    }
  }

  &-r {
    width: 100%;
    display: grid;
    gap: 30px;
    padding-left: 53px;

    &-parent {
      display: flex;
      background: rgb(255, 255, 255);
      border-radius: 16px;
      place-items: center;
      justify-content: space-between;
      padding: 14px;
      width: 100%;
      position: relative;

      &:before {
        content: '';
        background-color: #153458;
        position: absolute;
        width: 8px;
        height: 8px;
        top: 20px;
        left: -24px;
        display: block;
        border-radius: 16px;
        border: 3px solid #ffffff;
      }

      &:after {
        content: '';
        background-color: #ffffff;
        position: absolute;
        width: 5px;
        height: 80px;
        top: 50%;
        left: -20px;
        display: block;
        z-index: -1;
        border-radius: 8px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &-children {
      display: flex;
      align-items: flex-end;
      gap: 5px;
    }

    &-combo {
      display: flex;
      gap: 21px;
    }

    &-child {
      display: flex;
      gap: 3px;
      align-items: center;

      & > img {
        width: 18px;
      }

      &-amount {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #535763;
        margin-top: 3px;
      }

      &-cap {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #212121;
        margin-top: 3px;
      }

      &-label {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #212121;
        margin-top: 3px;
      }
    }

    @media screen and (max-width: 1200px) {
      &-parent {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        &:before {
          top: 36px;
        }

        &:after {
          height: 110px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding-left: 41px;
    }

    @media screen and (max-width: 552px) {
      gap: 10px;

      &-parent {
        &-combo {
          gap: 5px;
          flex-direction: column;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        &:before {
          top: 10px;
        }

        &:after {
          top: 20px;
          height: 155px;
        }
      }

      &-combo {
        gap: 5px;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  &-t-row {
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    gap: 20px;
    justify-items: center;
    padding: 30px;
    width: 100%;

    &.welcome {
      background: #ffffffed;
      border-radius: 16px;
      display: grid;
      gap: 20px;
      justify-items: center;
      padding: 30px;
      max-width: 540px;
      position: fixed;
      left: 0;
      right: 0;
      margin: auto;
      box-shadow: 0 0 190px #153458a3;
    }

    &.terms {
      max-width: 700px;
      overflow-wrap: break-word;
    }
  }

  &-t-h {
    font-weight: 600;
    font-size: 24px;
    color: #0e608b;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  &-t-cap {
    background: #f5f5f5;
    border-radius: 16px;
    padding: 30px;
    position: relative;
    height: 400px;
    // overflow-y: scroll;
    overflow-x: hidden;
    width: -webkit-fill-available;
    overflow-wrap: break-word;

    & > p {
      font-weight: 200;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &-t-end {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &-t-line {
    display: flex;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }

  &-t-combo {
    margin: 20px;

    & > input {
      vertical-align: middle;
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }
  }

  &-t-label {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.6);
  }

  &-t-for-btn {
    display: flex;
    justify-content: flex-end;
  }

  &-t-btn {
    width: 100%;
    border-radius: 8px;
    font-size: 20px;
    background-color: #153458;
    color: #ffffff;
    width: 160px;
    height: 54px;
    margin-top: 10px;
    cursor: pointer;

    &:after,
    &:before,
    &:focus {
      transition: 0.3s;
      background-color: #3d5c81;
    }
  }

  @media screen and (max-width: 1600px) {
    &-f {
      &-circle {
        right: -27px;
      }

      &-row {
        &:before {
          width: 68%;
        }
      }

      &-symbol {
        &-one {
          width: 98px;
          right: 101px;
        }

        &-two {
          right: 203px;
        }

        &-three {
          right: 241px;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    &-f {
      &-symbol {
        &-one {
          width: 93px;
          right: 135px;
        }

        &-two {
          right: 235px;
        }

        &-three {
          right: 275px;
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    &-f {
      &-symbol {
        &-one {
          width: 90px;
          right: 123px;
        }

        &-two {
          right: 222px;
        }

        &-three {
          right: 265px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &-t-row,
    &-t-cap {
      padding: 20px;
    }

    &-f {
      &-circle {
        right: 0;
        top: -50px;
      }

      &-symbol {
        display: none;
      }

      &-link {
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    &-f {
      &-row {
        &:before {
          width: 62%;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    &-f {
      &-row {
        &:before {
          width: 68%;
        }
      }
    }

    &-t-line {
      flex-direction: column;
    }

    &-t-combo {
      margin: 0px;
      margin-bottom: 20px;
    }

    &-t-label {
      font-size: 13px;
    }

    &-t-btn {
      height: 44px;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    &-f {
      &-row {
        &:before {
          width: 62%;
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    &-f {
      &-row {
        &:before {
          width: 52%;
        }
      }

      &-circle {
        border: 6px solid #166bb6;

        &-image {
          &-top {
            right: -25px;
            top: -45px;
          }

          &-bottom {
            right: 0px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 552px) {
    &-f {
      &-btn {
        width: 62px;
        font-size: 11px;
      }

      &-row {
        &:before {
          width: 42%;
        }

        &-p {
          margin-top: 160px;
        }
      }

      &-circle {
        width: 92px;
        height: 92px;
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: #166bb6;

        &-num {
          font-size: 18px;
          color: #ffffff;
        }

        &-cap,
        &-connect {
          color: #ffffff;
        }

        &-image {
          &-bottom {
            bottom: -68px;
          }
        }
      }
    }

    &-t-row,
    &-t-cap {
      padding: 15px;
    }

    &-t-cap {
      & > p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-t-h {
      font-size: 20px;
    }

    &-t-btn {
      width: 100%;
      height: 38px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px) {
    &-f {
      &-row {
        &:before {
          width: 22%;
        }
      }
    }
  }
}

// My Map
.flex-mymap {
  padding: 2rem;
  display: grid !important;
  grid-template-columns: 1fr;
  gap: 12px !important;
  justify-items: flex-start;

  @media screen and (max-width: 768px) {
    padding: 10px !important;
  }
}

.mymap {
  width: 100%;

  h4 {
    font-weight: 600;
    color: #0e608b;
    height: 62px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 24px;

    @media screen and (max-width: 992px) {
      height: 52px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 65px;
      margin-bottom: 0;
      padding-left: 15px;
    }
  }

  &-f-r {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
    // width: calc(100% * (1) - 0px);
    width: 100%;

    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 552px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &-f-box {
    position: relative;
    height: 101px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: column nowrap;
    padding-top: 8px;
    padding-bottom: 3px;
    letter-spacing: 0.4px;

    &-inside {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;

      &-amount {
        font-weight: 600;
        font-size: 22px;
        color: #0e608b;
      }
    }

    &-row {
      flex-direction: row;
    }

    &-h {
      font-weight: 400;
      font-size: 14px;
      color: #0e608b;
    }

    &-cap {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #0e608b;
    }

    &-amount {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;

      @media screen and (max-width: 1200px) {
        font-size: 20px;
      }
    }

    &-extra {
      font-weight: 200;
      font-size: 13px;
      color: #43b309;
      margin-left: 5px;
    }

    @media screen and (max-width: 1200px) {
      &-cap {
        font-size: 16px;
      }

      &-extra {
        font-size: 11px;
      }
    }

    @media screen and (max-width: 768px) {
      height: 91px;

      &:nth-child(3n-1):nth-last-of-type(1) {
        grid-column: span 2;
      }
    }

    @media screen and (max-width: 552px) {
      align-items: flex-start;
      padding-left: 15px;

      &-inside {
        align-items: flex-start;
      }

      &-cap {
        font-size: 14px;
      }

      &-extra {
        margin-top: 6px;
      }
    }
  }

  &-s-r {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    // height: 365px;
    width: 100%;
    align-items: stretch;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &-s {
    background: #ffffff;
    border-radius: 16px;
    padding: 25px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .skeleton-chart-fix {
      min-height: 25rem;

      @media screen and (max-width: 768px) {
        min-height: 30rem;
      }

      @media screen and (max-width: 480px) {
        min-height: 20rem;
        padding: 15px 5px;
      }
    }

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.4rem;
    }

    &-row {
      display: flex;
    }

    &-h {
      font-weight: 600;
      font-size: 22px;
      color: #0e608b;
    }

    &-p {
      font-weight: 200;
      font-size: 14px;
      color: #8d9092;
      display: flex;
      align-items: center;
      justify-content: center;

      &-g {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;

        span {
          margin-left: 5px;
        }
      }
    }

    &-blue {
      width: 10.35px;
      height: 10.35px;
      background: #34b3f1;
      border-radius: 4px;
    }

    &-black {
      width: 10.35px;
      height: 10.35px;
      background: #010101;
      border-radius: 4px;
    }

    &-selector {
      font-weight: 600;
      font-size: 14px;
      color: #153458;
      border-radius: 16px;
      border: 1px solid #153458;
      padding: 4px 8px 4px 10px;

      background: url("data:image/svg+xml,<svg t='1675155272854' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'><path d='M760.454652 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227064 0L511.706311 596.712357 308.185035 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227065 0-12.489452 12.489452-12.489452 32.737612 0 45.227064L489.093291 664.553465c12.489452 12.489452 32.737612 12.489452 45.227064 0L760.454652 438.418144c12.488429-12.489452 12.488429-32.738635 0-45.227064z' p-id='1287'></path></svg>")
        no-repeat;
      background-color: #e8f1f4;
      background-position: calc(110% - 14px) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-right: 30px !important;
    }

    @media screen and (max-width: 768px) {
      padding: 15px;

      &-box {
        flex-direction: column;
        align-items: flex-start;
      }

      &-h {
        font-size: 18px;
      }

      &-p {
        &-g {
          margin-left: 8px;

          span {
            font-size: 11px;
          }
        }
      }

      &-selector {
        margin-top: 10px;
      }

      &-blue,
      &-black {
        width: 8px;
        height: 8px;
        border-radius: 3px;
        margin-bottom: 1px;
      }
    }
  }

  &-t-r {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &-t-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    &-left {
      grid-template-columns: 1.3fr 1fr;
    }

    &-middle {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-combo {
      height: 150px;
      background: rgb(255, 255, 255);
      border: 1.5px solid rgb(238, 238, 238);
      border-radius: 16px;
      display: grid;
      justify-items: center;
      align-items: center;
      align-content: center;
      justify-content: center;
      gap: 3px;

      &-image {
        margin-bottom: 10px;
      }

      &-amount {
        font-weight: 600;
        font-size: 18px;
        color: #0e608b;
      }

      &-info {
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        color: #4d4f51;
      }
    }

    &-cap {
      font-weight: 200;
      font-size: 13px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: #4d4f51;
    }

    &-amount {
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #4d4f51;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;

      &-left {
        grid-template-columns: 1fr;
      }

      &-combo {
        &-info {
          font-size: 11px;
        }
      }

      &-cap {
        font-size: 11px;
      }
    }

    @media screen and (max-width: 552px) {
    }
  }

  &-t-box-f {
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    align-items: center;
    justify-items: flex-start;
    padding: 10px 16px;
    grid-template-columns: 7fr 1fr;
    gap: 6px;

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    }

    &-p {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-h {
      font-weight: 600;
      font-size: 18px;
      color: #0e608b;
      letter-spacing: 0.5px;
    }

    &-mmber {
      font-weight: 600;
      font-size: 18px;
      text-align: right;
      letter-spacing: 0.5px;
      color: #0e608b;
    }

    &-cap {
      font-weight: 200;
      font-size: 13px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: #4d4f51;
    }

    &-amount {
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #4d4f51;
    }

    &-middle {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      &-cap {
        font-weight: 200;
        font-size: 13px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: #4d4f51;
      }

      &-amount {
        font-weight: 400;
        font-size: 13px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: #4d4f51;
      }
    }

    @media screen and (max-width: 1200px) {
      &-cap {
        font-size: 11px;
      }

      &-middle {
        &-cap,
        &-amount {
          font-size: 11px;
        }
      }

      &-f {
        padding-right: 10px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 15px;
    }

    @media screen and (max-width: 552px) {
      &-h,
      &-mmber {
        font-size: 16px;
      }
    }
  }

  &-t-grid {
    display: grid;
    gap: 5.5px;

    &-box {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
      gap: 16px;
      height: 72px;
      background: #ffffff;
      border-radius: 16px;
    }

    &-border {
      position: relative;

      &-num {
        width: 42px;
        height: 42px;
        border: 5px solid #f5f5f5;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }

      &-image {
        position: absolute;
        top: -1px;
        right: -5px;
        width: 44px;
        height: 48px;
      }
    }

    &-cap {
      font-weight: 600;
      font-size: 18px;
      color: #0e608b;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
    }

    &-info {
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.5px;
      color: #4d4f51;
    }

    @media screen and (max-width: 552px) {
      &-cap {
        font-size: 16px;
      }

      &-info {
        font-size: 11px;
      }
    }
  }

  &-f-row {
    display: grid;
    align-items: flex-start;
    background: rgb(255, 255, 255);
    border: 0.0938rem solid rgb(238, 238, 238);
    border-radius: 16px;
    padding: 20px 25px;
    gap: 6px;
    width: 100%;
    margin-bottom: 1rem;

    &-p {
      display: flex;
      width: 100%;
      gap: 82px;
      margin-bottom: 12px;
    }

    &-b {
      width: 100%;
      background-color: #ffffff;
      gap: 24px;
      display: flex;
    }

    &-btn {
      font-weight: 600;
      font-size: 22px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #0e608b;
      background: #ffffff;
      border: unset;
    }

    &-right {
      gap: 10px;
      display: flex;
      justify-items: center;
      align-items: center;

      &-search {
        display: flex;
        width: 250px;
        height: 38px;
        background: rgba(233, 241, 245, 0.6);
        border-radius: 32px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;

        &-input {
          border: unset;
          width: 100%;
          height: 100%;
          padding-left: 10px;
          margin-left: -10px;
          background-color: transparent;
          font-size: 14px;
        }
      }

      &-filter {
        display: flex;
        width: 92px;
        background: #ffffff;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;
        color: #0e608b;

        &-input {
          border: unset;
          width: 58px;
        }
      }

      @media screen and (max-width: 768px) {
        gap: 0;

        &-search {
          width: 100%;
        }

        &-filter {
          padding-left: 0;
        }
      }
    }

    &-bottom {
      height: 50px;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      &-cap {
        // font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #535763;
      }

      &-right {
        display: flex;
        gap: 77px;

        @media screen and (max-width: 768px) {
          width: 100%;
          justify-content: space-between;
        }

        &-td {
          display: flex;
          align-items: center;
        }

        &-detail {
          font-weight: 200;
          font-size: 13px;
          color: #373d50;
          margin-right: 5px;
        }

        &-financial {
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.02em;
          color: #535763;
        }
      }
    }

    @media screen and (max-width: 992px) {
      &-bottom {
        height: initial;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;

        &-cap {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 15px;

      &-p {
        gap: 12px;
        flex-direction: column;
      }

      &-bottom {
        &-right {
          &-td {
            align-items: flex-start;
            flex-direction: column;
          }
        }
      }
    }

    @media screen and (max-width: 552px) {
      &-btn {
        font-size: 18px;
      }
    }
  }
}

// Verify Your Identity Successful
.scc-container {
  background-color: #f5f5f5;
  justify-content: center;
}

.scc-flex {
  padding: 2rem;
  display: grid !important;
  width: 493px;
  justify-items: center;
}

.scc-parent {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(238, 238, 238);
  border-radius: 8px;
  padding: 64px 90px 40px;
  margin-top: 15px;

  @media (max-width: 552px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.scc-box {
  display: grid;
  align-items: center;
  justify-items: center;

  &-check {
    background: rgb(245, 245, 245);
    border-radius: 36px;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 32px;
  }

  &-h {
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #153458;
    margin-bottom: 12px;
  }

  &-cap {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #153458;
    margin-bottom: 32px;
  }

  &-btn {
    cursor: pointer;
    width: 100%;
    background: #153458;
    color: white;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    border: none;
  }
}

// Fill Treasure Chest Overview
.body {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }
}

.fill-treasure-container {
  padding: 0;
}

.fill-treasure-flex {
  display: grid;
  grid-template-columns: 1fr;
}

.fill-treasure {
  display: flex;
  flex-direction: column;

  &-header {
    background-image: '/images/natureLogoBig.svg';

    &-text {
      width: 100%;

      h4 {
        font-weight: 600;
        color: #0e608b;
        height: 62px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 24px;
        font-size: 16px;

        @media screen and (max-width: 992px) {
          height: 52px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 65px;
          padding-left: 15px;
        }
      }
    }
  }

  &-nav {
    width: 100%;
    padding: 0rem 2rem;
    background-color: #ffffff;

    &-ac-btn {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #535763;
      padding: 14px 34px 15px 34px;
    }

    &-btn {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #ffffff;
      padding: 14px 34px 15px 34px;
    }
  }

  &-body {
    padding: 2rem 0;
  }

  &-post {
    display: grid;
    grid-template-columns: 1fr 5fr;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    gap: 27px;

    @media screen and (max-width: 768px) {
      padding: 20px;
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 552px) {
      padding: 15px;

      &-title {
        font-size: 20px;
      }
    }
  }

  &-h {
    font-weight: 600;
    font-size: 24px;
    color: #0e608b;
    margin-bottom: 15px;
  }

  &-p {
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #535763;
    line-height: 28px;

    @media screen and (max-width: 552px) {
      font-size: 13px;
      line-height: 24px;
    }
  }
}

// Fill Treasure Chest Update
.chestUpdate-parent {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  align-items: flex-start;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }
}

.container-chestUpdate {
  padding: 0;
}

.flex-chestUpdate {
  display: grid;
  grid-template-columns: 1fr;
}

.chestUpdate {
  display: flex;
  flex-direction: column;

  &-header {
    background-image: '/images/natureLogoBig.svg';

    &-title {
      width: 100%;

      h4 {
        font-weight: 600;
        color: #0e608b;
        height: 62px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 24px;
        font-size: 16px;

        @media screen and (max-width: 992px) {
          height: 52px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 65px;
          padding-left: 15px;
        }
      }
    }
  }

  &-p {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
  }

  &-row {
    width: 100%;
    padding: 0rem 2rem;
    background-color: #ffffff;
  }

  &-btn {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #535763;
    background: #ffffff;
    border: unset;
    border-bottom: 2px solid #ffffff;
    padding: 14px 34px 15px 34px;

    &-act {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #535763;
      padding: 14px 34px 15px 34px;
    }
  }

  &-body {
    padding: 20px 0 0 130px;
    position: relative;
    overflow: hidden;
    // &:before {
    //     content: "";
    //     background-color: #153458;
    //     position: absolute;
    //     width: 3px;
    //     height: 100%;
    //     top: 130px;
    //     left: 89px;
    //     display: block;
    //     z-index: -1;
    //     border-radius: 8px;
    // }
  }

  &-post {
    display: grid;
    grid-template-columns: 1fr;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    gap: 24px;
    height: boxHeight;
    position: relative;

    img {
      max-width: 100% !important;
      height: fit-content !important;
      object-fit: scale-down;
    }

    &:before {
      content: '';
      background-color: #ffffff;
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      left: -50px;
      display: block;
      border-radius: 16px;
      border: 4px solid #153458;
    }

    &:after {
      content: '';
      background-color: #153458;
      position: absolute;
      width: 3px;
      height: 1000%;
      top: 0;
      left: -41px;
      display: block;
      z-index: -1;
      border-radius: 8px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    @media screen and (max-width: 552px) {
      padding: 15px;
      gap: 12px;

      &-title {
        font-size: 20px;
      }

      gap: 12px;
    }
  }

  &-h {
    font-weight: 600;
    font-size: 24px;
    color: #0e608b;
  }

  &-date {
    position: absolute;
    left: -130px;
    top: 50%;
    font-size: 13px;
    color: #959595;
    font-weight: 600;
    margin-top: 3px;
  }

  &-p {
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #535763;
    line-height: 28px;

    @media screen and (max-width: 552px) {
      font-size: 13px;
      line-height: 24px;
    }
  }

  &-show-more {
    border: unset;
    background-color: unset;
    font-weight: 500;
    font-size: 13px;
    text-decoration-line: underline;
    color: #000000;
  }

  @media screen and (max-width: 768px) {
    &-body {
      padding-left: 50px;
    }

    &-post {
      &:before {
        left: -30px;
      }

      &:after {
        left: -21px;
      }
    }

    &-date {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      margin-top: -20px;
      left: -55px;
    }
  }

  @media screen and (max-width: 552px) {
    &-post {
      &:before {
        border: 2px solid #153458;
      }

      &:after {
        width: 2px;
        left: -23px;
      }
    }

    &-date {
      font-size: 12px;
      left: -50px;
      font-weight: 200;
    }

    &-h {
      font-size: 20px;
      margin-top: 5px;
    }
  }
}

// Financial Account
.flex-suc {
  background: #f5f5f5;
  padding: 2rem;
  display: grid;
}

.suc {
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 392px;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;

  &-parent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }

  &-t {
    font-weight: 600;
    font-size: 32px;
    align-items: center;
    text-align: center;
    color: #535763;
    display: flex;
  }

  &-info {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #535763;
  }

  &-btn {
    width: 100%;
    background: #eeeeee;
    border: 3px solid #000000;
    border-radius: 8px;
    height: 48px;
    font-weight: 600;
    font-size: 18px;
  }
}

// Financial Account Profile
.prof-flex {
  // background: #F5F5F5;
  padding: 2rem;
  display: grid !important;
  grid-template-columns: 1fr;
  gap: 23px;
}

.prof-h {
  font-weight: 600;
  font-size: 36px;
  color: #0e608b;
}

.prof-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 23px;
}

.prof-l {
  background: rgba(255, 255, 255, 0.8);
  border: 6px solid #373d50;
  box-shadow: 0px 20px 16px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(6.5px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 30px;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.02px;
  }

  &-name {
    font-weight: 600;
    font-size: 28px;
    color: #0e608b;
  }

  &-add {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    // text-transform: uppercase;
    color: rgba(55, 62, 80, 0.62);
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-bttm {
    display: flex;
  }

  &-type {
    font-weight: 600;
    font-size: 14px;
    color: #818590;

    &-name {
      font-weight: 600;
      font-size: 14px;
      color: #373d50;
    }
  }
}

.prof-r {
  background: rgb(245, 245, 245);
  border: 1.5px dashed #153458;
  border-radius: 8px;
  padding: 11px;

  &-border {
    display: flex;
    background: rgba(255, 255, 255, 0.81);
    border: 1px dashed rgba(83, 87, 99, 0.25);
    border-radius: 8px;
    align-items: center;
    gap: 7px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &-btn {
    border: unset;
    background-color: unset;
    width: 60px;
  }

  &-txt {
    font-weight: 600;
    font-size: 24px;
    color: #535763;
  }
}

.fmodal {
  justify-items: center;
  display: grid;
  width: 100%;

  &-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      rgba(0, 0, 0, 0.04) 100%
    );
    width: 609px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    &-body {
      padding: 16px 24px;
      width: 92%;
      background: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-steps {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
      align-items: center;

      &-act {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;

        &-num {
          width: 34px;
          height: 34px;
          left: 677px;
          top: 165px;
          background: #ffffff;
          border: 0.1875rem solid #000000;
          border-radius: 20px;
          text-align: center;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
        }

        &-text {
          font-weight: 600;
          font-size: 12px;
          text-align: right;
          color: #000000;
        }
      }

      &-dact {
        width: 34px;
        height: 34px;
        left: 677px;
        top: 165px;
        background: #ffffff;
        border: 0.1875rem solid #e2e2e2;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;

        &-num {
          font-weight: 600;
          font-size: 12px;
          text-align: right;
          color: rgba(0, 0, 0, 0.1);
        }

        &-text {
          font-weight: 600;
          font-size: 12px;
          text-align: right;
          color: rgba(0, 0, 0, 0.1);
        }
      }

      &-line {
        width: 100%;
        height: 6px;
        background: #f5f5f5;
        border-radius: 40px;

        &-continue {
          width: 25%;
          height: 6px;
          background: #000000;
          border-radius: 40px;
          position: relative;
        }

        &-zero {
          width: 0%;
          height: 6px;
          background: #000000;
          border-radius: 40px;
          position: relative;
        }

        &-done {
          width: 100%;
          height: 6px;
          background: #000000;
          border-radius: 40px;
          position: relative;
        }
      }
    }

    &-br {
      height: 1px;
      background: #f5f5f5;
      border-radius: 40px;
    }

    &-combo {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 43px;

      &-label {
        font-weight: 600;
        font-size: 16px;
        color: #676767;
      }

      &-options {
        background: #f5f5f5;
        border: 0.5px solid #eeeeee;
        border-radius: 8px;
        width: 100%;
        height: 48px;
        padding: 1rem;
      }
    }
  }

  &-header {
    display: flex;
    width: 94%;
    padding: 3%;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.06));
    height: 50px;
    align-items: center;

    &-close {
      border: unset;
      background: unset;
    }

    &-p {
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }
  }

  &-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    width: 94%;
    align-items: center;

    &-btn {
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: #ffffff;
      padding: 12px;
      gap: 10px;
      width: 54px;
      height: 40px;
      background: #358eea;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
    }
  }
}

.smodal {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  &-input {
    display: grid;
    grid-template-columns: 1fr;
    gap: 11px;
  }

  &-label {
    font-weight: 600;
    font-size: 16px;
    color: #676767;
  }

  &-inputs {
    padding: 15px;
    box-sizing: border-box;
    height: 48px;
    background: #f5f5f5;
    border: 0.5px solid #eeeeee;
    border-radius: 8px;
  }

  &-btn {
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
    padding: 12px;
    gap: 10px;
    width: 69px;
    height: 40px;
    background: #358eea;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
  }
}

// Financial Account Profile Last
.prof {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-flex {
    // background: #F5F5F5;
    padding: 2rem;
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  &-h {
    width: 100%;

    & > h4 {
      font-weight: 600;
      color: #0e608b;
      height: 62px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-bottom: 24px;
      font-size: 16px;

      @media screen and (max-width: 992px) {
        height: 52px;
      }

      @media screen and (max-width: 768px) {
        margin-left: 65px;
        padding-left: 15px;
      }
    }
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // gap: 42px;
  }

  &-active {
    border: 6px solid #373d50 !important;
    backdrop-filter: blur(6.5px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    min-height: 260px;
    cursor: pointer;

    &:after {
      content: url(.//assets/icons/check.svg);
      background-color: #153458;
      position: absolute;
      border: 4px solid #ffffff;
      border-radius: 50px;
      bottom: -27px;
      left: 22px;
      padding: 5px 6px 1px;
    }

    &-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    &-name {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-add {
      font-weight: 200;
      font-size: 14px;
      text-align: center;
      // text-transform: uppercase;
      color: rgba(55, 62, 80, 0.62);
    }

    &-bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &-right {
        margin-top: 20px;
      }
    }

    &-bttm {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    &-type {
      font-weight: 600;
      font-size: 16px;
      color: #818590;

      &-name {
        font-weight: 600;
        font-size: 14px;
        color: #373d50;
        margin-left: 5px;
      }
    }

    &-img {
      width: 92px;
    }
  }

  &-r {
    background: #e8f1f5;
    border: 3px dashed #153458;
    border-radius: 16px;
    padding: 30px;
    min-height: 260px;
    cursor: pointer;
    height: 240px;

    &-border-new {
      width: 100%;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      height: 100%;
      justify-content: center;
    }

    &-btn-new {
      border: unset;
      background-color: unset;

      img {
        width: 100px;
      }
    }

    &-txt {
      font-weight: 200;
      font-size: 16px;
      color: #535763;
    }
  }

  &-description {
    background: #ffffff;
    border: 6px solid #ffffff;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 16px;
    margin-bottom: 1rem;
  }

  &-right {
    background: #ffffff;
    border: 6px solid #ffffff;
    backdrop-filter: blur(6.5px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    min-height: 260px;
    height: 240px;
    cursor: pointer;

    &-body {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &-type {
      font-weight: 600;
      font-size: 14px;
      color: #818590;
    }

    &-ans {
      font-weight: 600;
      font-size: 14px;
      color: #373d50;
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 1200px) {
    &-row {
      grid-template-columns: 1fr;
      gap: 32px;
    }

    &-active {
      padding: 20px;
      border: 4px solid #373d50;

      img {
        width: 62px;
      }

      &-img {
        width: 62px;
      }

      &-name {
        font-size: 20px;
      }

      &-add {
        font-size: 12px;
      }
    }

    &-r {
      padding: 20px;
    }

    &-right {
      padding: 20px;
    }

    &-prof {
      &-active {
        &-img {
          width: 62px;
        }

        &-type {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    &-active,
    &-r,
    &-right {
      min-height: 230px;
    }
  }

  @media screen and (max-width: 552px) {
    &-row {
      gap: 22px;
    }

    &-active {
      padding: 15px;
      min-height: 200px;
      border: 2px solid #373d50 !important;

      img {
        width: 52px;
      }

      &-img {
        width: 52px;
      }

      &-name {
        font-size: 18px;
      }

      &-add {
        font-size: 11px;
      }

      &-type {
        font-size: 13px;

        &-name {
          font-size: 12px;
        }
      }

      &:after {
        left: 20px;
        zoom: 70%;
      }
    }

    &-r {
      padding: 5px;
      min-height: 200px;
      border: 2px dashed #153458;

      &-btn {
        &-new {
          img {
            width: 60px;
          }
        }
      }

      &-txt {
        font-size: 13px;
      }
    }

    &-right {
      padding: 15px;
      min-height: 200px;

      &-type {
        font-size: 13px;

        &-name {
          font-size: 12px;
        }
      }

      &-ans {
        font-size: 13px;
      }
    }

    &-prof {
      &-active {
        &-img {
          width: 62px;
        }

        &-type {
          font-size: 13px;

          &-name {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.financial-account-edit-btn {
  cursor: pointer;
  color: white;

  background: #153458;
  padding: 1rem 2rem;
  border-radius: 8px;

  position: relative;
  z-index: 10;

  &:hover {
    background: #153458f0;
  }
}

.financial-account-delete-btn {
  cursor: pointer;
  color: white;

  background: #ff0000;
  padding: 1rem 2rem;
  border-radius: 8px;

  position: relative;
  z-index: 10;

  &:hover {
    background: #ff0000f0;
  }
}

// First My Treasure
.fir-tr {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  width: 100%;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  &-container {
    // background: #F5F5F5;
    @media screen and (max-width: 768px) {
      padding: 10px !important;
    }
  }

  &-flex {
    padding: 2rem;
    width: 100vw;
  }

  &-bod {
    width: 100%;
  }

  &-body {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &-h {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
  }

  &-combo-subject {
    width: 100%;

    h4 {
      font-weight: 600;
      color: #0e608b;
      height: 62px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-bottom: 24px;
      font-size: 16px;

      @media screen and (max-width: 992px) {
        height: 52px;
      }

      @media screen and (max-width: 768px) {
        margin-left: 65px;
        padding-left: 15px;
      }
    }
  }

  &-combo-header {
    gap: 10px;
    display: flex;
    justify-items: center;
    justify-content: flex-end;
    width: 100%;

    &-input {
      display: flex;
      width: 290px;
      height: 46px;
      background: #ffffff;
      border-radius: 32px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      @media screen and (max-width: 552px) {
        width: 60%;
      }

      &-search {
        border: unset;
        height: 100%;
        width: 100%;
        padding-left: 10px;
        margin-left: -10px;
        background-color: transparent;
        font-size: 14px;
        outline: none;
      }
    }

    &-filter {
      display: flex;
      width: 102px;
      height: 46px;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0px 0 16px;
      margin-right: 10px;

      &-options {
        width: 100px;
        position: absolute;
        // background-color: transparent;
        border: none;
        text-align: right;

        background: initial;
        background-position: calc(100% - 14px) center;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding-right: 10px;
        outline: 0;
      }

      @media screen and (max-width: 552px) {
        width: 72px;
        padding-left: 0;
        margin-right: 20px;

        &-options {
          padding-right: 20px;
        }
      }
    }
  }

  &-posts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
    justify-items: center;
    margin-bottom: 1rem;
  }

  &-post-l {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    flex-direction: column;
    border-radius: 16px;
    background: white;
    padding: 20px;
    width: 100%;
    gap: 5px;

    &-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
        line-height: 28px;
      }

      &-name {
        font-weight: 200;
        font-size: 14px;
        color: #464646;
        display: inline-block;
        margin-top: 10px;
      }

      @media screen and (max-width: 552px) {
        &-title {
          font-size: 20px;
        }

        img {
          width: 16px;
        }

        &-name {
          font-size: 12px;
        }
      }
    }

    &-box {
      background: rgba(238, 238, 238, 0.5);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 15px;

      @media screen and (max-width: 552px) {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      color: #0e608b;

      @media screen and (max-width: 552px) {
        font-size: 14px;
      }
    }

    &-value {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;

      @media screen and (max-width: 552px) {
        font-size: 14px;
      }
    }

    &-margin {
      margin-bottom: 20px;
      min-height: 196px;

      @media screen and (max-width: 552px) {
        margin-bottom: 10px;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      align-items: center;

      &-value {
        font-weight: 200;
        font-size: 16px;
        color: #0e608b;
      }

      @media screen and (max-width: 552px) {
        padding: 10px 0;

        &-value {
          font-size: 13px;
        }
      }
    }

    &-btn-row {
      display: grid;
      grid-template-columns: 1fr;
      gap: 9px;
      height: 46px;

      &-bottom {
        background: #d7e5ec;
        border-radius: 16px;
        width: 86%;
        height: 32px;

        &-percent {
          position: relative;
          background: #35b3f1;
          border-radius: 16px;
          height: 32px;
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-weight: 600;
          font-size: 14px;
          text-align: right;
          color: #ffffff;
          padding-right: 15px;
        }

        &-content {
          background: rgba(234, 242, 246, 0.4);
          border-radius: 8px;
          margin-bottom: 20px;
          padding: 15px;

          &-value {
            font-weight: 600;
            font-size: 18px;
            color: #0e608b;
          }

          &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          }

          &-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &-s {
            &-p {
              display: flex;

              &-g {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 40px;

                span {
                  font-size: 14px;
                  margin-left: 5px;
                }
              }
            }

            &-blue {
              width: 16px;
              height: 16px;
              background: #34b3f1;
              border-radius: 4px;
            }

            &-black {
              width: 16px;
              height: 16px;
              background: #010101;
              border-radius: 4px;
            }
          }
        }
      }

      @media screen and (max-width: 552px) {
        &-bottom {
          height: 22px;
          font-size: 14px;

          &-percent {
            height: 22px;
            font-size: 12px;
          }

          &-content {
            margin-bottom: 10px;
            padding: 10px;

            &-top {
              flex-direction: column;
            }

            &-value {
              font-size: 14px;
            }

            &-s {
              &-p {
                margin-top: 5px;

                &-g {
                  margin-left: 10px;

                  span {
                    font-size: 12px;
                  }

                  &:first-child {
                    margin-left: 0;
                  }
                }
              }

              &-blue,
              &-black {
                width: 8px;
                height: 8px;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }

    &-btn-btn {
      background: #eeeeee;
      border-radius: 8px;
      font-size: 20px;
      background-color: #153458;
      color: #ffffff;
      height: 54px;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        transition: 0.3s;
        background-color: #3d5c81;
      }

      @media screen and (max-width: 552px) {
        height: 38px;
        font-size: 16px;
      }
    }

    @media screen and (max-width: 552px) {
      padding: 15px;
    }
  }

  &-post-l-inside {
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: space-between;
    height: 100%;

    &-image {
      background-color: #f6f6f6;
      border-radius: 8px;
      width: 100%;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }

      @media screen and (max-width: 768px) {
        height: 210px;
      }

      @media screen and (max-width: 552px) {
        height: 170px;
      }
    }
  }

  &-post-r {
    height: 745px;
    width: 417px;
    background: #f5f5f5;
    border: 1.5px dashed #535763;
    border-radius: 8px;
    padding: 23px 21px 25px 19px;

    &-border {
      width: 100%;
      background: rgba(255, 255, 255, 0.81);
      border: 1px dashed rgba(83, 87, 99, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      height: 100%;
      justify-content: space-around;
    }

    &-combo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-btn {
      border: unset;
      background-color: unset;
    }

    &-text {
      font-weight: 600;
      font-size: 18px;
      color: #535763;
    }

    &-bottom {
      display: flex;
      gap: 8px;
    }
  }
}

// Second My Treasure
.sec-tr {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  width: 100%;

  &-bod {
    width: 100%;
  }

  &-body {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &-h {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
  }

  &-combo-header {
    gap: 10px;
    display: flex;
    justify-items: center;

    &-input {
      display: flex;
      width: 290px;
      height: 46px;
      background: #ffffff;
      border: 2px solid #282b32;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      &-search {
        border: unset;
        height: 90%;
      }
    }

    &-filter {
      display: flex;
      width: 102px;
      height: 46px;
      background: #ffffff;
      border: 2px solid #282b32;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      &-input {
        border: unset;
        width: 58px;
      }
    }
  }

  &-post-border {
    width: 486px;
    height: 775px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
    background: #212121;
    padding: 8px 0px;
    gap: 8px;
    margin: auto;
  }

  &-post {
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
    border-radius: 8px;
    background: white;
    padding: 19px 20px 26px 20px;

    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-name {
        font-weight: 500;
        font-size: 16px;
        color: #464646;
      }
    }

    &-box {
      background: rgba(238, 238, 238, 0.5);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 0 22px 0 16px;
      height: 62px;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;
    }

    &-value {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;
    }

    &-row {
      display: flex;
      flex-direction: column;
      gap: 17px;
      margin-bottom: 10px;
    }

    &-rows {
      display: flex;
      justify-content: space-between;
      padding: 0 22px 0 16px;

      &-title {
        font-weight: 600;
        font-size: 18px;
        color: #0e608b;
      }

      &-box {
        width: 426px;
        height: 111px;
        background: #ffffff;
        border: 1.5px solid #eeeeee;
        border-radius: 8px;

        &-row {
          font-weight: 600;
          font-size: 20px;
          color: rgb(55, 62, 80);
          display: flex;
          justify-content: space-between;
          padding: 16px 17px 20px;
          flex-direction: column;
          gap: 17px;

          &-title {
            font-weight: 600;
            font-size: 20px;
            color: rgb(55, 62, 80);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        &-color {
          display: flex;
          gap: 34px;

          &-bbox {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #3480f1;
            display: flex;
            gap: 4px;
            align-items: center;
          }

          &-btitle {
            width: 16px;
            height: 16px;
            background: #3480f1;
            border-radius: 4px;
          }

          &-blbox {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #373d50;
            display: flex;
            gap: 4px;
            align-items: center;
          }

          &-bltitle {
            width: 16px;
            height: 16px;
            background: #373d50;
            border-radius: 4px;
          }
        }
      }
    }

    &-percent-row {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &-percent {
      font-weight: 500;
      font-size: 15px;
      color: rgba(83, 87, 99, 0.6);

      &-back {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #373d50;
        width: 347px;
        height: 31px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 13px;
        background: #f6f7f7;
        border: 1px solid #eeeeee;
        border-radius: 13px;

        &-num {
          font-weight: 600;
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #ffffff;
          background: #3480f1;
          border-radius: 13px;
          width: 113px;
          height: 31px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 15px;
        }
      }
    }

    &-btn {
      width: 467px;
      height: 54px;
      background: #eeeeee;
      border-radius: 8px;
      border: unset;
      font-weight: 600;
      font-size: 22px;
      color: #0e608b;
    }
  }
}

.container-sec-tr {
  background: #f5f5f5;
}

.flex-sec-tr {
  padding: 2rem;
}

.modal {
  display: flex;
  justify-content: center;

  &-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(to bottom, #ffffff 0%, #d3d3d3 100%);
    width: 609px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    &-header {
      display: flex;
      width: 94%;
      padding: 3%;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.06));

      &-title {
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }

      &-close {
        border: unset;
        background: unset;
      }
    }
  }

  &-inside {
    background: #ffffff;
    width: 94%;
    padding: 3%;
    display: flex;
    gap: 8px;
    flex-direction: column;

    &-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-value {
      font-weight: 600;
      font-size: 16px;
      color: #0e608b;
    }

    &-middle {
      width: 100%;
      height: 192px;
      background: #eeeeee;
      border-radius: 9px;

      &-title {
        font-weight: 600;
        font-size: 16px;
        color: #0e608b;
        padding: 11px 0 0 11px;
      }

      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 7px 11px;
        height: 32px;
        padding-left: 11px;
        padding-right: 11px;
        background: #f5f5f5;
        border-radius: 9px;

        &-title {
          font-weight: 500;
          font-size: 16px;
          text-align: right;
          color: #0e608b;
        }
      }
    }

    &-last {
      display: flex;
      justify-content: space-between;
      height: 35px;
      background: #eeeeee;
      border-radius: 9px;
      align-items: center;
      padding-left: 11px;
      padding-right: 11px;

      &-date {
        font-weight: 600;
        font-size: 15px;
        color: #0e608b;

        &-value {
          font-weight: 500;
          font-size: 16px;
          text-align: right;
          color: #0e608b;
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      width: 94%;
      align-items: center;

      &-title {
        font-weight: 600;
        font-size: 13px;
        text-align: center;
        color: #000000;
        opacity: 0.6;
      }

      &-percent {
        font-weight: 600;
        font-size: 13px;
        text-align: center;
        color: #153458;
        display: flex;
        align-items: center;
        width: 115px;
        height: 40px;
        background: #ffffff;
        border-radius: 12px;
        justify-content: center;
      }
    }
  }
}

// Third My Treasure
.thi-tr {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  width: 100%;

  &-bod {
    width: 100%;
  }

  &-body {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &-h {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
  }

  &-combo-header {
    gap: 10px;
    display: flex;
    justify-items: center;

    &-input {
      display: flex;
      width: 290px;
      height: 46px;
      background: #ffffff;
      border: 2px solid #282b32;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      &-search {
        border: unset;
        height: 90%;
      }
    }

    &-filter {
      display: flex;
      width: 102px;
      height: 46px;
      background: #ffffff;
      border: 2px solid #282b32;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      &-input {
        border: unset;
        width: 58px;
      }
    }
  }

  &-post-border {
    width: 486px;
    height: 775px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
    background: #212121;
    padding: 8px 0px;
    gap: 8px;
    margin: auto;
  }

  &-post {
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
    border-radius: 8px;
    background: white;
    padding: 19px 20px 26px 20px;

    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-name {
        font-weight: 500;
        font-size: 16px;
        color: #464646;
      }
    }

    &-box {
      background: rgba(238, 238, 238, 0.5);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 0 22px 0 16px;
      height: 62px;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;
    }

    &-value {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;
    }

    &-row {
      display: flex;
      flex-direction: column;
      gap: 17px;
      margin-bottom: 10px;
    }

    &-rows {
      display: flex;
      justify-content: space-between;
      padding: 0 22px 0 16px;

      &-title {
        font-weight: 600;
        font-size: 18px;
        color: #0e608b;
      }

      &-box {
        width: 426px;
        height: 111px;
        background: #ffffff;
        border: 1.5px solid #eeeeee;
        border-radius: 8px;

        &-row {
          font-weight: 600;
          font-size: 20px;
          color: rgb(55, 62, 80);
          display: flex;
          justify-content: space-between;
          padding: 16px 17px 20px;
          flex-direction: column;
          gap: 17px;

          &-title {
            font-weight: 600;
            font-size: 20px;
            color: rgb(55, 62, 80);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        &-color {
          display: flex;
          gap: 34px;

          &-bbox {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #3480f1;
            display: flex;
            gap: 4px;
            align-items: center;
          }

          &-btitle {
            width: 16px;
            height: 16px;
            background: #3480f1;
            border-radius: 4px;
          }

          &-blbox {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #373d50;
            display: flex;
            gap: 4px;
            align-items: center;
          }

          &-bltitle {
            width: 16px;
            height: 16px;
            background: #373d50;
            border-radius: 4px;
          }
        }
      }
    }

    &-percent-row {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &-percent {
      font-weight: 500;
      font-size: 15px;
      color: rgba(83, 87, 99, 0.6);

      &-back {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #373d50;
        width: 347px;
        height: 31px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 13px;
        background: #f6f7f7;
        border: 1px solid #eeeeee;
        border-radius: 13px;

        &-num {
          font-weight: 600;
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #ffffff;
          background: #3480f1;
          border-radius: 13px;
          width: 113px;
          height: 31px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 15px;
        }
      }
    }

    &-btn {
      width: 467px;
      height: 54px;
      background: #eeeeee;
      border-radius: 8px;
      border: unset;
      font-weight: 600;
      font-size: 22px;
      color: #0e608b;
    }
  }
}

.container-thi-tr {
  background: #f5f5f5;
}

.flex-thi-tr {
  padding: 2rem;
}

// Fourth My Treasure
.fou-tr {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  width: 100%;

  &-container {
    background: #f5f5f5;
  }

  &-flex {
    padding: 2rem;
    width: 100vw;
  }

  &-bod {
    width: 100%;
  }

  &-body {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &-h {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
  }

  &-combo-header {
    gap: 10px;
    display: flex;
    justify-items: center;

    &-input {
      display: flex;
      width: 290px;
      height: 46px;
      background: #ffffff;
      border: 2px solid #282b32;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      &-search {
        border: unset;
        height: 90%;
      }
    }

    &-filter {
      display: flex;
      width: 102px;
      height: 46px;
      background: #ffffff;
      border: 2px solid #282b32;
      border-radius: 6px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 0 0 16px;

      &-input {
        border: unset;
        width: 58px;
      }
    }
  }

  &-posts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
    justify-items: center;
  }

  &-post-l {
    width: 451px;
    height: 780px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
    background: #212121;
    padding: 8px 0px;
    gap: 5px;

    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-name {
        font-weight: 500;
        font-size: 16px;
        color: #464646;
      }
    }

    &-box {
      background: rgba(238, 238, 238, 0.5);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 0 22px 0 16px;
      height: 62px;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;
    }

    &-value {
      font-weight: 600;
      font-size: 20px;
      color: #0e608b;
    }

    &-margin {
      margin-bottom: 52px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      padding: 0 22px 0 16px;
      height: 46px;

      &-value {
        font-weight: 600;
        font-size: 18px;
        color: #0e608b;
      }
    }

    &-btn-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 9px;
      padding: 0px 22px 0px 16px;
      height: 46px;

      &-bottom {
        background: rgba(238, 238, 238, 0.5);
        border-radius: 12px;
        width: 433px;
        height: 27px;

        &-percent {
          position: relative;
          background: #eeeeee;
          border-radius: 12px;
          height: 27px;
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-weight: 600;
          font-size: 17px;
          text-align: right;
          color: #0e608b;
        }
      }
    }

    &-btn-btn {
      background: #eeeeee;
      border: 3px solid #000000;
      border-radius: 8px;
      font-size: 20px;
      color: #0e608b;
    }
  }

  &-post-l-inside {
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
    border-radius: 8px;
    background: white;
    padding: 19px 20px 26px 20px;
  }

  &-post-r {
    height: 745px;
    width: 417px;
    background: #f5f5f5;
    border: 1.5px dashed #535763;
    border-radius: 8px;
    padding: 23px 21px 25px 19px;

    &-border {
      width: 100%;
      background: rgba(255, 255, 255, 0.81);
      border: 1px dashed rgba(83, 87, 99, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      height: 100%;
      justify-content: space-around;
    }

    &-combo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-btn {
      border: unset;
      background-color: unset;
    }

    &-text {
      font-weight: 600;
      font-size: 18px;
      color: #535763;
    }

    &-bottom {
      display: flex;
      gap: 8px;
    }
  }
}

// Condition Manual
.con {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  &-container {
    padding: 0;
  }

  &-flex {
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-full {
    display: flex;
    flex-direction: column;
  }

  &-header {
    background-image: '/images/natureLogoBig.svg';

    &-title {
      font-weight: 600;
      font-size: 36px;
      color: #0e608b;
      padding: 2rem;
    }

    &-nav {
      width: 100%;
      padding: 0rem 2rem;
      background-color: #ffffff;

      &-btn {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #535763;
        background: #ffffff;
        border: unset;
        border-bottom: 2px solid #ffffff;
        padding: 14px 34px 15px 34px;

        &-act {
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          letter-spacing: -0.01em;
          color: #535763;
          background: #ffffff;
          border: unset;
          border-bottom: 2px solid #535763;
          padding: 14px 34px 15px 34px;
        }
      }
    }
  }

  &-body {
    padding: 2rem;

    &-post {
      width: 1016px;
      background: #ffffff;
      border: 1.5px solid #eeeeee;
      border-radius: 8px;
      padding: 30px;

      &-title {
        font-weight: 600;
        font-size: 25px;
        color: #0e608b;
      }

      &-parent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 35px;
      }

      &-cap {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #535763;
        line-height: 22px;
      }

      &-vid {
        background: rgb(245, 245, 245);
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Insufficient Funds
.fun {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-container {
    padding: 0;
  }

  &-flex {
    display: grid;
    grid-template-columns: 1fr;
  }

  &-header {
    display: flex;
    flex-direction: column;

    &-header {
      background-image: '/images/natureLogoBig.svg';
    }

    &-title {
      width: 100%;

      h4 {
        font-weight: 600;
        color: #0e608b;
        height: 62px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 24px;
        font-size: 16px;

        @media screen and (max-width: 992px) {
          height: 52px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 65px;
          padding-left: 15px;
        }
      }
    }
  }

  &-nav {
    width: 100%;
    padding: 0rem 2rem;
    background-color: #ffffff;

    &-btn {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #ffffff;
      padding: 14px 34px 15px 34px;

      &-deac {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #535763;
        background: rgba(83, 87, 99, 0.04);
        border: unset;
        border-bottom: 2px solid #535763;
        padding: 14px 34px 15px 34px;
      }
    }
  }

  &-body {
    padding: 20px 0;
  }

  &-post {
    height: 100%;
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 30px;
    gap: 24px;

    &-border {
      margin-bottom: 30px;
    }

    &-l {
      display: grid;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
        margin-bottom: 63px;
      }

      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-info {
          display: grid;
          justify-items: flex-start;

          &-title {
            font-weight: 200;
            font-size: 16px;
            color: #0e608b;
          }

          &-value {
            font-weight: 600;
            font-size: 16px;
            color: #0e608b;
          }

          &-parent {
            display: grid;
            justify-items: flex-end;
          }

          &-date {
            font-weight: 600;
            font-size: 14px;
            color: #373d50;

            &-value {
              font-weight: 600;
              font-size: 16px;
              color: #373d50;
            }
          }
        }

        &-middle {
          background: rgba(238, 238, 238, 0.5);
          border-radius: 8px;
          font-weight: 600;
          font-size: 20px;
          color: #0e608b;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 15px;
          padding-left: 15px;

          @media screen and (max-width: 1200px) {
            padding: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
          }

          &-btn {
            font-weight: 600;
            font-size: 14px;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 24px;
            height: 33px;
            background: #153458;
            border-radius: 4px;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
              transition: 0.3s;
              background-color: #3d5c81;
            }

            @media screen and (max-width: 552px) {
              font-weight: 200;
              font-size: 13px;
              padding: 6px 12px;
              height: 28px;
            }
          }
        }

        &-penult {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: nowrap;
          margin-bottom: 30px;

          &-parent {
            display: flex;
          }

          &-title {
            font-weight: 200;
            font-size: 14px;
            letter-spacing: 0.03em;
            color: #000000;
          }

          &-value {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.03em;
            // text-transform: uppercase;
            color: #000000;
            margin: 0 2px;
          }

          &-currency {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.03em;
            // text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        &-last {
          display: grid;
          grid-template-columns: 1fr;
          gap: 4px;

          &-parent {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 5px;
            height: 22px;

            @media screen and (max-width: 1200px) {
              height: 32px;
            }
          }

          &-btn {
            font-weight: 500;
            font-size: 14px;
            color: #1f1f1f;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 27px;
            height: 33px;
            background: #ffffff;
            border: 1.5px solid #e2e3e4;
            border-radius: 4px;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
              transition: 0.3s;
              background-color: #f6f6f6;
            }
          }
        }
      }
    }

    &-r {
      width: 100%;
      background: rgba(238, 238, 238, 0.5);
      border-radius: 16px;
      padding: 20px;

      &-title {
        font-weight: 600;
        font-size: 20px;
        color: #0e608b;
        margin-bottom: 25px;
      }

      &-rows {
        display: grid;
        gap: 16px;
        margin-bottom: 16px;
      }

      &-row {
        display: flex;
        justify-content: space-between;

        &-title {
          font-weight: 200;
          font-size: 14px;
          color: #373d50;
        }

        &-value {
          font-weight: 600;
          font-size: 14px;
          text-align: right;
          letter-spacing: -0.01em;
          color: #0e608b;

          &-red {
            font-weight: 600;
            font-size: 14px;
            text-align: right;
            letter-spacing: 0.03em;
            color: #ff4242;
          }
        }
      }

      &-br {
        height: 1px;
        background: rgba(83, 87, 99, 0.13);
        margin-bottom: 10px;
      }

      &-penult {
        display: grid;
        gap: 6px;
        margin-bottom: 16px;
      }

      &-btn {
        width: 100%;
        background: #eeeeee;
        border-radius: 8px;
        font-size: 20px;
        background-color: #153458;
        color: #ffffff;
        height: 54px;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          transition: 0.3s;
          background-color: #3d5c81;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;

      &-l {
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    @media screen and (max-width: 552px) {
      padding: 15px;

      &-l {
        &-title {
          font-size: 20px;
          margin-bottom: 30px;
        }

        &-row {
          &-info {
            &-title,
            &-value,
            &-date {
              font-size: 14px;
            }

            &-date {
              &-value {
                font-size: 14px;
              }
            }
          }

          &-middle {
            font-size: 14px;
          }

          &-penult {
            &-title,
            &-value,
            &-currency {
              font-size: 13px;
            }
          }
        }
      }

      &-r {
        padding: 15px 10px;

        &-btn {
          height: 38px;
          font-size: 16px;
        }

        &-row {
          &-title,
          &-value {
            font-size: 13px;
          }
        }

        &-rows {
          gap: 12px;
        }
      }
    }
  }

  &-note {
    font-weight: 600;
    font-size: 28px;
    color: #0e608b;
    display: flex;
    align-items: center;
    gap: 13px;
    margin-bottom: 29px;

    &-br {
      width: 100%;
      height: 1px;
      background: rgba(83, 87, 99, 0.13);
    }

    &-grid {
      display: grid;
      gap: 18px;
    }

    &-parent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
    }

    &-num {
      font-weight: 500;
      font-size: 24px;
      letter-spacing: -0.005em;
      // text-transform: uppercase;
      color: rgb(255, 255, 255);
      width: 55px;
      min-width: 55px;
      height: 55px;
      background: #153458;
      border: 8px solid rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
    }

    &-post {
      display: grid;
      gap: 16px;
      margin-top: 14px;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-info {
        font-weight: 200;
        font-size: 16px;
        color: #0e608b;
        line-height: 24px;
        position: relative;

        &:before {
          content: '';
          background-color: #ffffff;
          position: absolute;
          width: 5px;
          height: 100px;
          top: -50px;
          left: -50px;
          display: block;
          z-index: -1;
          border-radius: 8px;
        }
      }
    }

    @media screen and (max-width: 992px) {
      font-size: 24px;

      &-num {
        font-size: 18px;
        width: 45px;
        min-width: 45px;
        height: 45px;
      }

      &-post {
        &-title {
          font-size: 20px;
        }

        &-info {
          font-size: 14px;
          line-height: 20px;

          &:before {
            height: 80px;
            left: -45px;
          }
        }
      }
    }

    @media screen and (max-width: 552px) {
      font-size: 20px;

      &-parent {
        gap: 12px;
      }

      &-num {
        font-size: 14px;
        width: 40px;
        min-width: 40px;
        height: 40px;
      }

      &-post {
        &-title {
          font-size: 18px;
        }

        &-info {
          font-size: 13px;
          line-height: 18px;

          &:before {
            height: 60px;
            left: -35px;
          }
        }
      }
    }
  }
}

// Introduction Video
.intr {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  width: 100%;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  &-flex {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-container {
    display: grid;
  }

  &-title {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
    margin-top: 20px;
  }

  &-post {
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 16px;
    padding: 30px;
    margin: 1rem;
  }

  &-step {
    display: grid;
    width: 100%;
    justify-items: center;

    &-parent {
      display: grid;
      gap: 1rem;
      width: 592px;
      grid-template-columns: 1fr 2fr 1fr 2fr 1fr 2fr 1fr;
      align-items: center;
      margin-bottom: 36px;
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
    }

    &-back {
      width: 34px;
      height: 34px;
      left: 677px;
      top: 165px;
      background: #000000;
      border-radius: 20px;
      text-align: center;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;

      &-deac {
        width: 34px;
        height: 34px;
        left: 677px;
        top: 165px;
        background: #f5f5f5;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
      }
    }

    &-num {
      color: #eeee;

      &-deac {
        color: #ffffff;
      }
    }

    &-text {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      color: #000000;

      &-deac {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #f5f5f5;
      }
    }

    &-line {
      width: 100%;
      height: 6px;
      background: #f5f5f5;
      border-radius: 40px;

      &-completed {
        width: 100%;
        height: 6px;
        background: #000000;
        border-radius: 40px;
        position: relative;
      }

      &-zero {
        width: 0%;
        height: 6px;
        background: #000000;
        border-radius: 40px;
        position: relative;
      }
    }
  }

  &-video {
    margin-bottom: 26px;
    // height: 577px;
    background: #f5f5f5;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //youtube
    padding-bottom: 56.25%;
    height: 0;
  }

  &-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-bottom {
    display: flex;
    justify-content: flex-end;

    &-btn {
      background: #153458;
      border-radius: 8px;
      color: white;
      height: 62px;
      position: relative;
      left: 0;
      width: 186px;
      font-weight: 600;
      font-size: 20px;
      border: 0;
    }
  }

  &-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 1300px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 920px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &-logo {
      margin-bottom: 26px;
      height: 104px;
      overflow: hidden;
      background: #f5f5f5;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-q {
    font-weight: 600;
    font-size: 16px;
    color: #676767;

    &-input {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      background: #f5f5f5;
      border: 0.5px solid #eeeeee;
      border-radius: 8px;
      margin-bottom: 24px;
      margin-top: 5px;

      &-last {
        padding: 15px;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        background: #f5f5f5;
        border: 0.5px solid #eeeeee;
        border-radius: 8px;
        margin-bottom: 72px;
        margin-top: 5px;
      }
    }
  }

  &-last {
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    &-row {
      margin: 1rem 0px 2rem;

      &-label {
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.6);
        margin-left: 10px;
        vertical-align: middle;
      }

      &-right {
        display: flex;
        justify-content: flex-end;

        &-btn {
          cursor: pointer;
          background: #153458;
          border-radius: 8px;
          border: 0;
          height: 62px;
          position: relative;
          left: 0;
          width: 186px;
          font-weight: 600;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.87);

          &.disable {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: initial;
    padding: 0 10px;

    &-last {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    &-video {
      height: 140px;
      margin-bottom: 16px;

      img {
        width: 30px;
      }
    }

    &-videos {
      &-logo {
        img {
          width: 30px;
        }
      }
    }

    &-bottom {
      &-btn {
        width: 100% !important;
        height: 48px;
        font-size: 16px;
      }
    }

    &-title {
      margin-top: 70px;
      font-size: 24px;
    }

    &-post {
      padding: 20px;
      margin: 7rem 0 2rem;
    }

    &-step {
      padding: 0;

      &-step {
        &-title {
          font-size: 11px;
          height: 14px;
        }
      }
    }

    &-last {
      &-row {
        &-right {
          &-btn {
            width: 100%;
            height: 48px;
            font-size: 16px;
          }
        }
      }
    }

    &-q {
      font-size: 14px;

      &-input {
        // height: 38px;
        margin-bottom: 14px;
      }
    }
  }
}

// Island Overview
.over {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-container {
    padding: 0 20px;
    margin-top: 1rem !important;
  }

  &-flex {
    // background-color: #F5F5F5;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-all {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-header {
    background-image: '/images/natureLogoBig.svg';

    &-title {
      width: 100%;
      margin-top: 1rem;

      h4 {
        font-weight: 600;
        color: #0e608b;
        height: 62px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 24px;
        font-size: 16px;

        @media screen and (max-width: 992px) {
          height: 52px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 65px;
          padding-left: 15px;
        }
      }
    }
  }

  &-nav {
    width: 100%;
    padding: 0rem 2rem;
    background-color: #ffffff;
    border-radius: 16px;

    &-btn {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #ffffff;
      padding: 14px 34px 15px 34px;

      &-deac {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #535763;
        background: rgba(83, 87, 99, 0.04);
        border: unset;
        border-bottom: 2px solid #535763;
        padding: 14px 34px 15px 34px;
      }
    }
  }

  &-body {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 17px;
  }

  &-f-row {
    background: rgb(255, 255, 255);
    border: 1.5px solid rgb(238, 238, 238);
    border-radius: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 32px;
    padding: 30px;
    width: 100%;
    justify-items: stretch;

    &-inside {
      display: flex;
      gap: 22px;
      flex-direction: column;
    }

    &-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-item {
      display: flex;
      gap: 17px;
      flex-direction: column;

      &:last-child {
        text-align: right;
      }
    }

    &-title {
      display: flex;
      gap: 5px;
      flex-direction: column;
      font-weight: 200;
      font-size: 18px;
      color: #0e608b;
    }

    &-value {
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.03px;
      color: #0e608b;
      margin-top: 2px;
    }

    &-br {
      width: 100%;
      height: 1px;
      background: #f5f5f5;
    }

    &-label {
      font-weight: 600;
      font-size: 16px;
      color: #373d50;
      line-height: 28px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 20px;
    }

    @media screen and (max-width: 552px) {
      padding: 15px;

      &-title,
      &-value {
        font-size: 13px;
      }

      &-inside {
        gap: 12px;
      }
    }
  }

  &-f-post {
    display: grid;
    gap: 16px;
    margin-bottom: 16px;

    &-inside {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      margin-top: 5px;

      &-title {
        font-weight: 200;
        font-size: 16px;
        color: #0e608b;
      }

      &-value {
        font-weight: 600;
        font-size: 16px;
        color: #0e608b;
        text-align: right;
        letter-spacing: 0.03px;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #0e608b;
      }

      &-value {
        font-weight: 600;
        font-size: 36px;
        color: #0e608b;
      }
    }

    @media screen and (max-width: 552px) {
      &-inside {
        margin-top: 0;
        margin-bottom: 0;

        &-title,
        &-value {
          font-size: 13px;
        }
      }

      &-right {
        &-title {
          font-size: 14px;
        }

        &-value {
          font-size: 24px;
        }
      }
    }
  }

  &-br {
    width: 95%;
    height: 1px;
    background: #ffffff;
  }

  &-s {
    background: rgb(255, 255, 255);
    border: 1.5px solid rgb(238, 238, 238);
    border-radius: 16px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 54px;
    width: 100%;

    &-inside {
      align-items: center;
      display: flex;
      gap: 34px;
    }

    &-title {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
    }

    &-blue-box {
      font-weight: 500;
      font-size: 14px;
      color: #34b3f1;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-blue-text {
      width: 16px;
      height: 16px;
      background: #34b3f1;
      border-radius: 4px;
      margin-bottom: 5px;
    }

    &-black-box {
      font-weight: 500;
      font-size: 14px;
      color: #373d50;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-black-text {
      width: 16px;
      height: 16px;
      background: #373d50;
      border-radius: 4px;
      margin-bottom: 5px;
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 24px;

      &-percent {
        font-weight: 500;
        font-size: 15px;
        color: rgba(83, 87, 99, 0.6);
      }

      &-line {
        width: 100%;
        height: 37px;
        background: #f6f7f7;
        border: 1px solid #eeeeee;
        border-radius: 13px;
        font-weight: 600;
        font-size: 18px;
        color: #373d50;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 13px;
      }

      &-completed {
        width: 40%;
        height: 37px;
        background: #34b3f1;
        border-radius: 13px;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.01em;
        color: #ffffff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;

      &-inside {
        gap: 12px;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    @media screen and (max-width: 552px) {
      padding: 15px;
      gap: 22px;

      &-row {
        gap: 12px;
      }

      &-title {
        font-size: 20px;
      }
    }
  }

  &-th {
    display: grid;
    grid-template-columns: 1fr;
    gap: 23px;
    width: 100%;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 1.4fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &-l {
      background: #ffffff;
      border-radius: 16px;
      padding: 30px;
      width: 100%;

      canvas {
        width: 100% !important;
      }

      &-inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-box {
        font-weight: 500;
        font-size: 14px;
        color: #8d9092;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.8px;

        &-title {
          width: 10.35px;
          height: 10.35px;
          background: #34b3f1;
          border-radius: 4px;
          margin-top: -5px;
        }
      }

      // &-option{
      //     font-weight: 400;
      //     font-size: 14px;
      //     color: #8D9092;
      //     border-radius: 12px;
      //     border: 1.5px solid #EEEEEE;
      // }
      &-option {
        font-weight: 600;
        font-size: 14px;
        color: #153458;
        border-radius: 16px;
        border: 1px solid #153458;
        padding: 4px 8px 4px 10px;

        background: url("data:image/svg+xml,<svg t='1675155272854' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'><path d='M760.454652 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227064 0L511.706311 596.712357 308.185035 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227065 0-12.489452 12.489452-12.489452 32.737612 0 45.227064L489.093291 664.553465c12.489452 12.489452 32.737612 12.489452 45.227064 0L760.454652 438.418144c12.488429-12.489452 12.488429-32.738635 0-45.227064z' p-id='1287'></path></svg>")
          no-repeat;
        background-position: calc(110% - 14px) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 30px !important;
      }

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      @media screen and (max-width: 552px) {
        padding: 15px;

        &-title {
          font-size: 20px;
        }
      }
    }

    &-r {
      width: 100%;
      position: relative;
      background: #ffffff;
      border-radius: 16px;
      padding: 30px;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
        margin-bottom: 67px;
      }

      &-symbol {
        position: absolute;
        left: 0;
        top: 147px;
      }

      &-circle {
        display: flex;
        justify-content: flex-end;
        justify-items: flex-end;
        margin-bottom: 23px;
        position: relative;

        &:before {
          content: '';
          background-color: #166bb6;
          position: absolute;
          width: 110px;
          height: 5px;
          top: 50%;
          left: 148px;
          display: block;
          border-radius: 8px;
          z-index: 0;
        }

        &-image {
          &-top {
            position: absolute;
            right: -30px;
            top: -30px;
          }

          &-bottom {
            position: absolute;
            right: 0;
            bottom: -50px;
          }
        }
      }

      &-label {
        width: 140px;
        height: 140px;
        border: 14px solid #166bb6;
        border-radius: 85px;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #ffffff;
        z-index: 0;
      }

      &-value {
        font-weight: 600;
        font-size: 28px;
        letter-spacing: -0.01em;
        color: #373d50;
      }

      @media screen and (max-width: 992px) {
        &-circle {
          margin-bottom: 62px;

          &:before {
            width: 30px;
          }
        }

        &-label {
          width: 110px;
          height: 110px;
        }

        &-value {
          font-size: 24px;

          &-l {
            font-size: 12px;
          }
        }

        &-symbol {
          top: 132px;
        }
      }

      @media screen and (max-width: 768px) {
        padding: 20px;

        &-title {
          margin-bottom: 24px;
        }

        &-symbol {
          top: 79px;
        }

        &-circle {
          &:before {
            width: 260px;
          }

          &-image {
            &-top {
              right: -32px;
              top: -40px;
            }

            &-bottom {
              right: 4px;
              bottom: -60px;
            }
          }
        }

        &-label {
          border: 6px solid #166bb6;
        }
      }

      @media screen and (max-width: 552px) {
        padding: 15px;
        height: initial;

        &-circle {
          &:before {
            width: 50%;
            left: 164px;
          }
        }

        &-symbol {
          top: 60px;
        }

        &-label {
          width: 92px;
          height: 92px;
          background-color: #166bb6;
          color: #ffffff;
        }

        &-value {
          color: #ffffff;
        }

        &-title {
          font-size: 20px;
        }
      }

      @media screen and (max-width: 332px) {
        &-circle {
          &:before {
            width: 45%;
          }
        }
      }
    }
  }

  &-last {
    width: 100%;
    height: 556px;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;

    &-title {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
      margin-bottom: 13px;
    }

    &-combo {
      display: flex;
      gap: 39px;
      margin-bottom: 97px;
    }

    &-blue-box {
      font-weight: 500;
      font-size: 14px;
      color: #8d9092;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-text {
      width: 16px;
      height: 16px;
      background: #34b3f1;
      border-radius: 4px;
      margin-top: -5px;
    }

    &-black-box {
      width: 16px;
      height: 16px;
      background: #292d30;
      border-radius: 4px;
      margin-top: -5px;
    }

    &-black-title {
      font-weight: 500;
      font-size: 14px;
      color: #8d9092;
    }

    &-charts {
      display: flex;
      gap: 83px;
      align-items: center;
      justify-content: center;
    }

    &-chart {
      font-weight: 400;
      font-size: 15px;
      text-align: center;
      color: #8d9092;
      display: flex;
      gap: 13px;
      flex-direction: column;
      align-items: center;

      &-line {
        display: flex;
        align-items: flex-end;
        gap: 13px;

        &-black {
          width: 30px;
          height: 293px;
          background: #292d30;
          border-radius: 9px;
        }

        &-blue {
          width: 30px;
          height: 207px;
          background: #34b3f1;
          border-radius: 9px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
      height: initial;

      &-charts {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 552px) {
      padding: 15px;
      height: initial;

      &-title {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &-nav {
      padding: 0;
      overflow: auto;
      white-space: nowrap;
      max-width: 110vw;
      border-radius: 8px;

      &-btn {
        font-size: 14px;
        border-bottom: 2px solid #ffffff;
        padding: 10px 15px;

        &-deac {
          padding: 10px 15px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    &-nav {
      max-width: 90vw;
    }
  }
}

.over-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  width: 100%;
}

.over-hero {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;

  // display: flex;
  // justify-content: space-between;
  // position: relative;
  .over-hero-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .over-hero-total {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .over-hero-total-symbol {
      width: 60px;
      height: 60px;
      background: rgb(248, 248, 249);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .over-hero-total-text {
      display: flex;
      flex-direction: column;
      line-height: 26px;
      margin-left: 12px;
    }
  }
}

.over-connections {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 68px 25px;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: inherit;

  .over-connections-schema {
    display: flex;
    align-items: center;

    span {
      border: 14px solid #166bb6;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      font-size: 28px;
      font-weight: 600;
      color: #3b3b3b;
      margin-left: 10px;
    }
  }

  .over-connections-total {
    display: flex;
    align-items: center;

    .over-connections-total-symbol {
      width: 60px;
      height: 60px;
      background: rgb(248, 248, 249);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .over-connections-total-text {
      display: flex;
      flex-direction: column;
      line-height: 26px;
      margin-left: 12px;
    }
  }
}

.over-participate {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 25px;
  text-align: center;

  .over-participate-subject {
    h4 {
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 10px;
    }

    span {
      font-size: 36px;
      font-weight: 600;
    }
  }

  .over-participate-w {
    display: flex;
    justify-content: space-between;

    .over-participate-w-f {
      text-align: left;
    }

    .over-participate-w-s {
      text-align: right;
    }

    .over-participate-w-f,
    .over-participate-w-s {
      line-height: 32px;

      h5 {
        font-size: 18px;
        font-weight: 200;
      }

      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  > hr {
    margin: 10px 0;
    border-color: transparent;
    background-color: whitesmoke;
  }

  .over-participate-d {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      font-weight: 200;
    }

    b {
      font-size: 16px;
      font-weight: 600;
      color: #535763;
      text-wrap: nowrap;
      white-space: nowrap;
    }
  }

  .over-participate-b {
    display: flex;
    gap: 16px;
    padding-top: 10px;

    button {
      width: 100%;
      height: 54px;
      background-color: #153458;
      border-radius: 8px;
      font-weight: 600;
      font-size: 18px;
      position: relative;
      letter-spacing: 0.03em;
      color: #ffffff;
      cursor: pointer;

      &:after,
      &:active,
      &:hover {
        transition: 0.3s;
        background-color: #3d5c81;
      }
    }
  }

  .over-participate-b-b {
    margin-top: 16px;

    button {
      width: 100%;
      height: 54px;
      background-color: #153458;
      border-radius: 8px;
      font-weight: 600;
      font-size: 22px;
      position: relative;
      letter-spacing: 0.03em;
      color: #ffffff;
      cursor: pointer;

      &:after,
      &:active,
      &:hover {
        transition: 0.3s;
        background-color: #3d5c81;
      }
    }
  }
}

.over-chart {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 25px;
  margin-top: 14px;

  .over-chart-title {
    font-weight: 600;
    font-size: 24px;
    color: #1a2b40;
  }

  .over-chart-content {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;

    .over-chart-content-s {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }

      .over-chart-content-s-green {
        width: 10.35px;
        height: 10.35px;
        background: #959595;
        border-radius: 4px;
      }

      .over-chart-content-s-darkblue {
        width: 10.35px;
        height: 10.35px;
        background: #8a2be2;
        border-radius: 4px;
      }

      .over-chart-content-s-blue {
        width: 10.35px;
        height: 10.35px;
        background: #34b3f1;
        border-radius: 4px;
      }

      span {
        font-size: 14px;
        margin-left: 5px;
        margin-top: 6px;
      }
    }
  }

  .over-chart-line {
    canvas {
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .over-main {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .over-hero,
  .over-connections,
  .over-participate,
  .over-chart {
    padding: 20px;
  }

  .over-chart {
    margin-top: 10px;
  }

  .over-participate {
    .over-participate-b {
      button {
        height: 44px;
        font-size: 18px;
      }
    }

    .over-participate-b-b {
      button {
        height: 44px;
        font-size: 18px;
      }
    }
  }

  .over-chart {
    .over-chart-title {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 552px) {
  .over-hero,
  .over-connections,
  .over-participate,
  .over-chart {
    padding: 20px 10px;
  }

  .over-hero {
    flex-direction: column;
    align-items: center;

    .over-hero-total {
      .over-hero-total-symbol {
        width: 50px;
        height: 50px;
      }

      .over-hero-total-text {
        line-height: 22px;
        margin-left: 8px;

        span {
          font-size: 14px;
        }

        b {
          font-size: 14px;
        }
      }
    }
  }

  .over-connections {
    flex-direction: column;
    align-items: center;

    .over-connections-schema {
      span {
        width: 60px;
        height: 60px;
        background-color: #166bb6;
        color: #dfffffff;
        font-size: 24px;
      }
    }

    .over-connections-total {
      .over-connections-total-text {
        line-height: 22px;
        margin-left: 8px;

        span {
          font-size: 14px;
        }

        b {
          font-size: 14px;
        }
      }
    }
  }

  .over-participate {
    .over-participate-subject {
      h4 {
        font-size: 16px;
      }

      span {
        font-size: 26px;
      }
    }

    .over-participate-w {
      .over-participate-w-f,
      .over-participate-w-s {
        line-height: 24px;

        h5 {
          font-size: 14px;
        }

        span {
          font-size: 16px;
        }
      }
    }

    > hr {
      margin: 10px 0 15px;
    }

    .over-participate-d {
      margin-bottom: 10px;

      span,
      b {
        font-size: 14px;
      }
    }

    .over-participate-b {
      button {
        height: 38px;
        font-size: 14px;
      }
    }

    .over-participate-b-b {
      button {
        height: 38px;
        font-size: 14px;
      }
    }
  }

  .over-chart {
    .over-chart-title {
      font-size: 18px;
    }

    .over-chart-content {
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }
}

// Island Participate
.part {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-container {
    padding: 0;
  }

  &-flex {
    display: grid;
    grid-template-columns: 1fr;
  }

  &-header {
    display: flex;
    flex-direction: column;

    &-header {
      background-image: '/images/natureLogoBig.svg';
    }

    &-title {
      width: 100%;

      h4 {
        font-weight: 600;
        color: #0e608b;
        height: 62px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 24px;
        font-size: 16px;

        @media screen and (max-width: 992px) {
          height: 52px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 65px;
          padding-left: 15px;
        }
      }
    }
  }

  &-nav {
    width: 100%;
    padding: 0rem 2rem;
    background-color: #ffffff;
    border-radius: 16px;

    &-btn {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #ffffff;
      padding: 14px 34px 15px 34px;

      &-deac {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #535763;
        background: rgba(83, 87, 99, 0.04);
        border: unset;
        border-bottom: 2px solid #535763;
        padding: 14px 34px 15px 34px;
      }
    }
  }

  &-body {
    padding: 20px 0;
  }

  &-post {
    height: 100%;
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 30px;
    gap: 24px;

    &-border {
      margin-bottom: 30px;
    }

    &-l {
      display: grid;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
        margin-bottom: 63px;
      }

      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-info {
          display: grid;
          justify-items: flex-start;

          &-title {
            font-weight: 700;
            font-size: 16px;
            color: #0e608b;
          }

          &-value {
            font-weight: 600;
            font-size: 18px;
            color: #0e608b;
          }

          &-parent {
            display: grid;
            justify-items: flex-end;
          }

          &-date {
            font-weight: 700;
            font-size: 16px;
            color: #0e608b;

            &-value {
              font-weight: 600;
              font-size: 16px;
              color: #0e608b;
            }
          }
        }

        &-middle {
          background: rgba(234, 242, 246, 0.4);
          border-radius: 8px;
          font-weight: 600;
          font-size: 20px;
          color: #0e608b;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 16px;

          @media screen and (max-width: 1200px) {
            padding: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        &-penult {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: nowrap;
          margin-bottom: 30px;

          &-parent {
            display: flex;
          }

          &-title {
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.03em;
            color: #0e608b;
          }

          &-value {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.03em;
            // text-transform: uppercase;
            color: #0e608b;
            margin: 0 2px;
          }

          &-currency {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.03em;
            // text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        &-last {
          display: grid;
          grid-template-columns: 1fr;
          gap: 4px;

          &-parent {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 5px;
            height: 22px;

            @media screen and (max-width: 1200px) {
              height: 32px;
            }
          }

          &-btn {
            font-weight: 500;
            font-size: 14px;
            color: #1f1f1f;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 27px;
            height: 33px;
            background: #ffffff;
            border: 1.5px solid #e2e3e4;
            border-radius: 4px;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
              transition: 0.3s;
              background-color: #e2e3e4;
            }
          }
        }
      }
    }

    &-r {
      width: 100%;
      background: rgba(234, 242, 246, 0.4);
      border-radius: 16px;
      padding: 20px;

      &-title {
        font-weight: 600;
        font-size: 20px;
        color: #0e608b;
        margin-bottom: 25px;
      }

      &-rows {
        display: grid;
        gap: 16px;
        margin-bottom: 16px;
      }

      &-row {
        display: flex;
        justify-content: space-between;

        &-title {
          font-weight: 200;
          font-size: 14px;
          color: #373d50;
        }

        &-value {
          font-weight: 600;
          font-size: 14px;
          text-align: right;
          letter-spacing: -0.01em;
          color: #0e608b;

          &-red {
            font-weight: 600;
            font-size: 14px;
            text-align: right;
            letter-spacing: -0.01em;
            color: #ff4242;
          }
        }
      }

      &-br {
        height: 1px;
        background: rgba(83, 87, 99, 0.13);
        margin-bottom: 10px;
      }

      &-penult {
        display: grid;
        gap: 6px;
        margin-bottom: 16px;
      }

      &-btn {
        width: 100%;
        border-radius: 8px;
        font-size: 20px;
        background-color: #153458;
        color: #ffffff;
        height: 54px;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          transition: 0.3s;
          background-color: #3d5c81;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;

      &-l {
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    @media screen and (max-width: 552px) {
      padding: 15px;

      &-l {
        &-title {
          font-size: 20px;
          margin-bottom: 30px;
        }

        &-row {
          &-info {
            &-title,
            &-value,
            &-date {
              font-size: 14px;
            }

            &-date {
              &-value {
                font-size: 14px;
              }
            }
          }

          &-middle {
            font-size: 14px;
          }

          &-penult {
            &-title,
            &-value,
            &-currency {
              font-size: 13px;
            }
          }
        }
      }

      &-r {
        padding: 15px 10px;

        &-btn {
          height: 38px;
          font-size: 16px;
        }

        &-row {
          &-title,
          &-value {
            font-size: 13px;
          }
        }

        &-rows {
          gap: 12px;
        }
      }
    }
  }

  &-note {
    font-weight: 600;
    font-size: 28px;
    color: #0e608b;
    display: flex;
    align-items: center;
    gap: 13px;
    margin-bottom: 29px;

    &-br {
      width: 100%;
      height: 1px;
      background: rgba(83, 87, 99, 0.13);
    }

    &-grid {
      display: grid;
      gap: 18px;
    }

    &-parent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
    }

    &-num {
      font-weight: 500;
      font-size: 24px;
      letter-spacing: -0.005em;
      // text-transform: uppercase;
      color: rgb(255, 255, 255);
      width: 55px;
      min-width: 55px;
      height: 55px;
      background: #153458;
      border: 8px solid rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
    }

    &-post {
      display: grid;
      gap: 16px;
      margin-top: 14px;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-info {
        font-weight: 200;
        font-size: 16px;
        color: #0e608b;
        line-height: 24px;
        position: relative;

        &:before {
          content: '';
          background-color: #ffffff;
          position: absolute;
          width: 5px;
          height: 100px;
          top: -50px;
          left: -50px;
          display: block;
          z-index: -1;
          border-radius: 8px;
        }
      }
    }

    @media screen and (max-width: 992px) {
      font-size: 24px;

      &-num {
        font-size: 18px;
        width: 45px;
        min-width: 45px;
        height: 45px;
      }

      &-post {
        &-title {
          font-size: 20px;
        }

        &-info {
          font-size: 14px;
          line-height: 20px;

          &:before {
            height: 80px;
            left: -45px;
          }
        }
      }
    }

    @media screen and (max-width: 552px) {
      font-size: 20px;

      &-parent {
        gap: 12px;
      }

      &-num {
        font-size: 14px;
        width: 40px;
        min-width: 40px;
        height: 40px;
      }

      &-post {
        &-title {
          font-size: 18px;
        }

        &-info {
          font-size: 13px;
          line-height: 18px;

          &:before {
            height: 60px;
            left: -35px;
          }
        }
      }
    }
  }
}

// Login
.login-bg {
  background: url(.//assets/videos/bg.jpg);
  background-size: cover;
  backdrop-filter: blur(15px);
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .videoTag {
    position: fixed;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }
}

.bos-header {
  width: 592px;
  height: 84px;
  left: 424px;
  top: 106px;

  background: #ffffff78;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  .logo {
    display: flex;
    align-items: center;
    height: inherit;
    margin: 0 2rem;

    img {
      filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.1));
      height: 60px;
    }
  }
}

@media screen and (max-width: 600px) {
  .bos-header {
    width: unset;
  }
}

.login {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 133.4%;
  /* identical to box height, or 48px */

  background: linear-gradient(
    89.72deg,
    #2a68b2 -2.35%,
    #2dbdcd 51.93%,
    #2e94d0 106.08%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;

  &-bottom-line {
    width: 100%;
    max-width: 534px;
    height: 2px;
    left: 453px;
    top: 484px;
    margin: 1rem 0 2.2rem;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 13px;
  }

  &-flex {
    padding: 32px;
    display: grid !important;
    width: 100%;
  }

  &-form {
    width: 100%;
    max-width: 592px;
    // height: 411px;
    padding: 26px 27px;
    background: rgb(255 255 255 / 70%);
    backdrop-filter: blur(4px);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16px;

    &-label {
      font-weight: 600;
      font-size: 16px;
      color: #000000;

      &.email {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-input {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      border: 0.0313rem solid #eeeeee;
      margin-bottom: 10px;
      height: 48px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-size: 17px;

      &::focus {
        outline: none;
      }

      &::placeholder {
        font-weight: 400;
        line-height: 24px;
        color: #615f5f;
      }

      &-last {
        padding: 15px;
        box-sizing: border-box;
        width: 100%;
        border: 0.0313rem solid #eeeeee;
        margin-bottom: 10px;
        height: 48px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-size: 17px;

        &::placeholder {
          font-weight: 400;
          line-height: 24px;
          color: #615f5f;
        }
      }
    }

    &-btn {
      cursor: pointer;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 133.4%;
      /* or 29px */

      span {
        background: linear-gradient(
          89.72deg,
          #2a68b2 -2.35%,
          #2dbdcd 51.93%,
          #2e94d0 106.08%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }

      height: 62px;
      left: 451px;
      top: 689px;

      background: #ffffff;
      border-radius: 8px;
      border: 0;
    }

    &-bottom {
      font-weight: 400;
      font-size: 17px;
      display: flex;
      align-items: center;
      color: #000000;
      justify-content: center;
      position: relative;
      gap: 0.4rem;

      &-btn {
        border: unset;
        background-color: unset;
        font-weight: 600;
        font-size: 17px;
        align-items: center;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}

.input-password-with-option {
  position: relative;

  .input-option.pointer {
    position: absolute;
    right: 2rem;
    top: -7px;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}

// Successful
.succ {
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 392px;
  border: 1.5px solid #eeeeee;
  border-radius: 8px;

  &-flex {
    background: #f5f5f5;
    padding: 2rem;
    display: grid;
  }

  &-check {
    background: rgb(245, 245, 245);
    border-radius: 36px;
    padding: 19px 26px 20px 23px;
    font-weight: 600;
    font-size: 25px;
  }

  &-combo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }

  &-title {
    font-weight: 600;
    font-size: 32px;
    align-items: center;
    text-align: center;
    color: #535763;
    display: flex;
  }

  &-p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #535763;
  }

  &-btn {
    width: 100%;
    background: #eeeeee;
    border: 3px solid #000000;
    border-radius: 8px;
    height: 48px;
  }

  &-text {
    font-weight: 600;
    font-size: 18px;
  }
}

// My Treasure Inside My Crew

.mycrew {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-container {
    padding: 0;
  }

  &-flex {
    display: grid;
    grid-template-columns: 1fr;
  }

  &-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-header {
    background-image: '/images/natureLogoBig.svg';

    &-title {
      width: 100%;

      h4 {
        font-weight: 600;
        color: #0e608b;
        height: 62px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 24px;
        font-size: 16px;

        @media screen and (max-width: 992px) {
          height: 52px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 65px;
          padding-left: 15px;
        }
      }

      &-nav {
        width: 100%;
        padding: 0rem 2rem;
        background-color: #ffffff;
      }

      &-btn {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #535763;
        background: #ffffff;
        border: unset;
        border-bottom: 2px solid #ffffff;
        padding: 14px 34px 15px 34px;

        &-deac {
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          letter-spacing: -0.01em;
          color: #535763;
          background: rgba(83, 87, 99, 0.04);
          border: unset;
          border-bottom: 2px solid #535763;
          padding: 14px 34px 15px 34px;
        }
      }
    }
  }

  &-inside {
    &-border {
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 24px;
    }

    &-post {
      display: grid;
      grid-template-columns: 1fr 1.7fr;
      gap: 24px;
      align-items: flex-end;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-combo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-top: 22px;
        margin-bottom: 15px;
      }

      &-label {
        font-weight: 200;
        font-size: 16px;
        color: #0e608b;
      }

      &-value {
        font-weight: 600;
        font-size: 16px;
        text-align: right;
        letter-spacing: 0.03em;
        color: #0e608b;
      }

      &-parent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }

      @media screen and (max-width: 552px) {
        &-title {
          font-size: 20px;
        }

        &-label,
        &-value {
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      &-post {
        grid-template-columns: 1fr;
      }

      &-border {
        padding: 20px;
      }

      &-post {
        &-label,
        &-value {
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 552px) {
      &-border {
        padding: 15px;
      }
    }
  }

  &-post {
    display: flex;
    gap: 8px;
    flex-direction: column;

    &-inside {
      font-weight: 600;
      font-size: 16px;
      color: #1f1f1f;
      display: flex;
      gap: 6px;

      &-text {
        font-weight: 200;
        font-size: 15px;
        color: rgb(31, 31, 31);
        height: 45px;
        background: rgba(238, 238, 238, 0.5);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
      }

      &-btn {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 92px;
        height: 37px;
        background-color: #153458;
        color: #ffffff;
        border-radius: 8px;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          transition: 0.3s;
          background-color: #3d5c81;
        }
      }
    }
  }

  &-bottom {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    align-items: center;
    justify-items: center;
    gap: 12px;

    &-border {
      background: #ffffff;
      border-radius: 16px;
      display: grid;
      padding: 30px;
      gap: 24px;
      align-items: center;
      width: 100%;
      justify-items: center;
      align-content: space-between;
      position: relative;
      width: 100%;

      &-box {
        font-weight: 600;
        font-size: 16px;
        color: #535763;
        display: grid;
        justify-items: center;
        margin-bottom: 30px;
        gap: 12px;
      }

      @media screen and (max-width: 1200px) {
        padding: 15px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 40px;
      }

      @media screen and (max-width: 552px) {
        padding: 10px;
      }
    }

    &-combo {
      display: grid;
      gap: 16px;
      justify-items: center;
      width: 100%;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 15px;
      width: 100%;
      background: #f5f5f5;
      border-radius: 16px;

      &-title {
        font-weight: 200;
        font-size: 14px;
        color: #0e608b;
      }

      &-value {
        font-weight: 200;
        font-size: 14px;
        text-align: right;
        color: #535763;
      }

      &-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;

        &-title {
          font-weight: 200;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: #212121;
        }

        &-value {
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: #535763;
          margin-left: 3px;
        }
      }

      &-icon {
        position: absolute;
        right: -42px;
      }
    }

    @media screen and (max-width: 762px) {
      grid-template-columns: 1fr;

      &-border {
        &-box {
          margin-bottom: 0;
        }
      }

      &-row {
        &-icon {
          right: initial;
          bottom: -40px;
          left: 10px;
          transform: rotate(90deg);
        }
      }
    }

    @media screen and (max-width: 552px) {
      &-combo {
        gap: 8px;
      }

      &-row {
        padding: 5px 10px;

        &-title {
          font-size: 12px;
        }
      }
    }
  }

  &-right {
    width: 100%;
    display: grid;
    gap: 30px;
    padding-left: 65px;

    &-post {
      display: flex;
      background: rgb(255, 255, 255);
      border-radius: 16px;
      place-items: center;
      justify-content: space-between;
      padding: 14px;
      width: 100%;
      position: relative;

      &:before {
        content: '';
        background-color: #153458;
        position: absolute;
        width: 8px;
        height: 8px;
        top: 20px;
        left: -24px;
        display: block;
        border-radius: 16px;
        border: 3px solid #ffffff;
      }

      &:after {
        content: '';
        background-color: #ffffff;
        position: absolute;
        width: 5px;
        height: 80px;
        top: 50%;
        left: -20px;
        display: block;
        z-index: -1;
        border-radius: 8px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      position: relative;

      &:before {
        content: '';
        background-color: #153458;
        position: absolute;
        width: 8px;
        height: 8px;
        top: 20px;
        left: -24px;
        display: block;
        border-radius: 16px;
        border: 3px solid #ffffff;
      }

      &:after {
        content: '';
        background-color: #ffffff;
        position: absolute;
        width: 5px;
        height: 80px;
        top: 50%;
        left: -20px;
        display: block;
        z-index: -1;
        border-radius: 8px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &-name {
        display: flex;
        align-items: flex-end;
        gap: 5px;
      }

      &-title {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #535763;
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &-gap {
        display: flex;
        gap: 21px;
      }

      &-label {
        display: flex;
        gap: 3px;
      }

      &-title {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #212121;
        width: 92px;
        margin-top: 3px;
      }

      &-value {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #535763;
        margin-top: 3px;
      }

      &-second {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #212121;
        margin-top: 3px;
      }
    }

    @media screen and (max-width: 1200px) {
      &-post {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        &:before {
          top: 36px;
        }

        &:after {
          height: 110px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding-left: 41px;
    }

    @media screen and (max-width: 552px) {
      gap: 10px;

      &-post {
        &-gap {
          gap: 5px;
          flex-direction: column;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        &:before {
          top: 10px;
        }

        &:after {
          top: 20px;
          height: 155px;
        }
      }
    }
  }
}

// My Treasure Inside Transaction
.trans {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-container {
    padding: 0 20px;
  }

  &-flex {
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-body {
    display: flex;
    flex-direction: column;
  }

  &-header {
    background-image: '/images/natureLogoBig.svg';

    &-title {
      font-weight: 600;
      font-size: 36px;
      color: #0e608b;
      padding: 2rem;

      &-nav {
        width: 100%;
        padding: 0rem 2rem;
        background-color: #ffffff;
      }

      &-btn {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #153458;
        border: unset;
        border-bottom: 2px solid #000000;
        background: unset;

        &-deac {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.03em;
          color: rgba(26, 43, 64, 0.68);
          background: #ffffff;
          border: unset;
          border-bottom: 2px solid #eaf2f6;
        }
      }
    }
  }

  &-inside {
    &-border {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #ffffff;
      border-radius: 16px;
      padding: 30px;
      gap: 6px;

      @media screen and (max-width: 1200px) {
        padding: 20px;
      }

      @media screen and (max-width: 992px) {
        padding: 20px 10px;
      }
    }

    &-post {
      display: flex;
      width: 100%;
      margin-bottom: 12px;

      &-inside {
        width: 100%;
        background-color: #ffffff;
        gap: 24px;
        display: flex;
      }

      &-btn {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #153458;
        border: unset;
        border-bottom: 2px solid #000000;
        background: unset;

        &-deac {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.03em;
          color: #143457;
          background: #ffffff;
          border: unset;
          border-bottom: 2px solid #143457;
        }
      }

      &-btns {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.03em;
        color: rgba(26, 43, 64, 0.68);
        background: #ffffff;
        border: unset;
        border-bottom: 2px solid #eaf2f6;
      }

      @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-end;

        &-btn {
          padding-bottom: 10px;

          &-deac {
            padding-bottom: 10px;
          }
        }

        &-btns {
          padding-bottom: 10px;
        }
      }

      @media screen and (max-width: 768px) {
        &-inside {
          overflow: auto;
          white-space: nowrap;
          max-width: 100vw;
          padding-bottom: 10px;
        }
      }
    }

    &-flex {
      gap: 10px;
      display: flex;
      justify-items: center;
      align-items: center;

      &-img {
        display: flex;
        width: 290px;
        height: 46px;
        background: #ffffff;
        border: 2px solid #282b32;
        border-radius: 6px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;
      }

      &-imgs {
        display: flex;
        width: 102px;
        height: 46px;
        background: #ffffff;
        border: 2px solid #282b32;
        border-radius: 6px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;
      }

      &-search {
        display: flex;
        width: 290px;
        height: 46px;
        background: #f5f5f5;
        border-radius: 32px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;

        @media screen and (max-width: 552px) {
          width: 60%;
        }

        input {
          border: unset;
          height: 100%;
          width: 100%;
          padding-left: 10px;
          margin-left: -10px;
          background-color: transparent;
          font-size: 14px;
        }
      }

      &-filter {
        display: flex;
        width: 92px;
        height: 46px;
        border-radius: 6px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0px 0 16px;
        margin-right: 10px;

        &-options {
          width: 92px;
          position: absolute;
          // background-color: transparent;
          border: none;
          text-align: right;

          background: initial;
          background-position: calc(100% - 14px) center;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          padding-right: 10px;
          outline: 0;
        }

        @media screen and (max-width: 552px) {
          width: 72px;
          padding-left: 0;
          margin-right: 20px;

          &-options {
            padding-right: 20px;
          }
        }
      }

      @media screen and (max-width: 1300px) {
        margin-top: 20px;
      }
    }
  }

  &-post {
    background: #e6e6e6;
    border-radius: 8px;
    width: 100%;
    // overflow: hidden;
    margin-bottom: 5px;

    &-combo {
      background: #f5f5f5;
      border-radius: 8px;
      padding: 6px;
      display: grid;
      grid-template-columns: 0.5fr 3fr;

      &-grid {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 15px;
        align-items: center;
      }

      &-logo {
        display: flex;
        width: 50px;
        height: 50px;
        background: rgba(255, 255, 255, 0.79);
        border-radius: 8px;
        justify-content: center;
        align-items: center;
      }
    }

    &-flex {
      display: flex;
      gap: 12px;
    }

    &-col {
      @media screen and (max-width: 1200px) {
        &:nth-child(2),
        &:nth-child(4) {
          display: none;
        }
      }
    }

    &-value {
      font-weight: 200;
      font-size: 12px;
      color: #373d50;
      margin-bottom: 10px;

      &-l {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #153458;
      }

      @media screen and (max-width: 1200px) {
        margin-top: 10px;

        &-val {
          font-size: 12px;
        }
      }
    }

    &-col {
      min-width: 132px;

      @media screen and (max-width: 1200px) {
        min-width: 122px;
      }

      @media screen and (max-width: 768px) {
        min-width: initial;
      }
    }
  }

  &-middle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding-right: 7px;

    &-val {
      font-weight: 200;
      font-size: 14px;
      color: #28c586;
    }
  }
}

// My Treasure new
.myt {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  width: 100%;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-flex {
    padding: 2rem;
  }

  &-b {
    width: 100%;
  }

  &-h {
    width: 100%;

    h4 {
      font-weight: 600;
      color: #0e608b;
      height: 62px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-bottom: 24px;
      font-size: 16px;

      @media screen and (max-width: 992px) {
        height: 52px;
      }

      @media screen and (max-width: 768px) {
        margin-left: 65px;
        padding-left: 15px;
      }
    }

    &-t {
      font-weight: 600;
      font-size: 36px;
      color: #0e608b;
    }

    &-h {
      gap: 10px;
      display: flex;
      justify-items: center;
      justify-content: flex-end;
      width: 100%;

      &-search {
        display: flex;
        width: 290px;
        height: 46px;
        background: #ffffff;
        border-radius: 32px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;

        @media screen and (max-width: 552px) {
          width: 90%;
        }

        &-input {
          border: unset;
          height: 100%;
          width: 100%;
          padding-left: 10px;
          margin-left: -10px;
          background-color: transparent;
          font-size: 14px;
          outline: none;
        }
      }

      &-filter {
        display: flex;
        width: 102px;
        height: 46px;
        border-radius: 6px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;

        &-input {
          border: unset;
          width: 58px;
          background: transparent;
        }

        @media screen and (max-width: 552px) {
          width: 92px;
        }
      }
    }
  }

  &-body {
    gap: 16px;
    display: grid;
    margin-top: 20px;
    justify-items: center;

    &-detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-border {
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      border-radius: 16px;
      display: grid;
      grid-template-columns: 0.5fr 6fr;
      padding: 15px;
      gap: 24px;

      &-image {
        height: 200px;
        width: 364.11px;
        border-radius: 16px;
        background-color: #f6f6f6;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: 552px) {
          width: 100%;
        }
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-t {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
        margin-top: 10px;
      }
    }

    &-r {
      display: flex;
      gap: 12px;

      &-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px 2px;
        gap: 12px;
        background: rgb(245, 245, 245);
        border-radius: 8px;
        justify-content: space-around;

        &-title {
          font-weight: 600;
          font-size: 13px;
          color: #0e608b;
        }

        &-val {
          font-weight: 500;
          font-size: 14px;
          text-align: right;
          color: #0e608b;
        }

        &-p {
          font-weight: 200;
          font-size: 15px;
          letter-spacing: 0.03em;
          color: #535763;
          line-height: 26px;
          margin-top: 10px;
        }

        &-grid {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: flex-end;
        }

        &-flex {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 10px;
        }

        &-title {
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: #212121;
        }

        &-val {
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: #535763;
        }

        &-r {
          display: flex;
          justify-content: flex-end;
          gap: 0.7rem;

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }

          &-btn {
            width: 140px;
            height: 46px;
            background-color: #153458;
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            position: relative;
            letter-spacing: 0.03em;
            color: #ffffff;
            cursor: pointer;

            &.deactive {
              background-color: #ff5758;
              border: 1px solid #ff5758;
              width: 110px;

              &.dark {
                background-color: #0c608b !important;
                border: 1px solid #153458 !important;
              }

              &.head-alert {
                width: 100% !important;
                text-align: initial;
                padding: 0 2.5rem;
              }

              &:hover,
              &:focus,
              &:active {
                cursor: unset;
                transition: 0s;
                background-color: #ff5758;
              }
            }

            &:hover,
            &:focus,
            &:active {
              transition: 0.3s;
              background-color: #3d5c81;
            }

            @media screen and (max-width: 552px) {
              width: 120px;
              height: 38px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    &-body {
      &-header {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        &-t {
          margin-bottom: 10px;
        }
      }

      &-border {
        gap: 12px;
      }

      &-r {
        &-box {
          &-p {
            font-size: 14px;
          }

          &-title,
          &-val {
            font-size: 13px;
          }
        }

        &-btn {
          width: 120px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &-body {
      &-r {
        &-box {
          &-grid {
            align-items: flex-end;
            grid-template-columns: 1fr;
            // justify-items: flex-start;
            margin-top: 10px;
          }

          &-r {
            margin-top: 10px;
          }
        }
      }
    }

    .myt-body-border-image {
      height: 100%;

      img {
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 768px) {
    &-body {
      &-border {
        grid-template-columns: 1fr;
      }
    }

    .myt-body-border-image {
      width: 100%;
      height: 211px;

      img {
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 552px) {
    &-body {
      &-border {
        > img {
          width: 100%;
        }
      }

      &-header {
        &-t {
          font-size: 20px;
        }
      }

      &-r {
        &-box {
          gap: 6px;

          &-title,
          &-val {
            font-size: 12px;
          }

          &-p {
            font-size: 13px;
          }

          &-r {
            width: 100%;

            &-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// My Wallet
.wall {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-flex {
    padding: 32px;
    display: grid !important;
    grid-template-columns: 1fr;
  }

  &-t {
    width: 100%;

    & > h4 {
      font-weight: 600;
      color: 153458;
      height: 62px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-bottom: 24px;
      font-size: 16px;

      @media screen and (max-width: 992px) {
        height: 52px;
      }

      @media screen and (max-width: 768px) {
        margin-left: 65px;
        padding-left: 15px;
      }
    }
  }

  &-body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 27px;
    width: inherit;

    @media screen and (max-width: 992px) {
      display: flex;
      grid-template-columns: 1fr;
      gap: 27px;
      width: 100%;
      flex-direction: column;
    }

    &-inside {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 23px;
    }

    &-post {
      background: rgb(255, 255, 255);
      border-radius: 16px;
      padding: 30px;
      min-height: 308px;

      @media screen and (max-width: 920px) {
        min-height: unset;
      }

      &-h {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 15px;
        padding: 10px;
        background-color: #f7fafc;
        border-radius: 8px;
        justify-content: space-between;

        &-t {
          font-weight: 200;
          font-size: 20px;
          color: #1a2b40;
        }

        &-val {
          font-weight: 600;
          font-size: 34px;
          color: #1a2b40;
        }
      }

      &-mid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        justify-items: center;

        &-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 9px;
          background-color: #f7fafc;
          border-radius: 8px;
          width: 100%;
          margin-bottom: 8px;
          justify-content: center;

          &-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
          }

          &-title {
            font-weight: 200;
            font-size: 20px;
            color: #373d50;
            margin-bottom: 10px;

            &-val {
              font-weight: 600;
              font-size: 24px;
              letter-spacing: 0.03em;
              color: #153458;
            }
          }
        }

        &-combo {
          display: grid;
          gap: 16px;
          align-items: center;
          width: 100%;
          grid-template-columns: 1fr 1fr;
        }

        &-btn {
          width: 100%;
          height: 54px;
          background-color: #153458;
          border-radius: 8px;
          font-weight: 600;
          font-size: 22px;
          position: relative;
          letter-spacing: 0.03em;
          color: #ffffff;
          cursor: pointer;

          &:hover,
          &:focus,
          &:active {
            transition: 0.3s;
            background-color: #3d5c81;
          }

          @media screen and (max-width: 1200px) {
            height: 44px;
            font-size: 18px;
          }

          @media screen and (max-width: 552px) {
            height: 38px;
            font-size: 14px;
          }
        }

        &-r {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &-r {
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;
          }

          &-btn {
            width: 100%;
            height: 54px;
            background-color: #153458;
            border-radius: 8px;
            font-weight: 600;
            font-size: 18px;
            position: relative;
            letter-spacing: 0.03em;
            color: #ffffff;
            cursor: pointer;
            margin-top: 1rem;

            &:hover,
            &:focus,
            &:active {
              transition: 0.3s;
              background-color: #3d5c81;
            }

            @media screen and (max-width: 1200px) {
              height: 44px;
              font-size: 18px;
            }

            @media screen and (max-width: 552px) {
              height: 38px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &-post {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    gap: 12px;
    min-height: 500px;
    margin-bottom: 1rem;

    &-h {
      display: flex;
      width: 100%;
      gap: 82px;
      margin-bottom: 12px;

      &-l {
        width: 100%;
        background-color: #ffffff;
        gap: 24px;
        display: flex;
        align-items: center;

        &-sort {
          position: relative;

          &-title-wrapper {
            position: relative;
            cursor: pointer;

            span {
              padding-right: 10px;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              letter-spacing: 0.03em;
              color: rgba(26, 43, 64, 0.68);
              background: #ffffff;
              border: unset;
              border-bottom: 2px solid #eaf2f6;
            }

            img {
              position: absolute;
              right: -3px;
              top: 45%;
              transform: translateY(-50%);
              transition: 0.3s ease-out;
              transform-origin: top;
              rotate: 0;
              opacity: 0.7;
            }
          }

          &:hover &-values {
            // &-values{
            visibility: visible;
            opacity: 100;
            // transform: translateY(0);
            // }
            // &-title-wrapper{
            // img{
            // rotate: 90deg;
            // }
          }

          &:hover &-title-wrapper img {
            rotate: -90deg;
          }

          &-values {
            // transform: translateY(-10px);
            transition: 0.3s ease-out;
            visibility: hidden;
            opacity: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;

            top: 120%;
            left: 0;
            right: 0;
            width: 100%;
            position: absolute;
            border-radius: 0px 0px 6px 6px;

            &::after {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              content: '';
              width: 100%;
              height: 1px;
              background: #fff;
              z-index: 1;
            }

            span.active {
              background: #e8f1f4;
              color: #153458;
            }

            span.false {
              background: #ffffff;
              color: #15345870;
            }

            span {
              line-height: 1;
              display: flex;
              align-items: center;
              background: #e8f1f4;
              transition: 0.1s ease all;
              cursor: pointer;

              &:hover {
                padding: 10px 14px;
              }

              font-size: 14px;
              color: #fff;
              padding: 10px 8px;
            }
          }
        }

        button {
          cursor: pointer;
        }

        &-all {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.03em;
          color: #153458;
          border: unset;
          border-bottom: 2px solid #000000;
          background: unset;
        }

        &-dep {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.03em;
          color: rgba(26, 43, 64, 0.68);
          background: #ffffff;
          border: unset;
          border-bottom: 2px solid #eaf2f6;
        }

        &-with {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.03em;
          color: rgba(26, 43, 64, 0.68);
          background: #ffffff;
          border: unset;
          border-bottom: 2px solid #eaf2f6;
        }
      }

      &-mid {
        gap: 10px;
        display: flex;
        justify-items: center;
        align-items: center;

        &-parent {
          display: flex;
          gap: 21px;
        }

        &-pen {
          font-weight: 500;
          font-size: 13px;
          text-align: right;
          letter-spacing: -0.01em;
          color: #52a49a;
        }

        &-succ {
          font-weight: 500;
          font-size: 13px;
          text-align: right;
          letter-spacing: -0.01em;
          color: #28c586;
        }

        &-fail {
          font-weight: 500;
          font-size: 13px;
          text-align: right;
          letter-spacing: 0.03em;
          color: #ea3943;
        }
      }

      &-end {
        display: flex;
      }

      &-search {
        display: flex;
        width: 290px;
        height: 46px;
        background: linear-gradient(
            0deg,
            rgba(233, 241, 245, 0.6),
            rgba(233, 241, 245, 0.6)
          ),
          #ffffff;
        border-radius: 32px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;

        @media screen and (max-width: 552px) {
          width: 90%;
        }

        &-input {
          border: unset;
          height: 100%;
          width: 100%;
          padding-left: 10px;
          margin-left: -10px;
          background-color: transparent;
          font-size: 14px;
        }
      }

      &-filter {
        display: flex;
        width: 92px;
        height: 46px;
        border-radius: 6px;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 0 0 0 16px;

        &-input {
          border: unset;
          width: 58px;
          background: transparent;
        }

        @media screen and (max-width: 552px) {
          width: 92px;
        }
      }
    }
  }

  &-row {
    background: #eaf2f6;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    padding-bottom: 12px;

    &.closed {
      padding-bottom: unset;
    }

    &-second {
      display: grid;
      grid-template-columns: 1fr;
      padding: 15px;
      gap: 5px;
      align-items: flex-start;

      &-des {
        font-weight: 600;
        font-size: 12px;
        color: #373d50;
        padding: 5px;
      }

      &-det {
        font-weight: 200;
        font-size: 12px;
        letter-spacing: 0.03em;
        color: #153458;
        padding: 5px;
        line-height: 18px;
      }

      &-parent {
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 1fr;
      }

      &-val {
        font-weight: 600;
        font-size: 12px;
        text-align: right;
        color: #153458;

        &-more {
          font-weight: 600;
          font-size: 10px;
          text-align: right;
          color: #153458;
          display: flex;
        }

        &-btn {
          background-color: unset;
          border: unset;
          font-weight: 500;
          font-size: 9px;
          text-decoration-line: underline;
          color: #000000;
        }
      }

      &-combo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 11px;
        height: 29px;
        background: #ffffff;
        border-radius: 6px;
      }

      &-pdf {
        display: grid;
        grid-template-columns: 1fr 1.3fr;
        align-items: center;
        gap: 18px;

        &-div {
          font-weight: 600;
          font-size: 14px;
          color: #fa0f00;
          height: 63px;
          left: 362px;
          top: 73px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border-radius: 8px;
          margin-top: 10px;
          cursor: pointer;
          padding: 0 3rem;

          span {
            padding: 5px;
            padding-top: 8px;
          }

          &:hover,
          &:before,
          &:after {
            transition: 0.3s;
            background: #f7fafc;
          }
        }
      }
    }

    &-des {
      font-weight: 600;
      font-size: 12px;
      color: #373d50;
      padding: 5px;
    }

    &-det {
      font-weight: 200;
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #153458;
      padding: 5px;
      line-height: 18px;
    }

    &-pdf {
      font-weight: 600;
      font-size: 14px;
      color: #fa0f00;
      width: 20%;
      height: 63px;
      left: 362px;
      top: 73px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border-radius: 8px;
      cursor: pointer;

      span {
        padding: 5px;
      }

      &:hover,
      &:before,
      &:after {
        transition: 0.3s;
        background: #f7fafc;
      }
    }

    &-f {
      min-height: 62px;
      background: #f7fafc;
      border-radius: 8px;
      padding: 6px;
      display: grid;
      grid-template-columns: 0.5fr 3fr;

      @media screen and (max-width: 992px) {
        height: unset;
      }

      &-grid {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 15px;
        align-items: center;
      }

      &-income {
        display: flex;
        width: 50px;
        height: 50px;
        background: #ffffff;
        border-radius: 8px;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 992px) {
          height: 100%;
        }
      }

      &-row {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 992px) {
          gap: 28px;
          overflow-x: scroll;
          width: fit-content;

          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        @media screen and (max-width: 460px) {
          gap: 28px;
          overflow-x: scroll;
          width: fit-content;

          display: grid;
          grid-template-columns: 1fr;
        }

        &-title {
          font-weight: 200;
          font-size: 12px;
          text-align: left;
          color: #373d50;
          margin-bottom: 3px;

          &-val {
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.03em;
            color: #153458;
            text-align: left;

            &.small {
              font-size: small;
            }
          }
        }
      }

      &-r {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        padding-right: 7px;

        &-suc {
          font-weight: 200;
          font-size: 14px;
          color: #28c586;
        }

        &-more {
          border: unset;
          background-color: unset;
        }
      }
    }

    &-sec {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 15px;
      gap: 9px;
      align-items: flex-start;

      &-title {
        font-weight: 200;
        font-size: 12px;
        color: #153458;
        overflow-wrap: anywhere;
      }

      &-flex {
        display: flex;
        align-items: center;
        gap: 9px;

        &-grid {
          display: grid;
          gap: 5px;
          width: 80%;
        }
      }

      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 11px;
        min-height: 29px;
        background: #ffffff;
        border-radius: 6px;
      }
    }
  }

  &-modal {
    justify-items: center;
    display: grid;
    width: 100%;

    &-back {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: linear-gradient(
        to bottom,
        #ffffff 0%,
        rgba(0, 0, 0, 0.04) 100%
      );
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    &-head {
      display: flex;
      width: 100%;
      padding: 10px;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.06));
      height: 50px;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }

    &-close {
      border: unset;
      background: unset;
      padding-right: 10px;
    }

    &-det {
      padding: 30px;
      background: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #153458;
      line-height: 22px;
    }

    &-bottom {
      display: flex;
      justify-content: flex-end;
      padding: 15px 30px;
      width: 100%;
      align-items: center;
      gap: 8px;

      &-cancel {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #000000;
        opacity: 0.6;
        padding: 12px;
        gap: 10px;
        width: 54px;
        height: 40px;
        background: unset;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
      }

      &-with {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
        padding: 12px;
        gap: 10px;
        width: 86px;
        height: 40px;
        background: #153458;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          transition: 0.3s;
          background-color: #3d5c81;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &-body {
      &-post {
        padding: 20px;

        &-h {
          &-t {
            font-size: 16px;
          }

          &-val {
            font-size: 24px;
          }
        }

        &-mid {
          &-bottom {
            padding: 10px;

            &-title {
              font-size: 16px;
              margin-top: 10px;

              &-val {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    &-post {
      padding: 20px;

      &-h {
        gap: 32px;

        &-search {
          width: 180px;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    &-body {
      &-post {
        padding: 10px;

        &-h {
          &-t {
            font-size: 14px;
          }

          &-val {
            font-size: 18px;
          }
        }

        &-mid {
          &-bottom {
            padding: 0;
            background-color: transparent;

            &-title {
              font-size: 14px;
              margin-top: 10px;

              &-val {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    &-post {
      &-h {
        flex-direction: column;
        gap: 12px;

        &-mid {
          justify-content: flex-end;
        }

        &-l {
          button {
            padding-bottom: 10px;
          }
        }
      }
    }

    &-row {
      &-f {
        &-row {
          &-title {
            font-size: 11px;

            &-val {
              font-size: 13px;
            }
          }
        }
      }

      &-sec {
        grid-template-columns: 1fr;
        padding: 10px;
        gap: 6px;
      }

      &-second {
        padding: 10px;

        &-parent {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &-body {
      &-inside {
        grid-template-columns: 1fr;
      }
    }

    &-row {
      &-sec {
        &-flex {
          flex-direction: column;
          align-items: flex-start;

          &-grid {
            width: 100%;
          }
        }
      }

      &-second {
        &-pdf {
          grid-template-columns: 1fr;
        }
      }

      &-pdf {
        width: 100px;
      }

      &-f {
        &-income {
          width: 50px;
          height: 100%;
        }

        &-grid {
          gap: 6px;
        }

        &-row {
          gap: 8px;
        }
      }
    }

    &-post {
      &-h {
        &-mid {
          align-items: flex-end;
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: 552px) {
    &-body {
      &-post {
        padding: 10px;

        &-h {
          margin-bottom: 10px;

          &-t {
            font-size: 13px;
          }

          &-val {
            font-size: 16px;
          }
        }

        &-mid {
          &-bottom {
            &-title {
              font-size: 12px;

              &-val {
                font-size: 14px;
              }
            }

            &-div {
              margin-bottom: 10px;
              gap: 5px;
            }
          }
        }
      }
    }

    &-post {
      padding: 10px;

      &-h {
        &-mid {
          margin-top: 10px;
        }

        &-end {
          margin-top: 5px;
        }

        &-filter,
        &-search {
          height: 36px;
        }
      }
    }

    &-row {
      &-sec {
        &-row {
          flex-direction: column;
          align-items: flex-start;
          height: initial;
          padding: 7px 11px;
        }
      }

      &-second {
        &-combo {
          flex-direction: column;
          align-items: flex-start;
          height: initial;
          padding: 7px 11px;
        }

        &-val {
          padding-top: 5px;

          &-more {
            padding-top: 5px;
            text-align: left;
          }
        }
      }
    }

    &-modal {
      &-back {
        width: 95%;
      }
    }
  }
}

.rotate {
  transform: rotate(180deg);
}

.item-col {
  min-width: 110px;
}

.blue-btn {
  width: 100%;
  height: 54px;
  background-color: #153458;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  position: relative;
  letter-spacing: 0.03em;
  color: #ffffff;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    transition: 0.3s;
    background-color: #3d5c81;
  }

  @media screen and (max-width: 1200px) {
    height: 44px;
    font-size: 18px;
  }

  @media screen and (max-width: 552px) {
    height: 38px;
    font-size: 14px;
  }
}

.warning-message-deposit {
  width: 100%;
  background: antiquewhite;
  border-radius: 8px;
  border: 1px solid #00000012;
  padding: 0.7rem 1.6rem;
  margin-bottom: 1.5rem;

  .link {
    cursor: pointer;
    color: #153558;

    &:hover {
      color: #268cf9;
    }
  }
}

.membership-fee-message {
  width: 100%;
  background-color: white;
  color: #0e608b;
  padding: 2rem;
  border-radius: 16px;
  font-weight: 600;
  margin-bottom: 1rem;

  .link-click-here {
    color: #1974b5;
    cursor: pointer;

    &:hover {
      color: #1974ff;
    }
  }
}

// Pending
.pen {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  &-container {
    background: #f5f5f5;
    justify-content: center;
    padding: 5px;
  }

  &-flex {
    padding: 0rem;
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 8px;

    // min-width: 493px;
    min-width: 450px;
    min-height: 220px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-body {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1rem;

    &-border {
      background: rgb(245, 245, 245);
      border-radius: 50px;
      padding: 19px 26px 20px 23px;
      margin-bottom: 32px;
    }

    &-title {
      font-weight: 600;
      font-size: 32px;
      align-items: center;
      text-align: center;
      color: #535763;
    }

    &-info {
      font-weight: 600;
      font-size: 16px;
      align-items: center;
      text-align: center;
      color: #535763;
    }

    &-flex {
      display: flex;
      align-items: center;
      flex-direction: row;

      img {
        width: 20px;
        margin-right: 5px;
      }
    }

    &-det {
      font-weight: 400;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: rgba(83, 87, 99, 0.7);
    }
  }

  @media (max-width: 768px) {
    &-body {
      &-flex {
        flex-direction: column;

        img {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
}

// Register
.register-bg {
  background: url(.//assets/videos/bg.jpg);
  background-size: cover;

  .videoTag {
    position: fixed;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }
}

.register {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  z-index: 1;
  position: relative;

  &-parent {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
    display: flex;
    align-items: center;
  }

  &-form {
    width: 592px;
    // height: 411px;
    padding: 26px 27px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16px;

    &-label {
      font-weight: 600;
      font-size: 16px;
      color: #000000;

      &.email {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-input {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      border: 0.0313rem solid #eeeeee;
      margin-bottom: 10px;
      height: 48px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-size: 17px;

      &::focus {
        outline: none;
      }

      &::placeholder {
        font-weight: 400;
        line-height: 24px;
        color: #615f5f;
      }

      &-last {
        padding: 15px;
        box-sizing: border-box;
        width: 100%;
        border: 0.0313rem solid #eeeeee;
        margin-bottom: 10px;
        height: 48px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-size: 17px;

        &::placeholder {
          font-weight: 400;
          line-height: 24px;
          color: #615f5f;
        }
      }
    }

    &-btn {
      cursor: pointer;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 133.4%;
      /* or 29px */

      span {
        background: linear-gradient(
          89.72deg,
          #2a68b2 -2.35%,
          #2dbdcd 51.93%,
          #2e94d0 106.08%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }

      width: 538px;
      height: 62px;
      left: 451px;
      top: 689px;

      background: #ffffff;
      border-radius: 8px;
      border: 0;
    }

    &-bottom {
      font-weight: 500;
      font-size: 17px;
      display: flex;
      align-items: center;
      color: #000000;
      justify-content: center;
      position: relative;

      &-btn {
        border: unset;
        background-color: unset;
        font-weight: 400;
        font-size: 17px;
        align-items: center;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }

  &-border {
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    border: 0.0313rem solid #eeeeee;
    margin-bottom: 10px;
    height: 48px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 17px;

    &.connector {
      background: #153458 !important;
      color: white;
      cursor: not-allowed;
    }

    &::placeholder {
      font-weight: 400;
      line-height: 24px;
      color: #615f5f;
    }
  }

  &-label {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  &-input {
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    border: 0.0313rem solid #eeeeee;
    margin-bottom: 10px;
    height: 48px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 17px;

    &::placeholder {
      font-weight: 400;
      line-height: 24px;
      color: #615f5f;
    }

    &s {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      border: 0.0313rem solid #eeeeee;
      margin-bottom: 10px;
      height: 48px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-size: 17px;

      &::placeholder {
        font-weight: 400;
        line-height: 24px;
        color: #615f5f;
      }
    }

    &-user {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      border: 0.0313rem solid #eeeeee;
      margin-bottom: 10px;
      height: 48px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-size: 17px;

      &::placeholder {
        font-weight: 400;
        line-height: 24px;
        color: #615f5f;
      }
    }

    &-input {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      border: 0.0313rem solid #eeeeee;
      // margin-bottom: 10px;
      height: 48px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-size: 17px;

      &::placeholder {
        font-weight: 400;
        line-height: 24px;
        color: #615f5f;
      }
    }
  }

  &-lbl {
    font-weight: 300;
    font-size: 14px;
    padding-top: 3px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.6);
  }

  &-btn {
    cursor: pointer;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 133.4%;
    /* or 29px */

    span {
      background: linear-gradient(
        89.72deg,
        #2a68b2 -2.35%,
        #2dbdcd 51.93%,
        #2e94d0 106.08%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }

    height: 62px;
    left: 451px;
    top: 689px;

    background: #ffffff;
    border-radius: 8px;
    border: 0;
  }

  &-bottom {
    font-weight: 500;
    font-size: 17px;
    display: flex;
    align-items: center;
    color: #000000;
    justify-content: center;
    position: relative;

    &-btn {
      border: unset;
      background-color: unset;
      font-weight: 600;
      font-size: 17px;
      align-items: center;
      text-decoration-line: underline;
      color: #000000;
      margin-left: 0.5rem;
    }
  }

  &-parent {
    margin: 1rem 0px 0.5rem;
  }
}

// Verify Your Identity Successful
.veri-succ-step {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  align-items: center;

  &-parent {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
  }

  &-num {
    width: 34px;
    height: 34px;
    left: 677px;
    top: 165px;
    background: #ffffff;
    border: 3px solid #000000;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
  }

  &-text {
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    color: #000000;
  }

  &-line {
    width: 100%;
    height: 6px;
    background: #ffffff;
    border-radius: 40px;
  }

  &-completed {
    width: 100%;
    height: 6px;
    background: #000000;
    border-radius: 40px;
    position: relative;
  }
}

// Verify Code
.steps {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  align-items: center;

  @media (max-width: 552px) {
    padding: 0px 20px;
  }

  .step {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    margin-top: 18px;

    .number {
      width: 34px;
      height: 34px;
      padding-top: 3px;
      background: #ffffff;
      border: 0.3rem solid #e2e2e2;
      color: #e2e2e2;
      border-radius: 20px;
      text-align: center;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }

    .title {
      margin-top: 0.5rem;
      font-size: 13px;
      color: #e2e2e2;
    }

    &.active {
      .number {
        width: 34px;
        height: 34px;
        padding-top: 3px;
        background: #ffffff;
        border: 0.5rem solid #153458;
        color: #153458;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      .title {
        margin-top: 0.5rem;
        font-size: 13px;
        color: #153458;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 10px;

    .step {
      .title {
        font-size: 9px;
        height: 24px;
      }

      .number {
        width: 24px;
        height: 24px;
        border: 0.1rem solid #e2e2e2;
        font-size: 14px;
        border-width: 1px;
      }
    }

    .step.active {
      .title {
        font-size: 9px;
        height: 24px;
      }

      .number {
        width: 24px;
        height: 24px;
        font-size: 14px;
        border-width: 1px;
      }
    }
  }
}

.between-bar {
  width: 100%;
  height: 6px;
  background: #ffffff;
  border-radius: 40px;

  .proccess {
    width: 25%;
    height: 6px;
    background: #153458;
    border-radius: 40px;
    position: relative;
    transition: 0.3s;

    &.complete {
      width: 100%;
    }
  }
}

.verify-code {
  // background-color: #F5F5F5;
  justify-content: center;

  &-body {
    padding: 32px !important;
    display: grid !important;
    width: 493px !important;
    justify-items: center !important;
  }

  &-box {
    background: #ffffff;
    border: 0.0938rem solid #eeeeee;
    border-radius: 16px !important;
    padding: 32px;
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: #000000;
    }

    .description {
      font-size: 14px;
      letter-spacing: 0.08em;
      color: rgba(0, 0, 0, 0.6);
    }

    .email-address {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.7);

      .value {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1px;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .inputs {
      width: 100%;
      // display: grid;
      gap: 16px;
      // grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 13px 0px 0px 0px;

      &.phone {
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

        .input input {
          width: 100%;
        }
      }

      .input {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;

        input {
          width: 100%;
          height: 64.07px;
          left: 641px;
          top: 422px;
          background: #ffffff;
          border: 1.2px solid rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          text-align: center;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 24px;
        }
      }
    }

    .button {
      cursor: pointer;
      width: 100%;
      background: #153458;
      border: 0.1875rem solid #153458;
      color: white;
      border-radius: 8px;
      margin-bottom: 27px;
      font-weight: 600;
      font-size: 20px;
      height: 58px;
    }

    .verify-note {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 14px 10px 13px;
      gap: 10px;
      margin-top: 1rem;
      width: 100%;

      background: #e8f1f4;
      border: 1.5px solid #eeeeee;
      border-radius: 10px;

      span {
        font-size: 11px;
        line-height: 137%;
        /* or 15px */

        letter-spacing: 0.08em;

        color: #373d50;
      }
    }
  }
}

.verify-code-box {
  .title {
    margin-bottom: 10px;
    font-size: 26px;
  }

  .description {
    letter-spacing: 0.04em;
    margin-bottom: 10px;
    line-height: 22px;
  }

  .email-address {
    letter-spacing: 0.5px;
  }

  .button {
    margin-bottom: 0;
  }

  @media (max-width: 772px) {
    .button {
      font-size: 16px;
      height: 48px;
    }
  }
}

.verify-code-body {
  @media (max-width: 620px) {
    padding: 0 !important;
    display: initial !important;
    width: 100% !important;

    .verify-code-box {
      margin-top: 15px;
      padding: 20px;

      .inputs {
        margin: 20px 0;
        justify-items: center;

        .input {
          input {
            width: 50px;
            height: 40px;
          }
        }
      }
    }
  }
}

// Verify Video
.verify-video {
  .steps {
    display: grid;
    gap: 0px;
    // grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr;
    align-items: center;
    margin-bottom: 2rem;

    .step {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      margin-top: 18px;

      .number {
        width: 34px;
        height: 34px;
        padding-top: 3px;
        background: #ffffff;
        border: 0.3rem solid #e2e2e2;
        color: #e2e2e2;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      .title {
        margin-top: 0.5rem;
        font-size: 13px;
        color: #e2e2e2;
        text-align: center;
      }

      &.active {
        .number {
          width: 34px;
          height: 34px;
          padding-top: 3px;
          background: #153458;
          border: 0.5rem solid #153458;
          color: #ffffff;
          border-radius: 20px;
          text-align: center;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          font-size: 16px;
        }

        .title {
          margin-top: 0.5rem;
          font-size: 13px;
          color: #153458;
        }
      }
    }
  }

  .between-bar {
    width: 100%;
    height: 6px;
    background: #f5f5f5;
    border-radius: 40px;

    .proccess {
      width: 25%;
      height: 6px;
      background: #153458;
      border-radius: 40px;
      position: relative;
      transition: 0.3s;

      &.complete {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .steps {
      padding: 0;
      margin-bottom: 10px;

      .step {
        .title {
          font-size: 9px;
          height: 24px;
        }

        .number {
          width: 24px;
          height: 24px;
          border: 0.1rem solid #e2e2e2;
          font-size: 14px;
          border-width: 1px;
        }
      }

      .step.active {
        .title {
          font-size: 9px;
          height: 24px;
        }

        .number {
          width: 24px;
          height: 24px;
          font-size: 14px;
          border-width: 1px;
        }
      }
    }
  }
}

// Profile
.pro {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-flex {
    background-color: #f5f5f5;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-t {
    width: 100%;

    & > h4 {
      font-weight: 600;
      color: #0e608b;
      height: 62px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-bottom: 24px;
      font-size: 16px;

      @media screen and (max-width: 992px) {
        height: 52px;
      }

      @media screen and (max-width: 768px) {
        margin-left: 65px;
        padding-left: 15px;
      }
    }
  }

  &-head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &-head {
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      min-height: 233px;

      @media screen and (max-width: 480px) {
        flex-direction: column;

        & > div > div {
          flex-direction: column;
        }
      }
    }

    &-flex {
      display: flex;
      gap: 29px;
      width: 100%;

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    &-row {
      height: 57px;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-id {
        font-weight: 200;
        font-size: 15px;
        color: #535763;
      }

      &-mail {
        font-weight: 200;
        font-size: 15px;
        color: #535763;
        margin-bottom: 10px;
      }

      &-num {
        font-weight: 200;
        font-size: 15px;
        color: #535763;
        margin-bottom: 10px;
      }

      &-more {
        font-weight: 200;
        font-size: 12px;
        text-decoration-line: underline;
        color: #153458;
        border: unset;
        background-color: unset;
        cursor: pointer;
      }

      &-combo {
        display: flex;
        gap: 14px;

        @media screen and (max-width: 480px) {
          flex-direction: column;
          align-items: center;
        }
      }

      &-edit {
        font-weight: 500;
        font-size: 15px;
        text-align: right;
        text-decoration-line: underline;
        color: #153458;

        @media screen and (max-width: 480px) {
          text-align: center;
        }
      }
    }
  }

  &-t {
    font-weight: 600;
    font-size: 36px;
    color: #0e608b;
  }

  &-l {
    left: 52px;
    top: 734.91px;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    display: grid;
    gap: 12px;

    &-title {
      font-weight: 600;
      font-size: 20px;
      color: #373d50;
      display: flex;
      align-items: center;
    }

    &-input {
      height: 48px;
      background: #f5f5f5;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      padding-left: 16px;
      border: 1px solid #dddddd;
    }
  }

  &-r {
    display: grid;
    gap: 16px;
    height: 100%;

    &-post {
      background: rgb(255, 255, 255);
      border-radius: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px;

      &-row {
        display: grid;
        align-content: space-between;
        height: 100%;
      }

      &-title {
        font-weight: 600;
        font-size: 20px;
        color: #0e608b;
      }

      &-parent {
        display: grid;
        gap: 8px;
        margin-top: 20px;

        &-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 7px 11px;
          gap: 11px;
          width: 160px;
          background: rgba(234, 242, 246, 0.4);
          border-radius: 8px;
          justify-content: space-around;

          &-title {
            font-weight: 600;
            font-size: 14px;
            color: #0e608b;
          }

          &-value {
            font-weight: 500;
            font-size: 14px;
            text-align: right;
            color: #0e608b;
          }
        }
      }

      &-combo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-money {
        font-weight: 600;
        font-size: 72px;
        color: #0e608b;
      }
    }
  }

  &-post {
    left: 570px;
    top: 513.91px;
    background: #ffffff;
    border-radius: 16px;
    display: grid;
    gap: 24px;
    padding: 30px;
    height: 100%;

    &-title {
      font-weight: 600;
      font-size: 20px;
      color: #373d50;
    }

    &-gird {
      display: grid;
      align-items: flex-end;
      gap: 4px;
    }

    &-num {
      padding: 4px;
      box-sizing: border-box;
      height: 48px;
      background: #f5f5f5;
      border: 1px solid #dddddd;
      border-radius: 8px;
    }

    &-option {
      background: rgba(255, 255, 255, 0.79);
      border: 1px solid #ffffff;
      border-radius: 8px;
      height: 100%;
      font-weight: 200;
      font-size: 16px;
      width: 30%;
      color: #153458;
      border: 1px solid #153458;
      padding: 4px 8px 4px 10px;

      background: url("data:image/svg+xml,<svg t='1675155272854' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'><path d='M760.454652 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227064 0L511.706311 596.712357 308.185035 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227065 0-12.489452 12.489452-12.489452 32.737612 0 45.227064L489.093291 664.553465c12.489452 12.489452 32.737612 12.489452 45.227064 0L760.454652 438.418144c12.488429-12.489452 12.488429-32.738635 0-45.227064z' p-id='1287'></path></svg>")
        no-repeat;
      background-position: calc(110% - 20px) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-right: 30px !important;
    }

    &-input {
      height: 35px;
      border: unset;
      background-color: unset;
      width: 70%;
      padding-left: 15px;
      font-size: 16px;
    }

    &-tele {
      padding: 15px;
      width: 100%;
      height: 48px;
      background: #f5f5f5;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      padding-left: 16px;
      border: 1px solid #dddddd;
    }
  }

  @media screen and (max-width: 1200px) {
    &-head {
      &-head {
        padding: 20px;
      }
    }

    &-l {
      padding: 20px;

      &-input {
        height: 44px;
        font-size: 14px;
      }
    }

    &-r {
      &-post {
        padding: 20px;

        &-money {
          font-size: 52px;
        }
      }
    }

    &-post {
      &-num {
        height: 44px;
        font-size: 14px;
      }

      &-tele {
        height: 44px;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &-head {
      gap: 14px;
    }

    &-post {
      &-gird {
        gap: 12px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &-head {
      &-row {
        height: initial;
        margin-bottom: 10px;

        &-title {
          font-size: 20px;
        }

        &-id,
        &-mail,
        &-num,
        &-more {
          font-size: 14px;

          @media screen and (max-width: 480px) {
            text-align: center;
          }
        }

        &-mail,
        &-num {
          margin-bottom: 2px;
        }
      }

      &-flex {
        &-img {
          width: 72px;
        }
      }
    }
  }

  @media screen and (max-width: 552px) {
    &-head {
      gap: 14px;

      &-head {
        flex-direction: column;
        padding: 15px;
      }

      &-row {
        &-combo {
          margin-top: 20px;
        }
      }
    }

    &-l {
      padding: 15px;

      &-input {
        height: 38px;
      }
    }

    &-r {
      &-post {
        padding: 15px;
        grid-template-columns: 1fr;

        &-parent {
          &-row {
            width: 100%;

            &-title,
            &-value {
              font-size: 13px;
            }
          }
        }

        &-money {
          font-size: 42px;
        }

        &-combo {
          margin-top: 30px;
          margin-bottom: 20px;
        }
      }
    }

    &-post {
      padding: 15px;

      &-option {
        font-size: 12px;
        width: 40%;
      }

      &-input {
        height: 28px;
        width: 60%;
        font-size: 14px;
      }

      &-num {
        height: 38px;
        display: flex;
      }

      &-tele {
        height: 38px;
      }
    }
  }
}

.setting-post {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.pro-flex {
  background-color: initial;
}

.pro-l-input {
  background: #fafafa;
}

.pro-head-row-title {
  height: initial;

  @media screen and (max-width: 480px) {
    text-align: center;
  }
}

.pro-head-row-num {
  height: initial;

  @media screen and (max-width: 480px) {
    text-align: center;
    margin: auto;
  }
}

.pro-r-post-parent-row {
  padding: 10px 5px 8px;
  height: initial;
}

.country-flag {
  height: 26px;
  width: 42px;
  object-fit: fill;
  border-radius: 4px;
}

.profile-avatar {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 180px;
  height: 180px;
}

.profile-img {
  width: 180px;
  height: 180.11px;
  background: #e8f1f4;
  border-radius: 50%;
  border: 1rem solid #353e5112;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img-file {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.profile-edit-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: #1534585f;
  color: white;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background: #15345825;
  }
}

.profile-edit-border {
  border: 2px solid #337dd3;
}

.profile-edit-button {
  align-items: center;
  background: #153458;
  color: white;
  padding: 2px 2.4rem 0;
  border-radius: 1rem;
  margin: 0.6rem;
  cursor: pointer;

  &.right {
    position: absolute;
    right: 1px;
    top: 0;
    bottom: 0;
    display: flex;
  }

  &.update {
    min-width: 76.36px;
    min-height: 36px;
  }

  &.left {
    position: absolute;
    right: 89px;
    top: 0;
    bottom: 0;
    display: flex;
  }

  &.blue {
    background: #337dd3;
  }

  &:hover {
    background: #337dd3;
  }
}

// Setting
.sett {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 552px) {
    padding: 10px;
  }

  &-flex {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-t {
    width: 100%;

    & > h4 {
      font-weight: 600;
      color: #0e608b;
      height: 62px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-bottom: 24px;
      font-size: 16px;

      @media screen and (max-width: 992px) {
        height: 52px;
      }

      @media screen and (max-width: 768px) {
        margin-left: 65px;
        padding-left: 15px;
      }
    }
  }

  &-head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &-head {
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
    }

    &-flex {
      display: flex;
      gap: 29px;
    }

    &-row {
      height: 57px;

      &-title {
        font-weight: 600;
        font-size: 24px;
        color: #0e608b;
      }

      &-id {
        font-weight: 200;
        font-size: 15px;
        color: #535763;
      }

      &-mail {
        font-weight: 200;
        font-size: 15px;
        color: #535763;
        margin-bottom: 10px;
      }

      &-num {
        font-weight: 200;
        font-size: 15px;
        color: #535763;
        margin-bottom: 10px;
      }

      &-more {
        font-weight: 200;
        font-size: 12px;
        text-decoration-line: underline;
        color: #153458;
        border: unset;
        background-color: unset;
        cursor: pointer;
      }

      &-combo {
        display: flex;
        gap: 14px;
        padding-right: 22px;
      }

      &-edit {
        font-weight: 500;
        font-size: 15px;
        text-align: right;
        text-decoration-line: underline;
        color: #153458;
      }
    }
  }

  &-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    gap: 6px;
    padding: 30px;

    &-title {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
      margin-bottom: 20px;
    }

    &-flex {
      display: flex;
      flex-direction: column;
      gap: 7px;
      width: 100%;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px 6px;
      width: 100%;
      background: #eaf2f6;
      border-radius: 8px;

      &-title {
        font-weight: 200;
        font-size: 14px;
        text-align: right;
        color: #373d50;
      }

      &-val {
        font-weight: 600;
        font-size: 14px;
      }
    }

    &-btn {
      width: 100%;
      height: 46px;
      background-color: #153458;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      position: relative;
      letter-spacing: 0.03em;
      color: #ffffff;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        transition: 0.3s;
        background-color: #3d5c81;
      }

      @media screen and (max-width: 552px) {
        height: 38px;
        font-size: 14px;
      }
    }

    &-parent {
      display: grid;
      gap: 9px;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-top: 30px;
    }
  }

  &-last {
    background: #e8f1f5;
    border: 2px dashed rgb(83 87 99 / 20%);
    border-radius: 16px;
    padding: 30px;
    cursor: pointer;

    &:hover,
    &:focus {
      transition: 0.3s;
      border: 2px dashed rgb(83 87 99);
    }

    &-border {
      width: 100%;
      background: rgba(255, 255, 255, 0.81);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      height: 100%;
      justify-content: space-around;
    }

    &-parent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-btn {
      border: unset;
      background-color: unset;

      img {
        width: 120px;
      }
    }

    &-p {
      font-weight: 200;
      font-size: 16px;
      color: #535763;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    &-head {
      &-head {
        padding: 20px;
      }
    }

    &-row {
      padding: 20px;
    }

    &-last {
      padding: 20px;

      &-btn {
        img {
          width: 80px;
        }
      }

      &-parent {
        padding: 20px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &-head {
      gap: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    &-head {
      &-row {
        height: initial;
        margin-bottom: 10px;

        &-title {
          font-size: 20px;
        }

        &-id,
        &-mail,
        &-num,
        &-more {
          font-size: 14px;
        }

        &-mail,
        &-num {
          margin-bottom: 2px;
        }
      }

      &-flex {
        &-img {
          width: 72px;
        }
      }
    }
  }

  @media screen and (max-width: 552px) {
    &-head {
      gap: 14px;

      &-head {
        flex-direction: column;
        padding: 15px;
      }

      &-row {
        &-combo {
          margin-top: 20px;
        }
      }
    }

    &-row {
      padding: 15px;

      &-title {
        font-size: 20px;
      }

      img {
        width: 100px;
      }

      &-row {
        flex-direction: column;
        align-items: flex-start;

        &-title,
        &-val {
          font-size: 13px;
        }
      }
    }

    &-last {
      padding: 10px;

      &-btn {
        img {
          width: 60px;
        }
      }

      &-p {
        font-size: 14px;
      }

      &-parent {
        padding: 5px;
      }
    }
  }
}

.setting-post {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
  margin-bottom: 1rem;

  @media screen and (max-width: 992px) {
    gap: 14px;
  }
}

// Setup Account
.set {
  display: grid;
  grid-template-columns: 0.5fr 5fr;

  &.extra {
    align-items: center;
    height: 100vh;
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: initial;
  }

  &-container {
    display: grid !important;
    justify-items: center;
  }

  &-flex {
    padding: 32px;
    display: grid !important;
    width: 493px;
    justify-items: center;
  }

  &-step {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
    align-items: center;

    &-grid {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
    }

    &-num {
      width: 34px;
      height: 34px;
      left: 677px;
      top: 165px;
      background: #ffffff;
      border: 0.1875rem solid #000000;
      border-radius: 20px;
      text-align: center;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;

      &-deac {
        width: 34px;
        height: 34px;
        left: 677px;
        top: 165px;
        background: #ffffff;
        border: 0.1875rem solid #e2e2e2;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;

        &-p {
          font-weight: 600;
          font-size: 12px;
          text-align: right;
          color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    &-text {
      font-weight: 600;
      font-size: 12px;
      text-align: right;
      color: #000000;

      &-deac {
        font-weight: 600;
        font-size: 12px;
        text-align: right;
        color: rgba(0, 0, 0, 0.1);
      }
    }

    &-line {
      width: 100%;
      height: 6px;
      background: #ffffff;
      border-radius: 40px;

      &-zero {
        width: 0%;
        height: 6px;
        background: #000000;
        border-radius: 40px;
        position: relative;
      }

      &-half {
        width: 25%;
        height: 6px;
        background: #000000;
        border-radius: 40px;
        position: relative;
      }
    }
  }

  &-fin {
    justify-content: center;
  }

  &-border {
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 8px;
    padding: 30px;
    display: grid;
    width: 493px;
    justify-items: center;

    @media (max-width: 768px) {
      width: 100%;
      padding: 20px;
    }
  }

  &-img {
    margin-bottom: 20.29px;
  }

  &-title {
    color: #153458;
    margin-bottom: 20.29px;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &-info {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #153458;
    margin-bottom: 32px;
  }

  &-post {
    width: 417px;
    background: #f5f5f5;
    border: 1.5px dashed #535763;
    border-radius: 8px;
    padding: 23px 21px 25px 19px;

    &-border {
      width: 100%;
      background: rgba(255, 255, 255, 0.81);
      border: 1px dashed rgba(83, 87, 99, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
      height: 347px;
    }

    &-btn {
      border: unset;
      background-color: unset;
    }

    &-p {
      font-weight: 600;
      font-size: 18px;
      color: #535763;
    }
  }

  &-wall {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &-border {
      width: 421px;
      height: 250px;
      background: #fafafa;
      border-radius: 8px;
      border: 3px solid #535763;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 17px 0px 19px;
    }

    &-p {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
    }

    &-flex {
      gap: 7px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 7px 11px;
      width: 384px;
      height: 32px;
      background: #ffffff;
      border-radius: 9px;
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #373d50;
    }

    &-borders {
      background: #f5f5f5;
      border: 1.5px dashed #535763;
      border-radius: 8px;
      padding: 9px 11px 10px 11px;
    }

    &-boxs {
      display: flex;
      background: rgba(255, 255, 255, 0.81);
      border: 1px dashed rgba(83, 87, 99, 0.25);
      border-radius: 8px;
      padding-right: 97px;
      align-items: center;
      gap: 7px;
    }

    &-btn {
      border: unset;
      background-color: unset;
      width: 60px;

      &-p {
        font-weight: 600;
        font-size: 14px;
        text-align: right;
        color: #373d50;
      }
    }

    &-next {
      background: #eeeeee;
      border: 3px solid #373d50;
      border-radius: 8px;
      margin-bottom: 27px;
      font-weight: 600;
      font-size: 20px;
      padding: 12px 32px;
      color: #373d50;
    }
  }

  &-mem {
    width: 419px;
    height: 165px;
    left: 0;
    top: 278px;
    border: solid #535763 3px;
    background: #fafafa;
    border-radius: 8px;
    margin-bottom: 32px;

    &-mid {
      display: grid;
      grid-template-columns: 2.5fr 1fr;
      align-items: center;
      padding-left: 1.5rem;

      &-h {
        display: flex;
        align-items: center;

        &-ft {
          font-weight: 600;
          font-size: 20px;
          color: #0e608b;
        }

        &-st {
          font-size: 20px;
          color: #0e608b;
        }
      }

      &-inside {
        display: flex;
        align-items: center;
        gap: 4px;

        &-grid {
          display: grid;
          gap: 5px;
        }

        &-row {
          display: flex;
          background: #f5f5f5;
          border-radius: 9px;
          height: 24px;
          align-items: center;
          justify-content: space-between;
          width: 165px;
          padding-left: 11px;

          &-title {
            font-weight: 600;
            font-size: 14px;
            color: #0e608b;
          }

          &-Value {
            font-weight: 500;
            font-size: 14px;
            text-align: right;
            color: #0e608b;
          }
        }

        &-r {
          display: flex;
          background: rgb(255, 255, 255);
          border: 3.5px solid #153458;
          border-radius: 8px;
          margin: 25px 16px 22px 0px;
          align-items: center;
          justify-content: center;
          font-family: Chakra Petch;
          font-style: normal;
          font-weight: 400;
          font-size: 46px;
          color: rgb(55, 61, 80);
          padding: 21px 16px 19px 16px;
        }
      }

      &-btn {
        cursor: pointer;
        width: 100%;
        background: #153458;
        border: 0.1875rem solid #153458;
        color: white;
        border-radius: 8px;
        font-weight: 600;
        font-size: 20px;
        height: 58px;

        @media (max-width: 768px) {
          width: 100%;
          font-size: 16px;
          height: 48px;
        }
      }
    }
  }
}

.blur-bg {
  background: rgb(0 0 0 / 65%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 199;
}

.mod {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  align-items: flex-start;
  margin-top: 3rem;
  justify-content: center;
  top: 7rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    max-width: 570px;
    // box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    min-width: 400px;
    z-index: 200;

    @media (max-width: 500px) {
      min-width: 320px;
    }
  }

  &-box {
    display: flex;
    width: 100%;
    padding: 30px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    // filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.06));
    min-height: 50px;
    align-items: center;

    &-p {
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }

    &-btn {
      border: unset;
      background: unset;
      cursor: pointer;
      font-size: 2.3rem;
    }
  }

  &-inside {
    padding: 30px 0px;
    width: 90%;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-flex {
      padding: 0rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &-btn {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #535763;
      background: #ffffff;
      border: unset;
      border-bottom: 2px solid #f5f5f5;
      padding: 14px 24px 15px 24px;

      &-orange {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #f7931a;
        background: #ffffff;
        border: unset;
        border-bottom: 2px solid #f7931a;
        padding: 14px 24px 15px 24px;
      }

      &-blue {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1976d2;
        background: #ffffff;
        border: unset;
        border-bottom: 2px solid #1976d2;
        padding: 14px 24px 15px 24px;
      }

      &-bos {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #0e608b;
        background: #ffffff;
        border: unset;
        border-bottom: 2px solid #0e608b;
        padding: 14px 24px 15px 24px;
      }
    }

    &-br {
      // width: 404px;
      height: 2px;
      margin-bottom: 10px;
      background: #f5f5f5;
      border-radius: 40px;
      margin-top: 0px;
    }

    &-input {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      background: #f5f5f5;
      border: 0.5px solid #eeeeee;
      border-radius: 8px;
      margin-bottom: 0.8rem;
    }

    &-body {
      width: 100%;

      &-label {
        color: #7e7e7e;
        font-weight: 600;
        margin-bottom: 10px;
        display: block;

        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }

        @media screen and (max-width: 552px) {
          font-size: 13px;
        }
      }

      &-input {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        padding-left: 16px;
        border-radius: 8px;
        border: 1px solid #153458;
        margin-bottom: 10px;

        @media screen and (max-width: 1200px) {
          height: 44px;
          font-size: 16px;
        }

        @media screen and (max-width: 552px) {
          height: 38px;
          font-size: 14px;
        }
      }

      &-select {
        font-weight: 600;
        font-size: 14px;
        color: #153458;
        border-radius: 8px;
        border: 1px solid #153458;
        padding: 0 20px;
        width: 100%;
        height: 48px;

        background: url("data:image/svg+xml,<svg t='1675155272854' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'><path d='M760.454652 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227064 0L511.706311 596.712357 308.185035 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227065 0-12.489452 12.489452-12.489452 32.737612 0 45.227064L489.093291 664.553465c12.489452 12.489452 32.737612 12.489452 45.227064 0L760.454652 438.418144c12.488429-12.489452 12.488429-32.738635 0-45.227064z' p-id='1287'></path></svg>")
          no-repeat;
        background-position: calc(100% - 14px) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 30px !important;
        box-shadow: 0 0 30px #5d5d5d24;
      }

      &-btn {
        width: 120px;
        height: 48px;
        background-color: #153458;
        border-radius: 8px;
        font-weight: 600;
        font-size: 18px;
        position: relative;
        letter-spacing: 0.03em;
        color: #ffffff;
        cursor: pointer;
        margin-top: 30px;

        &:hover,
        &:focus,
        &:active {
          transition: 0.3s;
          background-color: #3d5c81;
        }

        @media screen and (max-width: 1200px) {
          height: 44px;
          font-size: 16px;
        }

        @media screen and (max-width: 552px) {
          height: 38px;
          font-size: 14px;
          margin-top: 15px;
        }
      }
    }
  }

  &-img {
    width: 180px;
    height: 180px;

    &-border {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 355px;

      &-parent {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-bottom {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      flex-direction: column;
      gap: 1rem;

      &-btn {
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background: unset;
        width: 65px;
        height: 30px;

        &:hover {
          background: #1976d2;
          color: white;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    width: 94%;
    align-items: center;

    &-btn {
      cursor: pointer;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: #ffffff;
      padding: 12px;
      gap: 10px;
      width: 69px;
      height: 40px;
      background: #358eea;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
    }

    &-modal-btn {
      width: 100%;
      height: 54px;
      background-color: #153458;
      border-radius: 8px;
      font-weight: 600;
      font-size: 22px;
      position: relative;
      letter-spacing: 0.03em;
      color: #ffffff;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        transition: 0.3s;
        background-color: #3d5c81;
      }

      @media screen and (max-width: 1200px) {
        height: 44px;
        font-size: 18px;
      }

      @media screen and (max-width: 552px) {
        height: 38px;
        font-size: 14px;
      }
    }
  }

  &-upload {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;

    &-label {
      font-weight: 600;
      font-size: 16px;
      color: #676767;
    }

    &-border {
      padding: 15px;
      box-sizing: border-box;
      height: 94px;
      background: #f8f8ff;
      border: 1px dashed rgba(56, 78, 183, 0.3);
      border-radius: 8px;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: flex-start;
    }

    &-flex {
      display: flex;
      justify-content: center;
    }

    &-browse {
      border: unset;
      background-color: unset;
    }

    &-info {
      font-weight: 400;
      font-size: 12px;
      align-items: center;
      text-align: center;
      color: #676767;
    }

    &-uploaded {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      height: 36px;
      background: #ffffff;
      border: 0.5px solid #e3e3e3;
      border-radius: 4px;

      &-flex {
        display: flex;
        justify-content: space-between;
      }

      &-p {
        font-weight: 400;
        font-size: 12px;
        align-items: center;
        text-align: center;
        color: #0f0f0f;
        padding-left: 11.51px;
      }

      &-close {
        border: unset;
        background-color: unset;
      }

      &-loading {
        height: 3px;
        background: rgb(72, 62, 168);
        border-radius: 10px;
        margin-top: -8px;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 10px;
    z-index: 200;
    margin-top: 7rem;

    &-border {
      min-width: 100%;
    }

    &-inside {
      padding-top: 10px;
    }
  }

  @media (max-width: 768px) {
    &-border {
      min-width: 100%;
    }
  }
}

.pend-container {
  background: #f5f5f5;
  width: 493px !important;
}

.pend-flex {
  background: rgb(255, 255, 255);
  padding: 66px 37px 40px !important;
  width: 493px !important;
  border: 1.5px solid rgb(238, 238, 238);
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100% !important;
    padding: 0 !important;
  }
}

.pend-body {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 15px;

  &- {
    background: rgb(245, 245, 245);
    border-radius: 50px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
}

.pend-head {
  background: rgb(245, 245, 245);
  border-radius: 50px;
}

.pend-title {
  font-weight: 600;
  font-size: 32px;
  align-items: center;
  text-align: center;
  color: #535763;
  margin-top: 10px;
}

.pend-p {
  font-weight: 200;
  font-size: 14px;
  align-items: center;
  text-align: center;
  color: #535763;
  padding: 10px 0 15px;

  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.pend-info {
  display: flex;
  align-items: flex-start;
  align-items: center;

  img {
    margin-right: 5px;
  }

  &-p {
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: rgba(83, 87, 99, 0.7);
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      width: 20px;
      margin-bottom: 5px;
    }
  }
}

.pend-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 11px;
  width: 418.5px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 9px;
  margin-bottom: 32px;

  &-title {
    font-weight: 600;
    font-size: 14px;
    color: #0e608b;
  }

  &-value {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #0e608b;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.pend-btn {
  cursor: pointer;
  width: 100%;
  background: #153458;
  border-radius: 8px;
  border: 0;
  border-radius: 8px;
  height: 58px;
  font-weight: 600;
  font-size: 20px;
  line-height: 133.4%;
  color: #ffffff;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }
}

.pendin-flex {
  background: rgb(255, 255, 255);
  width: 493px;
  border: 1.5px solid rgb(238, 238, 238);
  border-radius: 8px;
  height: 354px;
  padding: 64px 84px 36px;
}

.modal-description {
  font-size: 16px;
  margin-bottom: 2rem;
  font-weight: normal;
}

// Explorer
.explorer {
  &-body {
    padding: 20px 0;
    margin-bottom: 1rem;
  }

  &-content {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    display: grid;
    gap: 32px;
    padding: 30px;

    img {
      // width: 100% !important;
      // height: fit-content !important;
      // object-fit: scale-down;
      max-height: -webkit-fit-content !important;
      height: auto;
      object-fit: cover;
      max-width: 100%;
    }

    h4 {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
    }

    p {
      font-weight: 200;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #535763;
      line-height: 28px;
    }
  }
}

// Conditions
.conditions {
  &-body {
    padding: 20px 0;
  }

  &-content {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    display: grid;
    gap: 32px;
    padding: 30px;

    h4 {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
    }

    p {
      font-weight: 200;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #535763;
      line-height: 28px;
    }
  }
}

// My Questions
.my-treasure-inside {
  display: grid;
  grid-template-columns: 1fr;

  .inside-tab {
    background-color: #ffffff;
    padding: 26px 24px 24px;
    gap: 108;
    display: none;
  }

  .inside-content {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    align-items: center;
  }

  .inside-logo {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #1a1b2d;
  }

  .inside-description {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.01em;
    color: #535763;
  }

  .inside-my-treasure-items {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17;
  }

  .inside-my-treasure-item {
    display: flex;
    gap: 23;
    width: 100%;
    justify-content: center;
    flex-direction: row;
  }

  .inside-my-treasure-item {
    display: flex;
    gap: 23;
    width: 100%;
    justify-content: center;
    flex-direction: row;
  }

  .inside-chart-accumulative-profit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30;
    width: 100%;
  }

  .inside-info-box {
    display: flex;
    align-items: center;
    gap: 10;
    margin-bottom: 15px;
  }

  .inside-tx-chart {
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 16px;
    padding: 40px 36px 22px 39px;
    margin-top: 1.4rem;

    &-title {
      font-weight: 600;
      font-size: 24px;
      color: #0e608b;
      margin-bottom: 13px;
    }
  }
}

// User Info Wrapper
.user-info {
  @media screen and (max-width: 768px) {
    &-detail div {
      display: none !important;
      margin-right: 1rem;
    }
  }

  &-detail {
    display: flex;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  * {
    line-height: 1;
    font-size: 1.45rem;
    color: #0e608b;
    text-transform: capitalize;
  }

  .line {
    opacity: 0.3;
    background: #000;
    width: 100%;
    height: 1px;
    margin: 1px 0 6px;
  }

  img {
    border-radius: 100%;
    width: 5rem;
  }
}

// Header
.header {
  font-weight: 600;
  width: 100%;
  color: #0e608b;
  height: 70px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 24px;
  font-size: 16px;

  h2 {
    min-width: fit-content;
    font-weight: 600;
    color: #0e608b;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}

// Notification
.notification-wrapper {
  position: relative;
  height: 100%;
  z-index: 100;

  &:hover .notification-container {
    visibility: visible;
    opacity: 100%;
    transform: translateY(0);
  }

  .notification-container {
    box-shadow: 0px 6px 30px 2px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 1.6rem;
    max-width: 425px;
    padding-top: 0px;
    position: absolute;
    opacity: 0%;
    transition: all 0.2s linear;
    transform: translateY(-20px);
    visibility: hidden;
    overflow: visible;
    right: -24px;
    width: 85vw;
    top: 110%;

    &::after {
      content: '';
      position: absolute;
      right: 30px;
      rotate: 45deg;
      top: -6px;
      z-index: 2;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 14px 0 0;
      border-color: rgb(255 255 255 / 50%) transparent transparent transparent;
      box-shadow: 0px 6px 30px 2px rgb(0 0 0 / 10%);
      background: #fff;
      border-radius: 0rem;
    }
  }

  .notification {
    max-height: 545px;
    overflow-y: auto;

    padding: 1.4rem;

    // left: 0;
    & > span {
      display: flex;
      justify-content: center;
      font-size: 1.4rem;
      width: fit-content;
      margin: 0 auto;
      cursor: pointer;
      transition: 0.2s all;
      padding: 0.8rem 1.5rem;
      border-radius: 0.5rem;

      &:hover {
        background: rgba($color: #3b73af, $alpha: 0.15);
        color: #3b73af;
      }
    }

    &-header {
      color: #153458;
      font-size: 1.6rem;
      display: flex;
      justify-content: space-between;

      & div {
        display: flex;
        column-gap: 1.1rem;

        span {
          &:hover {
            color: #3b73af;
            transform: translateY(-4px);
          }

          cursor: pointer;
          transition: all 0.2s;
          font-size: 1.4rem;
        }
      }
    }

    &-empty {
      padding: 1rem;
      color: #153458;
      display: flex;
      justify-content: center;
      font-size: 1.8rem;
    }

    &-list {
      overflow-y: hidden;
      margin-bottom: 14px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &-item {
      background: #fbfbfb;
      padding: 10px 6px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      position: relative;
      overflow-x: hidden;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
        transition: 0.3s;
        color: #153458;
      }

      & svg {
        &:hover {
          rotate: -90deg;
        }

        transition: rotate 0.3s ease-out;
        font-size: 2rem;
        right: 4px;
        top: 4px;
        position: absolute;
        cursor: pointer;
      }

      & span:nth-child(3) {
        font-size: 1.4rem;
        font-weight: 400;
      }

      & span:nth-child(1) {
        font-size: 1.5rem;
      }

      .time {
        text-align: right;
        font-size: 10px;
        opacity: 0.9;
        color: #153458;

        &.admin {
          color: white;
        }
      }

      &.admin {
        background: #153458 !important;
        color: white;
        border-left: 3px solid #0c608b;

        &:hover {
          color: #fbfbfb;
        }
      }

      &-from-admin {
        background: #0c608b;
        padding: 1.45rem 0.6rem 1.2rem;
        width: fit-content;
        border-radius: 10px;
        color: white;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.2;
        height: 2.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-item.active {
      border-radius: 4px;
      background: rgba($color: #0e608b, $alpha: 0.1);
      border-left: 3px solid rgba($color: #0e608b, $alpha: 0.3);
    }
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(25deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-25deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .bell {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 100%;
    // z-index: 9999999;
    position: relative;
    cursor: pointer;

    &.wave {
      svg {
        animation: wave 0.7s linear forwards infinite;
        animation-fill-mode: both;
      }
    }

    .unread-count {
      position: absolute;
      top: -2px;
      // z-index: 9999999;
      display: flex;
      justify-content: center;
      align-items: center;
      right: -14px;
      width: 26px;
      overflow: hidden;
      height: 26px;
      border-radius: 50%;
      background: rgba($color: #bad4e2, $alpha: 0.7);
      font-size: 12px;
    }
  }
}

.notification-holder {
  .notification-container {
    visibility: visible;
    opacity: 100%;
    transform: translateY(0);
  }

  .notification {
    // max-height: 545px;
    // overflow-y: auto;

    // padding: 1.4rem;

    // left: 0;
    & > span {
      display: flex;
      justify-content: center;
      font-size: 1.4rem;
      width: fit-content;
      margin: 0 auto;
      cursor: pointer;
      transition: 0.2s all;
      padding: 0.8rem 1.5rem;
      border-radius: 0.5rem;

      &:hover {
        background: rgba($color: #3b73af, $alpha: 0.15);
        color: #3b73af;
      }
    }

    &-header {
      color: #153458;
      font-size: 1.6rem;
      display: flex;
      justify-content: space-between;

      & div {
        display: flex;
        column-gap: 1.1rem;

        span {
          &:hover {
            color: #3b73af;
            transform: translateY(-4px);
          }

          cursor: pointer;
          transition: all 0.2s;
          font-size: 1.4rem;
        }
      }
    }

    &-empty {
      padding: 1rem;
      color: #153458;
      display: flex;
      justify-content: center;
      font-size: 1.8rem;
    }

    &-list {
      overflow-y: hidden;
      margin-bottom: 14px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &-item {
      background: #fbfbfb;
      padding: 10px 6px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      position: relative;
      overflow-x: hidden;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
        transition: 0.3s;
        color: #153458;
      }

      & svg {
        &:hover {
          rotate: -90deg;
        }

        transition: rotate 0.3s ease-out;
        font-size: 2rem;
        right: 4px;
        top: 4px;
        position: absolute;
        cursor: pointer;
      }

      & span:nth-child(3) {
        font-size: 1.4rem;
        font-weight: 400;
      }

      & span:nth-child(1) {
        font-size: 1.5rem;
      }

      .time {
        text-align: right;
        font-size: 10px;
        opacity: 0.9;
        color: #153458;

        &.admin {
          color: white;
        }
      }

      &.admin {
        background: #153458 !important;
        color: white;
        border-left: 3px solid #0c608b;

        &:hover {
          color: #fbfbfb;
        }
      }

      &-from-admin {
        background: #0c608b;
        padding: 1.45rem 0.6rem 1.2rem;
        width: fit-content;
        border-radius: 10px;
        color: white;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.2;
        height: 2.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-item.active {
      border-radius: 4px;
      background: rgba($color: #0e608b, $alpha: 0.1);
      border-left: 3px solid rgba($color: #0e608b, $alpha: 0.3);
    }
  }

  .bell {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 100%;
    cursor: pointer;
  }
}

.explorer-content .line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.explorer-content .line-clamp-2.active {
  display: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: unset;
}

.line-clamp-2 {
  /* Standard properties */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;

  /* For limiting the number of lines to 2 */
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-decoration-break: clone;
  /* Needed for Firefox */
  text-overflow: ellipsis;

  /* To hide overflow text */
  overflow: hidden;

  /* Optional: Set a specific height to the container */
  max-height: 3.7em;
  /* Adjust as needed */
}

.text-limit {
  /* Standard properties */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;

  /* For limiting the number of lines to 2 */
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-decoration-break: clone;
  /* Needed for Firefox */
  text-overflow: ellipsis;

  /* To hide overflow text */
  overflow: hidden;

  /* Optional: Set a specific height to the container */
  max-height: 3.7em;
  /* Adjust as needed */
  max-width: 340px;
}

.pagination {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  gap: 10px;
  margin-top: 2rem;
  width: 100%;
  margin-top: 1rem;

  li {
    // background-color: #f6fafc;
    border-radius: 8px;
    cursor: pointer;

    a {
      background-color: #f6fafc;
      padding: 0.5rem 1.5rem;
      border-radius: 8px;
      color: #153458;

      &:hover {
        color: #0e608b;
      }
    }

    &.active a {
      background-color: #153458;
      color: white;

      a {
        color: white;
      }
    }
  }
}

body div div .rc-dialog-close-x {
  display: none !important;
}

body .rc-btn-ghost {
  margin-right: 0.4rem;
}

.toast-logo {
  width: 36px;
  margin-bottom: 9px;
}

// progress
.progresses {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  max-height: 200px;
  margin-top: 3rem;

  .progress1,
  .progress2,
  .progress3,
  .progress4 {
    position: absolute;
  }

  .progress1 {
    $progress-bar-stroke-width: 2.2;
    $progress-bar-size: 200px;

    svg {
      height: $progress-bar-size;
      transform: rotate(90deg);
      width: $progress-bar-size;
    }

    .progress-bar__background {
      fill: none;
      stroke: #19142e21;
      stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
      fill: none;
      stroke: #e2eff0;
      stroke: #0f608b;
      stroke-dasharray: 100 100;
      // stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: $progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }

  .progress2 {
    $progress-bar-stroke-width: 0.85;
    $progress-bar-size: 160px;

    svg {
      height: $progress-bar-size;
      transform: rotate(90deg);
      width: $progress-bar-size;
    }

    .progress-bar__background {
      fill: none;
      stroke: #19142e;
      stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
      fill: none;
      stroke: #e2eff0;
      stroke: #b372df;
      stroke-dasharray: 100 100;
      // stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: $progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }

  .progress3 {
    $progress-bar-stroke-width: 0.9;
    $progress-bar-size: 140px;

    svg {
      height: $progress-bar-size;
      transform: rotate(90deg);
      width: $progress-bar-size;
    }

    .progress-bar__background {
      fill: none;
      stroke: #19142e;
      stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
      fill: none;
      stroke: #e2eff0;
      stroke: #f1bd8f;
      stroke-dasharray: 100 100;
      // stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: $progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }

  .progress4 {
    $progress-bar-stroke-width: 1;
    $progress-bar-size: 120px;

    svg {
      height: $progress-bar-size;
      transform: rotate(90deg);
      width: $progress-bar-size;
    }

    .progress-bar__background {
      fill: none;
      stroke: #19142e;
      stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
      fill: none;
      stroke: #e2eff0;
      stroke: #7be39c;
      stroke-dasharray: 100 100;
      // stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: $progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }

  .icon {
    // background: url(.//assets/images/circle-star.svg) center no-repeat;
    width: 64px;
    height: 64px;
    display: inline-block;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .center-value {
      font-family: 'Hellix';
      font-size: 17px;
      font-weight: 500;
      line-height: normal;
      color: rgb(255, 255, 255);

      &.title-c {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: rgb(173, 170, 200);
      }

      &.value-c {
        font-size: 15px;
        font-weight: 600;
        line-height: normal;
        color: #fdf2d4;
      }

      &.sub-c {
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        color: #7be39c;
      }
    }
  }
}

.faq-style-wrapper {
  margin-top: 1rem;
}

.faq-style-wrapper .faq-title {
  font-size: 20px;
}

.faq-style-wrapper .faq-body .faq-row {
  padding: 0.32em 0;
}

.faq-style-wrapper .faq-body .faq-row .row-title {
  font-size: larger;
  color: #0e608b !important;
}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
  padding: 0.32em !important;
  font-size: medium !important;
  color: #153458 !important;
}

.row-option {
  margin-top: 2em;
}

.row-option label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
}

.row-option input {
  padding: 0.5em;
  width: 2em;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #d3dde1 !important;
}

.row-option button {
  padding: 0.5em 1em;
  margin-left: 1em;
  font-size: 1em;
  border-radius: 4px;
  background-color: #5000ca;
  border: 1px solid;
  color: white;
}

.extra-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin: 1rem 0 0 0;
  font-weight: 600;

  &-item {
    cursor: pointer;
    color: #153458;

    &:hover {
      color: #0e608b;
    }
  }
}

.button-withdraw {
  width: 100%;
  height: 54px;
  background-color: #153458;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  letter-spacing: 0.03em;
  color: #ffffff;
  cursor: pointer;
}

.title-withdraw {
  color: #1a2b40;
}

.description-withdraw {
  font-weight: 500;
  color: #153458;
}

.note-in-watch-video {
  margin-bottom: 2rem;

  p {
    font-size: 1.8rem;
    font-weight: 700;
    color: #153458;

    // &:last-child {
    //   margin-top: 0.5rem;
    //   font-size: 1.6rem;
    //   font-weight: 300;
    //   color: red;
    //   margin-bottom: 4rem;
    // }
  }
}

///

.participate-info {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  &-item {
    padding: 15px;
    background-color: #f7fafc;
    border-radius: 8px;
    width: 100%;
    position: relative;

    .title {
      font-weight: 600;
      font-size: 22px;
      color: #0e608b;
      text-align: left;
      margin-bottom: 1rem;
    }

    .data-container {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      &-item {
        display: flex;
        justify-content: space-between;

        .data-title {
          font-size: 16px;
          font-weight: 200;
        }

        .data-value {
          font-size: 16px;
          font-weight: 600;
          color: #535763;
        }
      }
    }
  }

  .is-active-badge {
    background-color: #ffffff;
    color: #000000;
    padding: 0.7rem 1rem 0.5rem;
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 13px;
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

.version {
  font-family: system-ui;
  margin: 0.3rem 0 0;
}

.account-setup {
  display: flex;
  align-items: flex-start;
}

.tooltip-info-text {
  text-wrap: balance;
}

// ToolTipInfo
.tooltip-info {
  right: 8px;
  top: 8px;
  position: absolute;

  &-icon {
    position: absolute;
    z-index: 10;
    right: 0px;
    font-size: 20px;
    cursor: pointer;
    color: #0e608b;
  }

  &-input {
    top: 13px !important;
  }

  &.tooltip-info-normal {
    position: unset !important;

    &-inside {
      position: relative !important;
    }

    .tooltip-info-icon {
      position: unset !important;
    }
  }

  &-text {
    color: #0e608b;
    width: 150px;
    position: absolute;
    z-index: 10;
    right: 0;
    margin: 0 auto;
    left: unset;
    right: 0;
    border: 1px solid #00000010;
    top: 30px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
    padding: 8px 10px;
    border-radius: 4px;
    background: #fafafa;
    // display: none;
    font-size: 13px;
    visibility: hidden;
    opacity: 0;
    z-index: 20;
    transition: 0.3s ease all;
  }

  &-icon:hover ~ &-text {
    opacity: 100%;
    visibility: visible;
    position: absolute;
  }
}

@media screen and (min-width: 1025px) {
  .tooltip-info {
    &-text {
      font-size: 14px;
      width: 300px;
    }

    &-icon {
      font-size: 21px;
    }
  }
}

.intr-videos-logo.responsive-video {
  @media screen and (min-width: 1300px) {
    width: 250px;
    height: 140px;
  }
}

.submenu-3rd {
  padding: 10px 0px 0px 18px !important;
}

.submenu-3rd {
  padding: 10px 0px 0px 18px !important;
}

.custom-radio-group {
  width: 100%;
}

.custom-radio-group * {
  box-sizing: content-box;
}

.overlay-menu {
  background: #15345840;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
}

.react-datepicker__aria-live {
  display: none;
}

.notification-wrapper .notification-item.admin.active .line-clamp-2 * {
  color: white !important;
}

@media screen and (max-width: 600px) {
  .participate-info > div,
  .inside-my-treasure-items > .over-chart > div {
    flex-direction: column;
  }
}

// @media screen and (max-width: 450px) {

//   .mymap-s,
//   .header,
//   .over-connections,
//   .over-participate,
//   .over-chart {
//     width: 94vw;
//   }

//   .participate-info>div,
//   .inside-my-treasure-items>.over-chart>div {
//     flex-direction: column;
//   }
// }

// @media screen and (max-width: 425px) {

//   // .header,
//   .mymap-s,
//   .over-connections,
//   .over-participate,
//   .over-chart {
//     width: 90vw;
//   }
// }

.warning-message {
  text-align: center;
  padding: 0rem;
  line-height: 0.8;

  p {
    font-size: 13.5px;
  }
}

.react-datepicker {
  width: max-content;
}

.boscrew-description {
  *img {
    max-width: 100% !important;
  }
}

.claim-window-stats {
  background-color: #f7fafc;
  border-radius: 8px;
  padding: 15px;

  .information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.3rem;

    @media screen and (max-width: 930px) {
      gap: unset;
      grid-template-columns: 1fr;
    }
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    color: #0e608b;
    text-align: left;
    margin-bottom: 1rem;
  }

  .over-participate-d {
    margin-bottom: unset;
    margin: 1rem 0;
  }
}

.group-icon {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 100rem;
}

.group-icon-container {
  display: flex;
  gap: 1rem;
}

.group-title-container {
  display: flex;
  align-items: center;
  gap: 1.7rem;

  @media screen and (max-width: 482px) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
}

.group-icon-title {
  padding-top: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.group-icon-texts {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

/* For desktop */
.faq-system-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  /* Adjust the gap as needed */
  align-items: start;

  @media screen and (max-width: 768px) {
    justify-items: center;
  }
}

/* For mobile */
@media (max-width: 768px) {
  .faq-system-wrapper {
    grid-template-columns: 1fr;
  }
}

.faq-aside {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

/* For desktop */
.faq-system-wrapper .faq-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  /* Adjust the gap as needed */
}

/* For desktop */
@media (max-width: 1200px) {
  .faq-system-wrapper .faq-content {
    grid-template-columns: 1fr;
  }
}

/* For mobile */
@media (max-width: 768px) {
  .faq-system-wrapper .faq-content {
    grid-template-columns: 1fr;
  }
}

/* Styles for .faq-topic */
.faq-topic,
.faq-popular,
.faq-support {
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  // height: fit-content;
  max-width: 425px;
}

.faq-topic h2,
.faq-popular h2,
.faq-support h2 {
  color: #1e6a92;
}

.faq-topic h2 a,
.faq-popular h2 a,
.faq-support h2 a {
  color: #1e6a92;
}

/* Styles for .faq-topic-content */
.faq-topic-content {
  color: #808d8e;
}

.faq-searchbox {
  gap: 10px;
  display: flex;
  justify-items: center;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.faq-searchbox-input {
  display: flex;
  width: 80%;
  height: 46px;
  background: #ffffff;
  border-radius: 32px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 0 0 16px;
}

.faq-searchbox-input-text {
  border: unset;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  margin-left: -10px;
  background-color: transparent;
  font-size: 14px;
  outline: none;
}

/* Styles for .faq-topic-list */
.faq-topic-list,
.faq-question-list {
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
}

.faq-topic-list h2,
.faq-question-list h2 {
  color: #1e6a92;
}

.faq-topic-list h2 a,
.faq-question-list h2 a {
  color: #1e6a92;
}

/* Styles for .faq-topic-list-content */
.faq-topic-list-content,
.faq-question-list-content {
  color: #808d8e;
  padding-bottom: 3rem;
}

/* For desktop */
.faq-system-wrapper .faq-list-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  /* Adjust the gap as needed */
}

.faq-system-wrapper h2 {
  margin-bottom: 1.8rem;
}

.faq-popular-item {
  padding: 2px 0;
}

.faq-question-list-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-popular-content {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.faq-question-item-icon {
  min-width: 20px;
  min-height: 20px;
}

.faq-question-item,
.faq-popular-item {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.faq-question-item a,
.faq-popular-item a {
  color: #153458;
  // line-height: 1;
  padding-top: 3px;
}

.faq-topic-list-content,
.faq-topic-list {
  position: relative;
}

.faq-topic-list-like-dislike {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: #979797;
  position: absolute;
  bottom: 2.4rem;
  right: 3.4rem;
}

.faq-topic-list-like-dislike span {
  line-height: 0.9;
}

.faq-like-dislike-icon {
  margin-bottom: 6px;
}

/* For mobile */
@media (max-width: 1140px) {
  .withdrawal-modal {
    display: block !important;
  }
}

.not-found-faq {
  max-width: 450px;
}

.faq-system-wrapper .faq-content.faq-not-found {
  grid-template-columns: 1fr;
}

.faq-system-wrapper .faq-content.faq-not-found button,
.faq-system-wrapper button {
  background: #32477b;
  padding: 1.5rem 5.5rem;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  color: white;
  font-weight: 700;
}

.sophisticate-page {
  align-items: start;
  margin-top: 1rem !important;
}

.sophisticate-container {
  width: 100%;
}

.sophisticate-form {
  .subtitle {
    margin-bottom: 5px;
  }

  .reject-reason {
    margin-bottom: 1rem;

    h2 {
      color: red;
    }
  }

  h1 {
    color: #153458;
    font-size: 34px;
    line-height: unset !important;
  }

  h2 {
    color: #153458;
    font-size: 18px;
    line-height: unset !important;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  p {
    color: #000000;
    font-size: 15px;
    margin-top: 1rem;
    line-height: unset !important;
  }

  .question {
    color: #000000;
    font-size: 16px;
    line-height: unset !important;

    &.qoute {
      display: flex;
      gap: 1rem;
      align-items: flex-start;
    }
  }

  .checkbox {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .content {
      display: flex;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;

      .inner-side {
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: start;
        gap: 0.5rem;

        & > input {
          cursor: pointer;
          vertical-align: middle;
          margin-right: 10px;
          width: 18px;
          height: 18px;
        }

        .label {
          cursor: pointer;
          color: #000000;
          font-size: 15px;
          line-height: unset !important;
          margin-top: 2px;
        }
      }
    }
  }

  .dropdown {
    font-weight: 600;
    font-size: 14px;
    color: #153458;
    border-radius: 16px;
    border: 1px solid #153458;
    padding: 4px 8px 4px 10px;

    background: url("data:image/svg+xml,<svg t='1675155272854' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'><path d='M760.454652 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227064 0L511.706311 596.712357 308.185035 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227065 0-12.489452 12.489452-12.489452 32.737612 0 45.227064L489.093291 664.553465c12.489452 12.489452 32.737612 12.489452 45.227064 0L760.454652 438.418144c12.488429-12.489452 12.488429-32.738635 0-45.227064z' p-id='1287'></path></svg>")
      no-repeat;
    background-color: #e8f1f4;
    background-position: calc(110% - 14px) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 30px !important;
  }

  .textarea,
  .input {
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    border: 0.0313rem solid #eeeeee;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 17px;
  }

  .input-amount {
    max-width: 400px;
  }

  .subtitle-amount {
    margin: 0;
    margin-bottom: 5px;
  }

  .radio-style {
    border: 0;
    padding: 1rem 0 0;
    margin-top: 1rem;
  }

  .radio-item {
    margin-top: 0.5rem;

    label {
      padding-left: 0.5rem;
    }
  }

  .textarea {
    height: 150px;
  }

  .text {
    height: 48px;
  }
}

.financial-tx-info {
  background: white;
  padding: 2rem 0rem 0rem;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
}

.financial-tx-info-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.multi-upload-container {
  h3 {
    font-size: 14px;
  }
}

.uploader-message {
  background: #4bb543;
  display: grid;
  padding: 14px 10px;
  border-radius: 8px;
  margin: 4px auto;
  color: white;
  width: 100%;
}

.uploader-btn-again {
  cursor: pointer;
  background: #2b8e23;
  border: 0;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-top: -5px;
  padding: 1rem 2rem;
}

.accepted-status {
  display: flex;
  // justify-content: space-between;
  gap: 0.5rem;
}

.accepted-sophisticated-box {
  // height: 83px;
  align-items: center;
}

.btn-calc {
  background: #153458;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .my-treasure-box-btn {
    flex-direction: column;
  }
}

.btn-cancel {
  max-width: 140px;
  max-height: 38px;
  font-size: 18px;
}

.drop-down-select-style {
  height: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #153458;
  border-radius: 16px;
  border: none;
  padding: 4px 8px 4px 10px;
  background: url('data:image/svg+xml,<svg t=%271675155272854%27 viewBox=%270 0 1024 1024%27 version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2714px%27><path d=%27M760.454652 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227064 0L511.706311 596.712357 308.185035 393.19108c-12.489452-12.489452-32.737612-12.489452-45.227065 0-12.489452 12.489452-12.489452 32.737612 0 45.227064L489.093291 664.553465c12.489452 12.489452 32.737612 12.489452 45.227064 0L760.454652 438.418144c12.488429-12.489452 12.488429-32.738635 0-45.227064z%27 p-id=%271287%27></path></svg>')
    no-repeat;
  background-color: #ffffff;
  background-position: calc(110% - 14px) center !important;
  appearance: none !important;
  padding-right: 30px !important;

  &.status {
    width: 120px;
  }

  &.type {
    width: 100px;
  }
}

.drop-down-style {
  display: flex;
  width: fit-content;
  height: 46px;
  background: #ffffff;
  border-radius: 32px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 4px;
}

.island-list-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 1rem 1.8rem 1rem;
  background: white;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 550px) {
  .fir-tr-combo-header {
    flex-direction: column-reverse;
  }

  .drop-down-select-style {
    &.status {
      width: 100%;
    }

    &.type {
      width: 100%;
    }

    &.pagination-dynamic {
      width: 60px;
    }
  }

  .fir-tr-combo-header-input {
    width: 100%;
  }

  .drop-down-style {
    width: 100%;
  }
}

.pagination-dynamic-input {
  width: unset;
  padding: 0;
  padding: 1.2rem;

  .input-dynamic {
    width: 40px;
    height: 40px;
    background: #e8f1f4;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0 -0.4rem;
    border-radius: 1rem;
  }

  .span-first {
    color: gray;
    font-size: 1.2rem;
  }

  .span-second {
    color: gray;
    font-size: 1.2rem;
  }
}

.badge-subscribe {
  background: #4bb543;
  padding: 1rem 1rem 0.8rem 1rem;
  color: white;
  border-radius: 5px;
}

.badge-unsubscribe {
  background: #a9a9a9;
  padding: 1rem 1rem 0.8rem 1rem;
  color: white;
  border-radius: 5px;
}

.badge-cleaned {
  background: #a9a9a9;
  padding: 1rem 1rem 0.8rem 1rem;
  color: white;
  border-radius: 5px;
  cursor: not-allowed;
}

.re-subscribe {
  background-color: gray;
}

.cleaned {
  background-color: gray;
  cursor: not-allowed;

  &:hover {
    background-color: gray;
  }
}

.ultimo-modal {
  width: 900px;
}

.ultimo-modal-box {
  border-radius: 10px;
  padding: 1.4rem 2rem;
}

.ultimo-modal-selected-box {
  background: #0000000a;
  border-radius: 10px;
  padding: 1.4rem 2rem;
}
